import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Widget, WidgetAPI } from "@uploadcare/react-widget";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useIntercom } from "react-use-intercom";
import { Content, Footer, SecondaryButton, StyledForm } from ".";
import { Card } from "../../../components/Card";
import { Button, ButtonWrap, Submit } from "../../../components/CTA";
import { CardDivider } from "../../../components/Divider";
import { Flex } from "../../../components/Flex";
import { H2 } from "../../../components/Heading";
import { PicturesIcon } from "../../../components/icons/PictureIcon";
import { TrashIcon } from "../../../components/icons/TrashIcon";
import Loading from "../../../components/Loading";
import { Modal } from "../../../components/Modal";
import { Overlay } from "../../../components/Overlay";
import { Subtitle } from "../../../components/Subtitle";
import { Text } from "../../../components/Text";
import { FakeTextLink } from "../../../components/TextLink";
import { View } from "../../../components/View";
import { MOBILE_BREAKPOINT } from "../../../config";
import {
  useGetBrandQuery,
  useUpdateBrandMutation,
} from "../../../graphql/generated";
import { ThemeNames, useColorScheme } from "../../../hooks/useColorScheme";
import useGqlClient from "../../../hooks/useGqlClient";
import { authSelectors } from "../../../store/auth/selector";
import styled, { css } from "../../../styles";

interface ImageProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  images: string[];
  setImages: React.Dispatch<React.SetStateAction<string[]>>;
}

const UPLOADCARE_LOCALE_TRANSLATIONS = {
  buttons: {
    choose: {
      images: {
        one: "Upload image",
      },
    },
  },
};

const ModalFooter = styled.div`
  padding: 0 ${(p) => p.theme.spacing.m} 0;
`;

const WidgetWrap = styled.div`
  position: relative;
  z-index: 1;
  &.uploadcare--widget__button,
  .uploadcare--widget__button_type_open {
    background: ${(p) => p.theme.color.input.hover};
    padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.l} !important;
    color: ${(p) => p.theme.color.primary};
    border-radius: ${(p) => p.theme.misc.borderRadius};
    cursor: pointer;
    font-family: ${(p) => p.theme.typography.bodyFamily} !important;
    font-size: ${(p) => p.theme.typography.size.m};

    &:focus {
      outline: none;
    }

    &:hover {
      filter: brightness(0.85);
    }
  }

  &.uploadcare--widget_status_loaded,
  #uploadcare--widget__text {
    display: none;
  }
`;

const ImagePlaceholder = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 2px dashed ${(p) => p.theme.color.typography.secondary}50;
  border-radius: ${(p) => p.theme.misc.borderRadius};
  cursor: pointer;

  height: 104px;
  width: 144px;

  &:hover {
    border: 2px dashed ${(p) => p.theme.color.typography.secondary};

    p {
      color: ${(p) => p.theme.color.typography.secondary};
    }

    svg {
      fill: ${(p) => p.theme.color.typography.secondary};
    }
  }
`;

const ImagesWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 144px);
  grid-column-gap: ${(p) => p.theme.spacing.l};
  grid-row-gap: ${(p) => p.theme.spacing.m};
  margin-bottom: ${(p) => p.theme.spacing.l};
  justify-content: start;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: repeat(2, 144px);
    grid-gap: ${(p) => p.theme.spacing.s};
  }
`;

const CloseWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: -6px;
  right: -8px;
  width: 18px;
  height: 18px;
  background-color: ${(p) => p.theme.color.typography.secondary};
  display: flex;
  text-decoration: none;
  border-radius: 999px;
  box-shadow: ${(p) => p.theme.shadow.imageFloatingIcon};
  justify-content: center;
  align-items: center;
  background: ${(p) => p.theme.color.typography.text};
  padding: 2px;
  z-index: 3;

  &:hover {
    background: ${(p) => p.theme.color.typography.secondaryHeading};
  }
`;

const ImageWrap = styled.div`
  position: relative;
  &:hover ${CloseWrapper} {
    -webkit-transition: opacity 0.2s ease-in-out;
    -moz-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
    opacity: 1;
  }
`;

const Image = styled.img<{ size?: "s" | "l" }>`
  position: relative;
  border-radius: ${(p) => p.theme.misc.borderRadius};
  height: 108px;
  width: 144px;

  ${(p) =>
    p.size === "l"
      ? css`
          height: 108px;
          width: 144px;
        `
      : ``}
`;

export const Images = (props: ImageProps) => {
  const [showImageModal, setShowImageModal] = useState(false);
  const theme = useColorScheme();
  const { showNewMessages } = useIntercom();
  const [orderedImages, setOrderedImages] = useState(props.images);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!active || !over) {
      return;
    }

    if (active.id !== over.id) {
      const oldIndex = orderedImages.indexOf(active.id as string);
      const newIndex = orderedImages.indexOf(over.id as string);
      const newArray = arrayMove(orderedImages, oldIndex, newIndex);
      setOrderedImages(newArray);
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const submitter = (e.nativeEvent as any).submitter;

    if (submitter && submitter.name === "saveDraft") {
      props.setCurrentStep(0);
      return;
    }

    if (orderedImages.length === 0) {
      alert("Please add at least one image");
      return;
    }

    props.setImages(orderedImages);

    props.setCurrentStep(2);
  };

  return (
    <div>
      <StyledForm onSubmit={onSubmit}>
        <Card margin="l 0 xl">
          <Content>
            <H2 margin="0 0 xs 0">Images</H2>
            <Subtitle margin="0 0 m" size="s">
              Need help with images?{" "}
              <FakeTextLink
                size="s"
                onClick={() => {
                  showNewMessages("Hey Joli Team, can you help with images?");
                }}
              >
                Chat to us
              </FakeTextLink>
            </Subtitle>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <ImagesWrap>
                <SortableContext
                  items={orderedImages}
                  strategy={rectSortingStrategy}
                >
                  {orderedImages.map((image) => {
                    return (
                      <SortableItem
                        image={image}
                        key={image}
                        setOrderedImages={setOrderedImages}
                        orderedImages={orderedImages}
                        theme={theme}
                      />
                    );
                  })}
                </SortableContext>
                <ImagePlaceholder
                  onClick={() => {
                    setShowImageModal(true);
                  }}
                >
                  <PicturesIcon colorPreset="secondary" />
                  <Text size="s" margin="xs 0 0" colorPreset="secondary">
                    Add images
                  </Text>
                </ImagePlaceholder>
              </ImagesWrap>
            </DndContext>
          </Content>
        </Card>
        <Footer>
          <ButtonWrap>
            <SecondaryButton
              margin="0"
              type="submit"
              name="saveDraft"
              value={!true ? "Saving..." : "Back"}
            />
            <Submit
              margin="0"
              type="submit"
              name="publish"
              value={!true ? "Saving..." : "Next"}
            />
          </ButtonWrap>
        </Footer>
      </StyledForm>
      {showImageModal ? (
        <ChooseImagesModal
          selectedImages={orderedImages}
          onClose={() => setShowImageModal(false)}
          onImagesSelected={(images) => {
            setShowImageModal(false);

            const newImages = [];
            for (const img of images) {
              if (!orderedImages.includes(img)) {
                newImages.push(img);
              }
            }
            setOrderedImages([...orderedImages, ...newImages]);
          }}
        />
      ) : null}
    </div>
  );
};

interface ChooseImagesModalProps {
  onClose: () => void;
  onImagesSelected: (images: string[]) => void;
  selectedImages: string[];
}

const ModalImagesWrap = styled.div`
  overflow-y: scroll;
  max-height: 400px;
  flex: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${(p) => p.theme.spacing.l};
  border: 2px solid transparent;
  margin: ${(p) => p.theme.spacing.m} 0 0;
  padding: ${(p) => p.theme.spacing.s};
  justify-items: center;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ModalImage = styled.img<{ selected: boolean }>`
  border-radius: ${(p) => p.theme.misc.borderRadius};
  border: 3px solid transparent;
  width: 100%;
  cursor: pointer;
  position: relative;

  margin: 0 2px;

  &:hover {
    transition: transform 0.2s;
    transform: scale(1.02);
  }

  ${(p) =>
    p.selected
      ? css`
          border: 3px solid ${(p) => p.theme.color.primary};
        `
      : ``}
`;

function ChooseImagesModal(props: ChooseImagesModalProps) {
  const client = useGqlClient();

  const [selectedImages, setSelectedImages] = useState<string[]>(
    props.selectedImages
  );
  const brand = useSelector(authSelectors.activeBrand);
  const brandQuery = useGetBrandQuery(client, {
    id: brand ? brand.id : "",
  });

  const widgetAPI = useRef<WidgetAPI>(null);

  const updateBrand = useUpdateBrandMutation(client);

  if (brandQuery.isLoading || !brandQuery.data || !brandQuery.data.brand) {
    return (
      <Modal isOpen={true} setIsOpen={() => console.log()}>
        <Loading />
      </Modal>
    );
  }

  if (brandQuery.error) {
    if (brandQuery.isLoading) {
      return (
        <Modal isOpen={true}>
          <Text>Something went wrong</Text>
        </Modal>
      );
    }
  }

  return (
    <Overlay
      onClick={() => {
        props.onClose();
        props.onImagesSelected([]);
      }}
    >
      <Modal isOpen={true} onClose={() => props.onImagesSelected([])}>
        <div>
          <Flex align="center" justify="space-between">
            <H2 margin="0">Select images</H2>
            <WidgetWrap>
              <Widget
                localeTranslations={UPLOADCARE_LOCALE_TRANSLATIONS}
                ref={widgetAPI}
                crop="4:3"
                multiple={false}
                previewStep={true}
                imageShrink="800x600"
                imagesOnly={true}
                tabs="file facebook instagram gdrive gphotos dropbox onedrive"
                publicKey="04ec353cf089294c8374"
                onChange={(file) => {
                  // file is a group of files in a url
                  if (!file.cdnUrl || !brand) {
                    return;
                  }

                  const images = [];
                  images.push(file.cdnUrl);

                  const newImages = [...selectedImages, ...images];
                  setSelectedImages(newImages);

                  updateBrand.mutate(
                    {
                      input: {
                        id: brand.id,
                        images,
                      },
                    },
                    {
                      onSuccess: () => {
                        brandQuery.refetch();
                        widgetAPI.current && widgetAPI.current.value(null);
                      },
                    }
                  );
                }}
              />
            </WidgetWrap>
          </Flex>
        </div>

        {brandQuery.isLoading || brandQuery.isFetching ? (
          <Loading />
        ) : (
          <>
            {brandQuery.data.brand &&
            brandQuery.data.brand.images.length > 0 ? (
              <View flex={1}>
                <ModalImagesWrap>
                  {brandQuery.data.brand.images.map((img) => {
                    return (
                      <ModalImage
                        selected={selectedImages.includes(img)}
                        onClick={() => {
                          if (selectedImages.includes(img)) {
                            setSelectedImages(
                              selectedImages.filter((i) => i !== img)
                            );
                            return;
                          }

                          setSelectedImages([...selectedImages, img]);
                        }}
                        src={img}
                      />
                    );
                  })}
                </ModalImagesWrap>
              </View>
            ) : (
              <Flex
                margin="0"
                align="center"
                justify="center"
                style={{ flex: 1 }}
              >
                <Text align="center">
                  <b>No images</b> <br />
                  Get started by uploading your first image
                </Text>
              </Flex>
            )}
          </>
        )}
        <CardDivider margin="0 0 m 0" />
        <ModalFooter>
          <ButtonWrap margin="0">
            <Button
              onClick={() => {
                props.onImagesSelected([]);
              }}
              margin="0 0 0 0"
              buttonType="secondary"
            >
              Cancel
            </Button>
            <Button
              style={{ minWidth: 180 }}
              isDisabled={selectedImages.length === 0}
              onClick={() => {
                if (selectedImages.length === 0) {
                  return;
                }
                props.onImagesSelected(selectedImages);
              }}
            >
              {selectedImages.length === 0
                ? "No images selected"
                : selectedImages.length === 1
                ? `Add ${selectedImages.length} image`
                : `Add ${selectedImages.length} images`}
            </Button>
          </ButtonWrap>
        </ModalFooter>
      </Modal>
    </Overlay>
  );
}

interface Props {
  image: string;
  setOrderedImages: React.Dispatch<React.SetStateAction<string[]>>;
  orderedImages: string[];
  theme: ThemeNames;
}

const SortableItem = (props: Props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props.image });

  return (
    <div
      id={props.image}
      key={props.image}
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? 2 : 1,
        opacity: isDragging ? 0.3 : 1,
        position: "relative",
        cursor: "grab",
      }}
    >
      <CloseWrapper
        onClick={(e) => {
          e.stopPropagation();
          props.setOrderedImages(
            props.orderedImages.filter((img: string) => img !== props.image)
          );
        }}
      >
        <TrashIcon color={props.theme === "dark" ? "#000" : "#fff"} />
      </CloseWrapper>
      <ImageWrap key={props.image} {...attributes} {...listeners}>
        <Image size="l" src={props.image} />
      </ImageWrap>
    </div>
  );
};
