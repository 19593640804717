import { useEffect, useRef, useState } from "react";
import { toast } from "sonner";
import {
  useGetDeclineReasonsQuery,
  useUpdateRejectionReasonsMutation,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import { useTheme } from "../../../hooks/useTheme";
import styled, { css } from "../../../styles";
import { Button, ButtonWrap, CTA } from "../../CTA";
import { Callout } from "../../Callout";
import { SingleCheckBox } from "../../Checkbox";
import { Chevron } from "../../Chevron";
import { Flex } from "../../Flex";
import Loader from "../../Loader";
import { Radio } from "../../RadioButton";
import { Text } from "../../Text";
import { TextArea } from "../../TextArea";
import { Tooltip } from "../../Tooltip";
import { View } from "../../View";

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 4fr auto;
  gap: ${(p) => p.theme.spacing.xl};
`;

const StyledFlex = styled(Flex)`
  display: flex;
  align-items: stretch;
  flex-direction: column;
`;

interface Props {
  onConfirm: (
    info: string,
    blockCreator: boolean,
    internalRejectionReason: string
  ) => void;
  onCancel: () => void;
  isLoading: boolean;
  creatorName: string;
  brandName: string;
}

export const RejectOverlay = (props: Props) => {
  const [selectedPreset, setSelectedPreset] = useState("");
  const [error, setError] = useState("");
  const [presetError, setPresetError] = useState("");
  const [presetOptions, setPresetOptions] = useState<Preset[]>([]);
  const [additionalFeedback, setAdditionalFeedback] = useState("");
  const [internalRejectionReason, setInternalRejectionReason] = useState("");
  const [rejectionMessage, setRejectionMessage] = useState("");
  const [blockCreator, setBlockCreator] = useState(false);
  const [editPresets, setEditPresets] = useState(false);
  const [step, setStep] = useState(0);
  const client = useGqlClient();
  const theme = useTheme();
  const updateReasons = useUpdateRejectionReasonsMutation(client);

  const { data, refetch } = useGetDeclineReasonsQuery(client);

  const handleInputChange = (id: string, newReason: string) => {
    if (id === "other") return;
    const updatedPresets = presetOptions.map((preset) => {
      if (preset.id === id) {
        return { ...preset, reason: newReason };
      }
      return preset;
    });

    setPresetOptions(updatedPresets);
  };

  const handleSave = () => {
    updateReasons.mutate(
      {
        reasons: presetOptions.map((preset) => ({
          id: preset.id,
          reason: preset.reason,
        })),
      },
      {
        onSuccess: () => {
          toast.success("Reasons updated");
          refetch();
        },
      }
    );

    setEditPresets(false);
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return function cleanup() {
      document.body.style.overflow = "unset";
    };
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }

    setPresetOptions([
      ...data.rejectionReasons.map((reason) => ({
        id: reason.id,
        reason: reason.reason,
      })),
    ]);
  }, [data]);

  useEffect(() => {
    setError("");
    setPresetError("");
    if (!selectedPreset) {
      setRejectionMessage("");
      return;
    }

    if (selectedPreset === "other") {
      setRejectionMessage(`${additionalFeedback}`);
    } else {
      const message = presetOptions.find(
        (preset) => preset.id === selectedPreset
      );
      setRejectionMessage(
        message
          ? message.reason +
              (additionalFeedback ? `: ${additionalFeedback}` : "")
          : ""
      );
    }
  }, [selectedPreset, additionalFeedback, presetOptions]);

  return (
    <>
      {props.isLoading ? (
        <Flex align="center" justify="center">
          <Loader />
        </Flex>
      ) : step === 0 ? (
        <StyledFlex>
          <Text
            weight="bold"
            size="xl"
            margin={"0 0 0 0"}
            colorPreset="heading"
          >
            Let them know why they weren't a good fit
          </Text>
          <Text margin={"0 0 m 0"} colorPreset="secondary">
            Choose from the options below or customise your own to match your
            brand
          </Text>
          <Flex
            justify="space-between"
            direction="row"
            align="center"
            margin="0"
          >
            <Text weight="bold">Primary Reason</Text>
            <Text
              style={{ cursor: "pointer", textAlign: "right" }}
              size="s"
              weight="semi"
              colorPreset="link"
              margin="0"
              onClick={editPresets ? handleSave : () => setEditPresets(true)}
            >
              {editPresets ? "Save" : "Edit"}
            </Text>
          </Flex>
          <Grid>
            <Presets
              presets={presetOptions}
              onInputChange={handleInputChange}
              editMode={editPresets}
              selectedPreset={selectedPreset}
              setSelectedPreset={setSelectedPreset}
              presetError={presetError}
            />
          </Grid>
          {editPresets ? (
            <View margin="s 0">
              <Callout
                type="info"
                text={`Any changes will be saved for all ${props.brandName} users`}
              />
            </View>
          ) : null}

          <Text weight="bold" margin="l 0 xs">
            Notes
          </Text>
          <TextArea
            rows={3}
            placeholder={"Additional feedback"}
            onChange={(e) => {
              setAdditionalFeedback(e.currentTarget.value);
              setError("");
            }}
          />
          {error ? (
            <Text margin="0 0 0" colorPreset="error">
              {error}
            </Text>
          ) : null}

          <Flex direction="row" margin="l 0 0">
            <PointerFlex
              dir="row"
              align="center"
              onClick={() => setBlockCreator(!blockCreator)}
            >
              <SingleCheckBox checked={blockCreator} />
              <Text margin="0 s 0 s">Block future applications</Text>{" "}
            </PointerFlex>
            <Tooltip>
              <Text size="s" margin="0">
                {`@${props.creatorName} will lose access to all current and future ${props.brandName} listings`}
              </Text>
              <Text size="xs" margin="0" colorPreset="secondary">
                They will not be aware of this action
              </Text>
            </Tooltip>
          </Flex>
          <ButtonWrap margin="xl 0 0">
            <CTA type="secondary" to="#" onClick={props.onCancel}>
              Cancel
            </CTA>
            <Button
              onClick={() => {
                if (!selectedPreset) {
                  setPresetError("Please select a reason");
                  return;
                }
                if (selectedPreset === "other" && !additionalFeedback) {
                  setError("Please specify a reason");
                  return;
                }
                setStep(1);
              }}
            >
              <Flex
                justify="center"
                align="center"
                direction="row"
                style={{ gap: 4 }}
              >
                Submit <Chevron color="#fff" />
              </Flex>
            </Button>
          </ButtonWrap>
        </StyledFlex>
      ) : (
        <StyledFlex>
          <Text
            weight="bold"
            size="xl"
            margin={"0 0 0 0"}
            colorPreset="heading"
          >
            Let <span style={{ color: theme.color.primary }}>Joli</span> know
            why they weren't a good fit
          </Text>
          <Text margin={"0 0 m 0"} colorPreset="secondary">
            Help us improve your Joli experience by letting us know why this
            person wasn't right for you.{" "}
            <Text margin="0" weight="bold" colorPreset="text" isInline>
              This feedback will not be shared with @{props.creatorName}.
            </Text>
          </Text>

          <Text weight="bold" margin="l 0 xs">
            Feedback
          </Text>
          <TextArea
            rows={3}
            placeholder={"Please leave any notes for the Joli team"}
            onChange={(e) => {
              setInternalRejectionReason(e.currentTarget.value);
            }}
          />
          <ButtonWrap margin="xl 0 0">
            <CTA
              type="secondary"
              to="#"
              onClick={() =>
                props.onConfirm(
                  rejectionMessage,
                  blockCreator,
                  internalRejectionReason
                )
              }
            >
              Skip
            </CTA>
            <CTA
              to="#"
              onClick={() => {
                props.onConfirm(
                  rejectionMessage,
                  blockCreator,
                  internalRejectionReason
                );
              }}
            >
              Submit
            </CTA>
          </ButtonWrap>
        </StyledFlex>
      )}
    </>
  );
};

export const Alert = styled(View)`
  background-color: ${(p) => p.theme.color.secondary}30;
  border-left: 3px solid ${(p) => p.theme.color.secondary};
  border-radius: 2px;
  width: 100%;
  box-sizing: border-box;

  color: ${(p) => p.theme.color.secondary};
`;

const PointerFlex = styled(Flex)<{ showUpgradePrompt?: boolean }>`
  cursor: pointer;
  user-select: none;

  ${(p) =>
    p.showUpgradePrompt
      ? css`
          pointer-events: none;
          opacity: 0.44;
        `
      : css`
          opacity: 1;
        `}
`;

interface Preset {
  id: string;
  reason: string;
}

interface PresetProps {
  presets: Preset[];
  onInputChange: (id: string, newReason: string) => void;
  editMode: boolean;
  selectedPreset: string;
  setSelectedPreset: (id: string) => void;
  presetError?: string;
}

const Presets = ({
  presets,
  onInputChange,
  editMode,
  selectedPreset,
  setSelectedPreset,
  presetError,
}: PresetProps) => {
  const textAreaRefs = useRef<(HTMLTextAreaElement | null)[]>([]);

  useEffect(() => {
    textAreaRefs.current.forEach((textArea) => {
      if (textArea) {
        textArea.style.height = "auto";
        textArea.style.height = `${textArea.scrollHeight}px`;
      }
    });
  }, [presets, editMode]);

  return (
    <div>
      {editMode
        ? presets.map((preset, index) => (
            <Flex key={preset.id} style={{ alignItems: "flex-start" }}>
              <View style={{ marginTop: 2 }}>
                <Radio
                  isDisabled={editMode}
                  active={selectedPreset === preset.id}
                />
              </View>
              <View margin="0 s" style={{ width: "100%", marginTop: -2 }}>
                <StyledTextArea
                  autoFocus={index === 0}
                  rows={1}
                  ref={(el) => (textAreaRefs.current[index] = el)}
                  key={preset.id}
                  value={preset.reason}
                  onChange={(e) => onInputChange(preset.id, e.target.value)}
                />
              </View>
            </Flex>
          ))
        : presets.map((preset) => (
            <PointerFlex
              align="center"
              key={preset.id}
              margin="0 0 m"
              style={{ alignItems: "flex-start" }}
              onClick={() => setSelectedPreset(preset.id)}
            >
              <div>
                <Radio
                  isDisabled={editMode}
                  active={selectedPreset === preset.id}
                />
              </div>
              <Text margin="0 m">{preset.reason}</Text>
            </PointerFlex>
          ))}
      <PointerFlex
        align="center"
        margin="0 0 m"
        onClick={() => setSelectedPreset("other")}
      >
        <Radio isDisabled={editMode} active={selectedPreset === "other"} />
        <Text margin="0 m">Other - please specify below</Text>
      </PointerFlex>
      {presetError ? (
        <Text margin="0 0 0" colorPreset="error">
          {presetError}
        </Text>
      ) : null}
    </div>
  );
};

const StyledTextArea = styled.textarea`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${(p) => p.theme.color.typography.secondary}50;
  border-radius: 5px;
  padding: 2px 8px;
  margin: 0;
  font-size: ${(p) => p.theme.typography.size.m};
  color: ${(p) => p.theme.color.typography.text};
  &:hover {
    border-color: ${(p) => p.theme.color.typography.text};
    outline: none;
  }
  &:focus,
  &:active {
    border-color: ${(p) => p.theme.color.primary};
    box-shadow: 0px 0px 0px 2px ${(p) => p.theme.color.input.hover};
    outline: none;
  }
`;
