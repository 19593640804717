import { format, fromUnixTime, getUnixTime } from "date-fns";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useIntercom } from "react-use-intercom";
import { Content, Footer, SecondaryButton, StyledForm } from ".";
import { Button, ButtonWrap, Submit } from "../../../components/CTA";
import { Callout } from "../../../components/Callout";
import { Card } from "../../../components/Card";
import { Chevron } from "../../../components/Chevron";
import { DayPicker } from "../../../components/DayPicker";
import { CardDivider } from "../../../components/Divider";
import { Dropdown } from "../../../components/Dropdown";
import { Flex } from "../../../components/Flex";
import { H2 } from "../../../components/Heading";
import { Input } from "../../../components/Input";
import Loader from "../../../components/Loader";
import { Switch } from "../../../components/Switch";
import { Text } from "../../../components/Text";
import { TextArea } from "../../../components/TextArea";
import { Tooltip } from "../../../components/Tooltip";
import { View } from "../../../components/View";
import {
  Days,
  DmnBookingTypesQuery,
  Listing,
  ListingType,
  useDmnBookingTypesQuery,
  useGetBrandQuery,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import { useTheme } from "../../../hooks/useTheme";
import { authSelectors } from "../../../store/auth/selector";
import styled from "../../../styles";
import { lightTheme } from "../../../styles/theme";
import { dayEnumToDays, daysToDaysEnum } from "./RepeatEvent";

interface AvailabilityProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setStartDate: React.Dispatch<React.SetStateAction<Listing["startDate"]>>;
  startDate: Listing["startDate"];
  setEndDate: React.Dispatch<React.SetStateAction<Listing["endDate"]>>;
  endDate: Listing["endDate"];
  setDays: React.Dispatch<React.SetStateAction<Listing["days"]>>;
  days: Listing["days"];
  listingType: ListingType;
  setListingType: React.Dispatch<React.SetStateAction<ListingType | undefined>>;
  locations: string[];
  setDmnBookingType: React.Dispatch<React.SetStateAction<string>>;
  dmnBookingType: string;
  numberOfPeople: number;
  setNumberOfPeople: React.Dispatch<React.SetStateAction<number>>;
  internalBookingNotes?: string;
  setInternalBookingNotes: React.Dispatch<React.SetStateAction<string>>;
  bookingPlatformAutoCreate: Listing["bookingPlatformAutoCreate"];
  setBookingPlatformAutoCreate: React.Dispatch<
    React.SetStateAction<Listing["bookingPlatformAutoCreate"]>
  >;
}

export const Availability = (props: AvailabilityProps) => {
  const { showNewMessages } = useIntercom();
  const [validDmnBookingTypes, setValidDmnBookingTypes] = useState<
    DmnBookingTypesQuery["dmnBookingTypes"] | undefined
  >(undefined);
  const [invalidDmnBookingTypes, setInvalidDmnBookingTypes] = useState<
    DmnBookingTypesQuery["dmnBookingTypes"] | undefined
  >(undefined);
  const [showInvalidDmnBookingTypes, setShowInvalidDmnBookingTypes] =
    useState(false);
  const [internalBookingNotesLength, setInternalBookingNotesLength] =
    useState<number>(
      props.internalBookingNotes ? props.internalBookingNotes.length : 0
    );
  const [dmnBookingEnabled, setDmnBookingEnabled] = useState<boolean>(false);
  const [dmnEnabled, setDmnEnabled] = useState<boolean>(!!props.dmnBookingType);
  const [useDmnAvailability, setUseDmnAvailability] = useState<boolean>(
    !!props.dmnBookingType
  );
  const [useDmnAutoCreate, setUseDmnAutoCreate] = useState<boolean>(
    props.bookingPlatformAutoCreate
  );
  const [selectedBookingType, setSelectedBookingType] = useState<string>(
    props.dmnBookingType || ""
  );
  const brand = useSelector(authSelectors.activeBrand);
  const client = useGqlClient();
  const theme = useTheme();
  const {
    data: dmnBookingTypes,
    isLoading: dmnBookingTypesLoading,
    refetch,
  } = useDmnBookingTypesQuery(
    client,
    {
      locationIDs: props.locations,
      numberOfPeople: props.numberOfPeople,
    },
    {
      enabled: useDmnAvailability,
    }
  );

  const { data: brandData } = useGetBrandQuery(
    client,
    {
      id: brand && brand.id ? brand.id : "",
    },
    {
      enabled: brand && brand.id ? true : false,
    }
  );

  const [days, setDays] = useState<Days[]>(
    props.days.length > 0
      ? props.days
      : [
          Days.Sunday,
          Days.Monday,
          Days.Tuesday,
          Days.Wednesday,
          Days.Thursday,
          Days.Friday,
          Days.Saturday,
        ]
  );

  useEffect(() => {
    if (!dmnBookingTypes) {
      return;
    }

    const validBookingTypes = dmnBookingTypes.dmnBookingTypes.filter(
      (bt) => bt.missingLocations.length === 0
    );
    const inValidBookingTypes = dmnBookingTypes.dmnBookingTypes.filter(
      (bt) => bt.missingLocations.length > 0
    );

    setValidDmnBookingTypes(validBookingTypes);
    setInvalidDmnBookingTypes(inValidBookingTypes);
  }, [dmnBookingTypes]);

  useEffect(() => {
    setUseDmnAutoCreate(props.bookingPlatformAutoCreate);
  }, [props.bookingPlatformAutoCreate]);

  useEffect(() => {
    if (!brandData || !brandData.brand) {
      return;
    }

    setDmnEnabled(brandData.brand.dmnEnabled);
    setDmnBookingEnabled(brandData.brand.dmnBookingsEnabled);

    if (props.dmnBookingType) {
      setUseDmnAvailability(true);
    }
  }, [brandData, props.dmnBookingType]);

  useEffect(() => {
    if (!props.numberOfPeople) {
      return;
    }
    refetch();
  }, [props.numberOfPeople, refetch]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const fd = new FormData(e.currentTarget);
    const submitter =
      (e.nativeEvent as any).submitter ||
      e.currentTarget.querySelector('[type="submit"]:focus');
    const formEmitterName = submitter ? submitter.name : "";

    if (formEmitterName === "saveDraft") {
      props.setCurrentStep(2);
      return;
    }

    props.setStartDate(
      fd.get("startDate") && fd.get("StartDate") !== ""
        ? getUnixTime(new Date(fd.get("startDate") as string))
        : null
    );

    props.setEndDate(
      fd.get("endDate") && fd.get("endDate") !== ""
        ? getUnixTime(new Date(fd.get("endDate") as string))
        : null
    );

    if (days.length === 0) {
      alert("Please select at least one day");
      return;
    }

    if (useDmnAvailability && !selectedBookingType) {
      alert(
        "Please select an Access Collins booking type to use live availability"
      );
      return;
    }

    if (useDmnAvailability && selectedBookingType) {
      props.setDmnBookingType(selectedBookingType);
      props.setBookingPlatformAutoCreate(useDmnAutoCreate);
    } else if (!useDmnAvailability) {
      props.setDmnBookingType("");
      props.setBookingPlatformAutoCreate(false);
    }
    props.setDays(days);
    props.setCurrentStep(4);
  };

  return (
    <StyledForm onSubmit={onSubmit}>
      <Card margin="l 0 xl" overflow="visible" padding="0 0 xxs">
        <Content>
          <Flex justify="space-between" align="center">
            <div>
              <H2 margin="0">Ongoing</H2>
              <Text margin="xs 0 0 0" colorPreset="secondary">
                A scheduled visit based on location opening hours
              </Text>
            </div>
            <Button
              buttonType="quaternary"
              onClick={() => {
                props.setListingType(undefined);
              }}
            >
              Change
            </Button>
          </Flex>
        </Content>
        <CardDivider margin="s 0 0" />
        <Content>
          <DoubleInputGroup>
            <Input
              label="Start date"
              defaultValue={
                props.startDate
                  ? format(fromUnixTime(props.startDate), "yyyy-MM-dd")
                  : undefined
              }
              name="startDate"
              type="date"
              margin="0"
            />
            <Input
              label="End date"
              defaultValue={
                props.endDate
                  ? format(fromUnixTime(props.endDate), "yyyy-MM-dd")
                  : undefined
              }
              name="endDate"
              type="date"
              margin="0 0 0 0"
            />
          </DoubleInputGroup>

          <Text weight="semi" margin="l 0 0 0">
            Days
          </Text>

          <DayPicker
            margin="s 0 xl 0"
            initialValue={dayEnumToDays(days)}
            onChange={(days) => {
              setDays(daysToDaysEnum(days));
            }}
          />

          {dmnEnabled ? (
            <>
              <Flex
                direction="row"
                align="center"
                margin="l 0 l 0"
                style={{ cursor: "pointer" }}
                onClick={() => setUseDmnAvailability(!useDmnAvailability)}
              >
                <View style={{ flex: 1 }}>
                  <Text weight="semi" margin="0 0 0 0">
                    Live availability
                  </Text>
                  <Flex align="center">
                    <Text size="s" colorPreset="secondary" margin="0 xs 0 0">
                      Show influencers real-time availability from Access
                      Collins
                    </Text>
                    <Tooltip>
                      <Text size="s" margin="0">
                        Start and end dates, along with selected days of the
                        week, will be used to narrow down the availability from
                        Access Collins
                      </Text>
                      {dmnBookingEnabled ? null : (
                        <Text margin="s 0 0" size="xs" colorPreset="secondary">
                          Interested in automatically creating booking on Access
                          Collins once approved on Joli?{" "}
                          <span
                            style={{
                              color: lightTheme.color.typography.link,
                              fontWeight: lightTheme.typography.weight.semi,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              showNewMessages(
                                "Hey Joli Team, can you let me know what I'd need to automatically create Access Collins bookings once approved on Joli?"
                              );
                            }}
                          >
                            Chat to us
                          </span>
                        </Text>
                      )}
                    </Tooltip>
                  </Flex>
                </View>
                <Switch
                  value={useDmnAvailability}
                  onChange={() => setUseDmnAvailability(!useDmnAvailability)}
                />
              </Flex>
            </>
          ) : null}

          {useDmnAvailability ? (
            <View margin="0 0 l">
              <Input
                name="numGuests"
                type="number"
                label="Number of guests"
                defaultValue={props.numberOfPeople ? props.numberOfPeople : 2}
                onChange={(e) =>
                  props.setNumberOfPeople(Number(e.currentTarget.value))
                }
                margin="m 0 0 0"
              />
              {dmnBookingTypesLoading ? (
                <Flex align="center" justify="center" margin="xl 0">
                  <Loader />
                </Flex>
              ) : (
                <>
                  {validDmnBookingTypes && validDmnBookingTypes.length > 0 ? (
                    <>
                      <Text weight="semi" margin="l 0 s">
                        Booking type
                      </Text>
                      <Dropdown
                        selectionMode="single"
                        selectedOptions={
                          selectedBookingType ? [selectedBookingType] : []
                        }
                        setSelectedOptions={(selectedOptions) => {
                          const selectedValue = selectedOptions[0]; // Handle single selection
                          setSelectedBookingType(selectedValue);
                        }}
                        renderLabel={() => {
                          if (!selectedBookingType) {
                            return "Select a booking type...";
                          }
                          const selectedOption = validDmnBookingTypes.find(
                            (bt) => bt.id === selectedBookingType
                          );
                          return `${selectedOption?.name}`;
                        }}
                        placeholder="Select a booking type..."
                        options={validDmnBookingTypes.map((bt) => ({
                          label: bt.name,
                          value: bt.id,
                        }))}
                      />
                    </>
                  ) : (
                    <Text size="xs" margin="s 0" colorPreset="warning">
                      No booking types available on Access Collins for the
                      selected number of guests and locations
                    </Text>
                  )}

                  {invalidDmnBookingTypes &&
                  invalidDmnBookingTypes.length > 0 ? (
                    <View margin="l 0 0">
                      <Callout
                        type="info"
                        header="Booking types must be available for all selected locations"
                        children={
                          <>
                            <OptionsToggleWrap
                              onClick={() =>
                                setShowInvalidDmnBookingTypes(
                                  !showInvalidDmnBookingTypes
                                )
                              }
                            >
                              <Text margin="0" size="s">
                                {showInvalidDmnBookingTypes
                                  ? "Hide invalid booking types"
                                  : "Show invalid booking types"}
                              </Text>
                              <Chevron
                                size="s"
                                color={theme.color.secondary}
                                direction="down"
                              />
                            </OptionsToggleWrap>
                            {showInvalidDmnBookingTypes &&
                              invalidDmnBookingTypes.map((bt) => {
                                return (
                                  <Flex
                                    margin="l 0 s"
                                    key={bt.id}
                                    direction="column"
                                  >
                                    <Text
                                      key={bt.id}
                                      size="s"
                                      weight="bold"
                                      margin="0 0 0 0"
                                      isCompact
                                    >
                                      {bt.name}
                                    </Text>
                                    <Text
                                      size="xs"
                                      margin="0 0 0"
                                      colorPreset="secondary"
                                    >
                                      Unavailable at:{" "}
                                      {bt.missingLocations.map((l, index) => {
                                        return (
                                          <span key={l.id}>
                                            {l.name}
                                            {index <
                                            bt.missingLocations.length - 1
                                              ? ", "
                                              : ""}
                                          </span>
                                        );
                                      })}
                                    </Text>
                                  </Flex>
                                );
                              })}
                          </>
                        }
                      />
                    </View>
                  ) : null}

                  {selectedBookingType && dmnBookingEnabled ? (
                    <>
                      <Flex
                        direction="row"
                        align="center"
                        margin="xl 0 l 0"
                        style={{ userSelect: "none", cursor: "pointer" }}
                        onClick={() => setUseDmnAutoCreate(!useDmnAutoCreate)}
                      >
                        <View style={{ flex: 1 }}>
                          <Text weight="semi" margin="0 0 0 0">
                            Automate bookings
                          </Text>
                          <Flex align="center">
                            <Text
                              size="s"
                              colorPreset="secondary"
                              margin="0 xs 0 0"
                            >
                              Create bookings automatically on Access Collins
                              once approved on Joli
                            </Text>
                          </Flex>
                        </View>
                        <Switch
                          value={useDmnAutoCreate}
                          onChange={() =>
                            setUseDmnAutoCreate(!useDmnAutoCreate)
                          }
                        />
                      </Flex>
                      {useDmnAutoCreate ? (
                        <TextArea
                          label="Internal booking notes"
                          margin="l 0 m 0"
                          name="internalBookingNotes"
                          rows={3}
                          help="Added to the Access Collins booking alongside the influencer's details"
                          characterLimit={150}
                          characterLength={internalBookingNotesLength}
                          onChange={(e) => {
                            setInternalBookingNotesLength(
                              e.currentTarget.value.length
                            );
                            props.setInternalBookingNotes(
                              e.currentTarget.value
                            );
                          }}
                          value={props.internalBookingNotes}
                          placeholder="eg. Complimentary meal x2 and a bottle of wine to share (or 2 cocktails each)"
                        />
                      ) : null}
                    </>
                  ) : null}
                </>
              )}
            </View>
          ) : null}
        </Content>
      </Card>
      <Footer>
        <ButtonWrap>
          <SecondaryButton
            margin="0"
            type="submit"
            name="saveDraft"
            onClick={() => {
              props.setCurrentStep(3);
              if (!useDmnAvailability) {
                props.setDmnBookingType("");
              }
            }}
            value={!true ? "Saving..." : "Back"}
          />
          <Submit
            margin="0"
            type="submit"
            name="publish"
            value={!true ? "Saving..." : "Next"}
          />
        </ButtonWrap>
      </Footer>
    </StyledForm>
  );
};

export const DoubleInputGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(p) => p.theme.spacing.l};
  margin-bottom: ${(p) => p.theme.spacing.l};
  margin-top: ${(p) => p.theme.spacing.s};
`;

const OptionsToggleWrap = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color = ${(p) => p.theme.color.secondary};
`;
