import { useState } from "react";
import {
  InstagramEngagementRateHelpText,
  InstagramEngagementsHelpText,
  ReportReachHelpText,
} from "../config";
import styled from "../styles";
import { Card } from "./Card";
import { ChartData, Charts } from "./Charts";
import { Flex } from "./Flex";
import { PercentageChange } from "./PercentageChange";
import { Text } from "./Text";
import { Tooltip } from "./Tooltip";
import { View } from "./View";

interface Props {
  label: string;
  value: number;
  previousValue?: number;
  metrics?: ChartData[];
  breakdown?: {
    label: string;
    value: number;
  }[];
}

const CardContent = styled(View)`
  min-height: 55px;
`;

const BreakdownText = styled(Text)`
  color: ${(p) => p.theme.color.primary};
`;

const BreakdownWrap = styled(Flex)`
  background-color: ${(p) => p.theme.color.card.callout};
  border-radius: 0 0 ${(p) => p.theme.misc.borderRadius}
    ${(p) => p.theme.misc.borderRadius};
  padding: ${(p) => p.theme.spacing.s} 0;
  cursor: pointer;

  :hover {
    background-color: ${(p) => p.theme.color.card.callout}80;
  }
`;

const BreakdownGrid = styled.div<{ columns?: number }>`
  display: grid;
  grid-template-columns: repeat(${(p) => p.columns}, 1fr);
  padding: ${(p) => p.theme.spacing.s} 0 ${(p) => p.theme.spacing.xs};
  justify-items: center;

  div {
    width: 100%;
    align-items: center;
    border-right: 1px solid ${(p) => p.theme.color.card.divider};
    :last-child {
      border-right: 1px solid ${(p) => p.theme.color.card.background};
    }
  }
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: unset;
`;

export function StatsCard(props: Props) {
  const previousValue =
    props.previousValue && props.previousValue === 0 ? 1 : props.previousValue;
  const [showBreakdown, setShowBreakdown] = useState(false);
  return (
    <StyledCard>
      <CardContent padding="s m xs">
        {!showBreakdown ? (
          <>
            <Flex direction="column" justify="space-between">
              <Flex justify="space-between" align="baseline">
                <Flex align="center" justify="flex-start">
                  <Text size="s" isCompact margin="0 xs 0 0" weight="semi">
                    {props.label}
                  </Text>
                  {props.label === "Total Reach" ? (
                    <div style={{ marginTop: 2 }}>
                      <Tooltip>
                        <Text size="s" margin="0">
                          {ReportReachHelpText}
                        </Text>
                      </Tooltip>
                    </div>
                  ) : null}
                  {props.label === "Total Engagements" ? (
                    <div style={{ marginTop: 2 }}>
                      <Tooltip>
                        <Text size="s" margin="0">
                          {InstagramEngagementsHelpText}
                        </Text>
                      </Tooltip>
                    </div>
                  ) : null}
                  {props.label === "Engagement" ? (
                    <div style={{ marginTop: 2 }}>
                      <Tooltip>
                        <Text size="s" margin="0">
                          {InstagramEngagementRateHelpText}
                        </Text>
                      </Tooltip>
                    </div>
                  ) : null}
                </Flex>
                {previousValue ? (
                  <PercentageChange
                    percentage={
                      ((props.value - previousValue) / previousValue) * 100
                    }
                  />
                ) : null}
              </Flex>

              <Flex direction="row" justify="space-between" align="baseline">
                <Flex direction="row" align="baseline">
                  <Text
                    colorPreset="heading"
                    weight="bold"
                    size="xxl"
                    isCompact
                    margin="0"
                  >
                    {props.value.toLocaleString()}
                    {props.label === "Engagement" && "%"}
                  </Text>
                  {previousValue !== undefined && previousValue > 1 && (
                    <Text size="xs" isCompact margin="0">
                      &nbsp; from {previousValue.toLocaleString()}
                      {props.label === "Engagement Rate" && "%"}
                    </Text>
                  )}
                </Flex>
              </Flex>
            </Flex>
            {props.metrics && (
              <Charts name={props.label} data={props.metrics} />
            )}
          </>
        ) : (
          <>
            <BreakdownGrid columns={props.breakdown?.length}>
              {props.breakdown &&
                props.breakdown.map((b) => {
                  return (
                    <Flex justify="flex-start" direction="column">
                      <Text
                        align="center"
                        isCompact
                        size="xxxs"
                        weight="bold"
                        margin="0"
                      >
                        {b.label}
                      </Text>
                      <Text margin="0" colorPreset="heading" weight="bold">
                        {b.value.toLocaleString()}
                        {b.label === "Approval" && "%"}
                      </Text>
                    </Flex>
                  );
                })}
            </BreakdownGrid>
          </>
        )}
      </CardContent>

      {!props.metrics && (
        <BreakdownWrap
          margin="0"
          align="center"
          onClick={() => setShowBreakdown(!showBreakdown)}
        >
          <BreakdownText size="xxs" weight="semi" margin="0 0 0 m">
            {!showBreakdown ? "View breakdown" : "Hide breakdown"}
          </BreakdownText>
        </BreakdownWrap>
      )}
    </StyledCard>
  );
}
