import { useEffect } from "react";
import Loading from "../../../components/Loading";
import config from "../../../config";

export function FacebookRedirect() {
  const stateHash = `${(+new Date().setHours(0, 0, 0, 0)).toString(
    36
  )}_facebook`;

  useEffect(() => {
    const uniqueParam = `cacheBuster=${new Date().getTime()}`;
    const redirectUrl = `https://www.facebook.com/v10.0/dialog/oauth?client_id=${
      config.facebookAppId
    }&redirect_uri=${encodeURIComponent(
      `${window.location.origin}/app-connect-facebook/connect/`
    )}&response_type=token&scope=pages_show_list,pages_read_engagement,instagram_basic,public_profile,instagram_manage_insights,business_management&auth_type=rerequest&state=${stateHash}&${uniqueParam}`;

    console.log("Redirecting to:", redirectUrl); // Debugging line

    window.location.href = redirectUrl;
  }, [stateHash]);

  return <Loading />;
}
