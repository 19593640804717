import { Content, Footer, SecondaryButton, StyledForm } from ".";
import { ButtonWrap, Submit } from "../../../components/CTA";
import { Card } from "../../../components/Card";
import { Chevron } from "../../../components/Chevron";
import { Flex } from "../../../components/Flex";
import { H2 } from "../../../components/Heading";
import { MostPopularTag } from "../../../components/Tag";
import { Text } from "../../../components/Text";
import { TextLink } from "../../../components/TextLink";
import { Tooltip } from "../../../components/Tooltip";
import { View } from "../../../components/View";
import { BoltIcon } from "../../../components/icons/BoltIcon";
import { CalendarIcon } from "../../../components/icons/CalendarIcon";
import { ClockIcon } from "../../../components/icons/ClockIcon";
import { RepeatIcon } from "../../../components/icons/RepeatIcon";
import { Days, ListingType } from "../../../graphql/generated";
import styled from "../../../styles";

interface ChooseListingTypeProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setListingType: React.Dispatch<React.SetStateAction<ListingType | undefined>>;
  listingType: ListingType | undefined;
}

const StyledCard = styled(Card)`
  border: 1px solid ${(p) => p.theme.color.typography.secondary}50;
  box-shadow: none;

  &:hover {
    box-shadow: ${(p) => p.theme.shadow.card};
  }
`;

export const ChooseListingType = (props: ChooseListingTypeProps) => {
  const daysOptions: { label: string; value: string; isChecked: boolean }[] =
    [];

  Object.keys(Days).forEach((d) => {
    daysOptions.push({
      label: d,
      value: d.toUpperCase(),
      isChecked: true,
    });
  });

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.setCurrentStep(4);
  };

  return (
    <StyledForm onSubmit={onSubmit}>
      <Card margin="l 0 xl" padding="0 0 l" style={{ overflow: "visible" }}>
        <Content>
          <H2 margin="0 0 l 0">Availability</H2>
          <StyledCard
            onClick={() => {
              props.setListingType(ListingType.Ongoing);
            }}
            flex={1}
            padding="m"
            margin="0 0 l"
            style={{ overflow: "visible" }}
          >
            <Flex align="center" justify="space-between">
              <Flex direction="row" justify="flex-start">
                <div style={{ marginTop: 2 }}>
                  <ClockIcon colorPreset="text" />
                </div>
                <View margin="0 0 0 s">
                  <Flex>
                    <Text margin="0 s 0 0" weight="bold">
                      Ongoing
                    </Text>
                    <div style={{ marginTop: -3 }}>
                      <MostPopularTag />
                    </div>
                  </Flex>
                  <Flex>
                    <Text size="s" margin="0 xs 0 0" colorPreset="secondary">
                      A scheduled visit based on location opening hours
                    </Text>
                    <Tooltip>
                      <Text size="s" margin="0">
                        Manage each location's opening hours and block off
                        specific times on the{" "}
                        <TextLink size="s" isInline to="/b/locations">
                          Locations page
                        </TextLink>
                      </Text>
                    </Tooltip>
                  </Flex>
                </View>
              </Flex>
              <Flex>
                <Chevron />
              </Flex>
            </Flex>
          </StyledCard>
          <StyledCard
            onClick={() => {
              props.setListingType(ListingType.RedeemAnytime);
            }}
            flex={1}
            padding="m"
            margin="0 0 l"
          >
            <Flex align="center" justify="space-between">
              <Flex direction="row" justify="flex-start">
                <div style={{ marginTop: 3, marginRight: 2 }}>
                  <BoltIcon colorPreset="text" />
                </div>
                <View margin="0 0 0 s">
                  <Flex direction="row" align="center">
                    <Text margin="0" weight="bold">
                      Redeem anytime
                    </Text>
                  </Flex>

                  <Text size="s" margin="0" colorPreset="secondary">
                    Available anytime without the need for prior scheduling
                  </Text>
                </View>
              </Flex>
              <Flex>
                <Chevron />
              </Flex>
            </Flex>
          </StyledCard>
          <StyledCard
            onClick={() => {
              props.setListingType(ListingType.Scheduled);
            }}
            flex={1}
            padding="m"
            margin="0 0 l"
          >
            <Flex align="center" justify="space-between">
              <Flex direction="row" justify="flex-start">
                <div style={{ marginTop: 2 }}>
                  <RepeatIcon colorPreset="text" />
                </div>
                <View margin="0 0 0 s">
                  <Text margin="0" weight="bold">
                    Repeat event
                  </Text>
                  <Text size="s" margin="0" colorPreset="secondary">
                    eg. Brunch at 1pm and 3pm on Saturdays and Sundays
                  </Text>
                </View>
              </Flex>
              <Flex>
                <Chevron />
              </Flex>
            </Flex>
          </StyledCard>

          <StyledCard
            onClick={() => {
              props.setListingType(ListingType.Event);
            }}
            flex={1}
            padding="m"
            margin="0"
          >
            <Flex align="center" justify="space-between">
              <Flex direction="row" justify="flex-start">
                <div style={{ marginTop: 2 }}>
                  <CalendarIcon colorPreset="text" />
                </div>
                <View margin="0 0 0 s">
                  <Text margin="0" weight="bold">
                    One-off event
                  </Text>
                  <Text size="s" margin="0" colorPreset="secondary">
                    e.g. Launch party on 16th August at 7:30pm
                  </Text>
                </View>
              </Flex>
              <Flex>
                <Chevron />
              </Flex>
            </Flex>
          </StyledCard>
        </Content>
      </Card>
      <Footer margin="xl 0 0 0">
        <ButtonWrap>
          <SecondaryButton
            margin="0"
            type="submit"
            name="saveDraft"
            onClick={() => props.setCurrentStep(2)}
            value={!true ? "Saving..." : "Back"}
          />
          <Submit
            margin="0"
            type="submit"
            name="publish"
            value={!true ? "Saving..." : "Next"}
          />
        </ButtonWrap>
      </Footer>
    </StyledForm>
  );
};
