import { useEffect, useState } from "react";
import { FakeBackLink } from "../../components/BackLink";
import { CancelIcon } from "../../components/CancelIcon";
import { Chevron } from "../../components/Chevron";
import { Flex } from "../../components/Flex";
import Loader from "../../components/Loader";
import { Text } from "../../components/Text";
import { ToggleButton } from "../../components/ToggleButton";
import { ToggleProduct, useToggleProductsQuery } from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import styled, { css } from "../../styles";
import withPadding from "../../styles/withPadding";

export const ToggleWrapper = styled.div<{
  isError?: boolean;
  padding?: string;
}>`
  background-color: ${(p) => p.theme.color.card.background};
  border: 2px solid ${(p) => p.theme.color.typography.secondary}50;
  border-radius: 5px;
  font-size: ${(p) => p.theme.typography.size.m};
  font-weight: ${(p) => p.theme.typography.weight.normal};
  margin-top: ${(p) => p.theme.spacing.s};
  overflow-y: scroll;
  max-height: 250px;

  color: ${(p) => p.theme.color.typography.text};

  &::placeholder {
    color: ${(p) => p.theme.color.typography.secondary};
  }

  ${(p) =>
    p.isError
      ? css`
          border-color: ${(p) => p.theme.color.destructive};
        `
      : null}

  ${withPadding}
`;

const ToggleProductItem = styled(Flex)`
  &:hover {
    background-color: ${(p) => p.theme.color.background.start};
    cursor: pointer;
  }
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
  border-bottom: 1px solid ${(p) => p.theme.color.card.divider};
`;

const ToggleCustomPriceWrapper = styled.input`
  background-color: ${(p) => p.theme.color.card.background};
  margin-top: 0;
  border-radius: 5px;
  border: 1px solid ${(p) => p.theme.color.typography.secondary};
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.s}
    ${(p) => p.theme.spacing.s} 30px;
  font-weight: ${(p) => p.theme.typography.weight.normal};
  font-size: ${(p) => p.theme.typography.size.m};

  color: ${(p) => p.theme.color.typography.text};

  &::placeholder {
    color: ${(p) => p.theme.color.typography.secondary};
  }

  &:hover {
    border-color: ${(p) => p.theme.color.typography.text};
    outline: none;
  }

  &:focus,
  &:active {
    border-color: ${(p) => p.theme.color.primary};
    box-shadow: 0px 0px 0px 2px ${(p) => p.theme.color.input.hover};
    outline: none;
  }
`;

const ToggleCurrencyWrap = styled(Flex)`
  position: absolute;
  height: 100%;
  margin-left: ${(p) => p.theme.spacing.xs};
`;

interface SelectToggleProductProps {
  onProductSelected: (
    productId: number,
    value: number,
    priceModifierId?: number
  ) => void;
  onClear: () => void;
}

export function SelectToggleProduct(props: SelectToggleProductProps) {
  const client = useGqlClient();
  const toggleQuery = useToggleProductsQuery(client);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const [selectedProduct, setSelectedProduct] = useState<ToggleProduct>();
  const [selectedPriceModifierId, setSelectedPriceModifierId] =
    useState<number>();
  const [value, setValue] = useState<number>();

  useEffect(() => {
    if (!selectedProduct || !value) {
      return;
    }

    props.onProductSelected(selectedProduct.id, value, selectedPriceModifierId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct, selectedPriceModifierId, value]);

  if (toggleQuery.isLoading) {
    return (
      <Flex margin="xl 0" align="center" justify="center">
        <Loader />
      </Flex>
    );
  }

  if (toggleQuery.error || !toggleQuery.data) {
    return <Text>Something went wrong</Text>;
  }

  if (selectedProduct) {
    return (
      <ToggleWrapper padding="s m">
        <FakeBackLink
          margin="0 0 m"
          onClick={() => setSelectedProduct(undefined)}
        >
          <Chevron size="s" direction="left" /> Back
        </FakeBackLink>
        <Flex align="center" justify="space-between">
          <Flex direction="column">
            <Text weight="normal" margin="0 0 xs 0">
              {selectedProduct.name}{" "}
            </Text>
            <Flex margin="0 0 s 0">
              {selectedProduct.priceModifiers.length > 0 ? (
                <>
                  {selectedProduct.priceModifiers.map((pm) => {
                    return (
                      <ToggleButton
                        onClick={() => {
                          setSelectedPriceModifierId(pm.id);
                          setValue(pm.value);
                        }}
                        margin="0 s 0 0"
                        title={`£${pm.value / 100}.00`}
                        active={pm.id === selectedPriceModifierId}
                      />
                    );
                  })}
                </>
              ) : (
                <div style={{ position: "relative" }}>
                  <ToggleCurrencyWrap align="center">
                    <Text size="m" margin="0 0 0 s">
                      £
                    </Text>
                  </ToggleCurrencyWrap>
                  <ToggleCustomPriceWrapper
                    autoFocus
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const newValue =
                        Number.parseInt(e.currentTarget.value, 10) * 100;

                      if (timeoutId) {
                        clearTimeout(timeoutId);
                      }

                      setTimeoutId(
                        setTimeout(() => {
                          setValue(newValue);
                        }, 1000)
                      );
                    }}
                    type="number"
                  />
                </div>
              )}
            </Flex>
          </Flex>
          <CancelIcon
            onClick={() => {
              props.onClear();
            }}
          />
        </Flex>
      </ToggleWrapper>
    );
  }

  return (
    <ToggleWrapper isError={false}>
      {toggleQuery.data.toggleProducts.map((tp) => {
        return (
          <ToggleProductItem
            justify="space-between"
            align="center"
            onClick={() => {
              setSelectedProduct(tp);
            }}
          >
            <Text margin="0" weight="normal">
              {tp.name}
            </Text>
            <Chevron size="s" />
          </ToggleProductItem>
        );
      })}
    </ToggleWrapper>
  );
}
