import { useState } from "react";
import { useWindowSize } from "../hooks/useWindowSize";
import { Flex } from "./Flex";
import { ToggleButton } from "./ToggleButton";

interface Props {
  margin?: string;
  padding?: string;
  onChange: (days: number[]) => void;
  initialValue?: number[];
}

const options = [
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
  { label: "Sunday", value: 0 },
];

export function DayPicker(props: Props) {
  const [days, setDays] = useState<number[]>(
    props.initialValue ? props.initialValue : []
  );
  const window = useWindowSize();

  return (
    <Flex wrap="wrap" margin="s 0 s">
      {options.map((option, index) => {
        return (
          <ToggleButton
            size="s"
            margin="0 m m 0"
            title={
              window.width && window.width < 400
                ? option.label.slice(0, 3)
                : option.label
            }
            onClick={() => {
              const newDays = days.includes(option.value)
                ? days.filter((day) => day !== option.value)
                : [...days, option.value];
              setDays(newDays);
              props.onChange(newDays);
            }}
            active={days.includes(option.value)}
            key={index}
          />
        );
      })}
    </Flex>
  );
}
