import { addMonths, format, fromUnixTime, getUnixTime } from "date-fns";
import { Content, Footer, SecondaryButton, StyledForm } from ".";
import { Card } from "../../../components/Card";
import { Button, ButtonWrap, Submit } from "../../../components/CTA";
import { CardDivider } from "../../../components/Divider";
import { Flex } from "../../../components/Flex";
import { H2 } from "../../../components/Heading";
import { Input } from "../../../components/Input";
import { Option, Select } from "../../../components/Select";
import { Text } from "../../../components/Text";
import { ListingType } from "../../../graphql/generated";

interface EventProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setTimeSlot: React.Dispatch<React.SetStateAction<number | undefined>>;
  timeSlot: number | undefined;
  setListingType: React.Dispatch<React.SetStateAction<ListingType | undefined>>;
}

function getHours(): Option[] {
  const hours: Option[] = [];
  for (let i = 0; i < 24; i++) {
    hours.push({
      value: {
        hour: i,
        minute: 0,
      },
      label: `${i < 10 ? "0" : ""}${i}`,
    });
  }
  return hours;
}

export const Event = (props: EventProps) => {
  const nextMonth = format(addMonths(new Date(), 1), "do MMMM");
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const fd = new FormData(e.currentTarget);
    const hour = fd.get("hour");
    const minute = fd.get("minute");
    const time = `${hour}:${minute}`;
    const date = fd.get("date");

    if (!hour || !minute || !date) {
      alert("Please select a date and time");
      return;
    }

    const timeSlot = getUnixTime(new Date(`${date} ${time}` as string));
    props.setTimeSlot(timeSlot);
    props.setCurrentStep(4);
  };

  return (
    <StyledForm onSubmit={onSubmit}>
      <Card margin="l 0 xl">
        <Content>
          <Flex justify="space-between" align="center">
            <div>
              <H2 margin="0">One-time event</H2>
              <Text margin="xs 0 0 0" colorPreset="secondary">
                eg. Cocktail masterclass on {nextMonth} at 7:30pm
              </Text>
            </div>
            <Button
              buttonType="quaternary"
              onClick={() => {
                props.setListingType(undefined);
              }}
            >
              Change
            </Button>
          </Flex>
        </Content>
        <CardDivider margin="s 0 0" />
        <Content>
          <Input
            defaultValue={
              props.timeSlot
                ? format(fromUnixTime(props.timeSlot), "yyyy-MM-dd")
                : undefined
            }
            label="Date"
            name="date"
            type="date"
            margin="0"
          />
          <Text weight="semi" margin="xl 0 0 0">
            Time
          </Text>
          <Flex
            align="center"
            style={{ alignItems: "center" }}
            margin="s 0 xl 0"
          >
            <Select
              defaultValue={
                props.timeSlot
                  ? format(fromUnixTime(props.timeSlot), "HH")
                  : undefined
              }
              name="hour"
              onSelect={(option) => {}}
              options={getHours()}
            ></Select>
            <Text weight="bold" margin="0 xs">
              :
            </Text>
            <Select
              defaultValue={
                props.timeSlot
                  ? format(fromUnixTime(props.timeSlot), "mm")
                  : undefined
              }
              name="minute"
              onSelect={(option) => {}}
              options={[
                { label: "00", value: 0 },
                { label: "15", value: 15 },
                { label: "30", value: 30 },
                { label: "45", value: 45 },
              ]}
            ></Select>
          </Flex>
        </Content>
      </Card>
      <Footer margin="xl 0 0 0">
        <ButtonWrap>
          <SecondaryButton
            margin="0"
            type="submit"
            name="saveDraft"
            onClick={() => props.setCurrentStep(2)}
            value={!true ? "Saving..." : "Back"}
          />
          <Submit
            margin="0"
            type="submit"
            name="publish"
            value={!true ? "Saving..." : "Next"}
          />
        </ButtonWrap>
      </Footer>
    </StyledForm>
  );
};
