import React, { useEffect } from "react";
import { useTheme } from "../hooks/useTheme";
import styled, { css } from "../styles";
import { WithMarginProp } from "../styles/withMargin";
import { Flex } from "./Flex";
import { Text } from "./Text";
import { ExternalTextLink } from "./TextLink";

type Props = {
  label?: string;
  defaultValue?: string | number;
  name?: string;
  value?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  help?: string;
  helpWithLink?: {
    preText: string;
    linkLabel: string;
    linkURL: string;
    postText?: string;
  };
  type?: string;
  error?: string;
  rows?: number;
  characterLimit?: number;
  characterLength?: number;
};

export const TextArea = (props: Props & WithMarginProp) => {
  const theme = useTheme();
  const ref = React.useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    ref.current.style.height = "0px";
    const scrollHeight =
      ref.current.scrollHeight < 150
        ? props.rows
          ? props.rows * 32
          : 150
        : ref.current.scrollHeight;

    ref.current.style.height = scrollHeight + "px";
  }, [ref, props.value, props.rows]);

  return (
    <Flex direction="column" margin={props.margin}>
      {props.label && (
        <Text weight="semi" margin="0">
          {props.label}
        </Text>
      )}
      {props.helpWithLink && (
        <Text isInline size="s" margin="0" colorPreset="secondary">
          {props.helpWithLink.preText}&nbsp;
          <ExternalTextLink
            size="s"
            colorPreset="secondary"
            isInline
            href={props.helpWithLink.linkURL}
            target="_blank"
          >
            {props.helpWithLink.linkLabel}
          </ExternalTextLink>
          &nbsp;{props.helpWithLink.postText}
        </Text>
      )}
      {props.help && (
        <Text size="s" margin="0" colorPreset="secondary">
          {props.help}
        </Text>
      )}
      <TextAreaWrap>
        <StyledArea
          isError={!!props.error}
          ref={ref}
          placeholder={props.placeholder}
          defaultValue={props.defaultValue}
          rows={props.rows ? props.rows : 6}
          name={props.name}
          hasLabel={props.label ? true : false}
          onChange={props.onChange}
          value={props.value}
        />
        {props.characterLimit && (
          <CharacterLimit
            size="xs"
            margin="xs s xs"
            weight={
              (props.characterLength ? props.characterLength : 0) >
              props.characterLimit
                ? "semi"
                : "normal"
            }
            colorPreset={
              (props.characterLength ? props.characterLength : 0) >
              props.characterLimit
                ? "error"
                : "text"
            }
          >
            {props.characterLength ? props.characterLength : 0}/
            {props.characterLimit}
          </CharacterLimit>
        )}
      </TextAreaWrap>

      {props.error && (
        <Text size="s" margin="0 0 0 0" color={theme.color.warning}>
          {props.error}
        </Text>
      )}
    </Flex>
  );
};

const CharacterLimit = styled(Text)`
  position: absolute;
  right: ${(p) => p.theme.spacing.xs};
  bottom: ${(p) => p.theme.spacing.s};
`;

// const RichTextHeader = styled.div`
//   display: flex;
//   background-color: ${(p) => p.theme.color.background.start};
//   padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.l};
//   padding-bottom: ${(p) => p.theme.spacing.xs};
//   border-bottom: 1px solid ${(p) => p.theme.color.card.divider};

//   span {
//     cursor: pointer;
//     color: ${(p) => p.theme.color.typography.secondary};
//     font-weight: ${(p) => p.theme.typography.weight.semi};
//     font-size: ${(p) => p.theme.typography.size.s};
//     margin-right: ${(p) => p.theme.typography.size.xs};

//     &:hover {
//       color: ${(p) => p.theme.color.typography.text};
//     }
//   }

//   a {
//     margin-left: auto;
//     text-align: right;
//     width: 100%;
//     justify-self: flex-end;
//   }
// `;

const TextAreaWrap = styled.div<{}>`
  position: relative;
  padding-bottom: 0px;
`;

const StyledArea = styled.textarea<{ hasLabel: boolean; isError?: boolean }>`
  padding: ${(p) => p.theme.spacing.m};
  border: none;
  background-color: ${(p) => p.theme.color.card.background};
  border: 1px solid ${(p) => p.theme.color.typography.secondary}50;
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  min-width: 100%;
  min-height: 100%;
  max-height: 350px;
  box-sizing: border-box;
  background-color: ${(p) => p.theme.color.card.background};
  margin-bottom: 0;

  ${(p) =>
    p.hasLabel
      ? css`
          margin-top: ${(p) => p.theme.spacing.s};
        `
      : null}

  ${(p) =>
    p.isError
      ? css`
          border-color: ${(p) => p.theme.color.warning};
          box-shadow: 0px 0px 0px 2px ${(p) => p.theme.color.warning}20;
        `
      : null}

  font-size: ${(p) => p.theme.typography.size.m};
  line-height: 140%;
  resize: vertical;
  white-space: pre-line;

  color: ${(p) => p.theme.color.typography.text};

  &:hover {
    border-color: ${(p) => p.theme.color.typography.text};
    outline: none;
  }

  &:focus,
  &:active {
    border-color: ${(p) => p.theme.color.primary};
    box-shadow: 0px 0px 0px 2px ${(p) => p.theme.color.input.hover};
    outline: none;
  }
`;
