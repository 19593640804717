import React from "react";
import { useTheme } from "../hooks/useTheme";
import styled, { css } from "../styles";
import { WithMarginProp } from "../styles/withMargin";
import { Flex } from "./Flex";
import { Text } from "./Text";

type InputProps = {
  label?: string;
  name?: string;
  value?: string | number;
  defaultValue?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  help?: string;
  helpLink?: string;
  type?: string;
  numberStep?: string;
  error?: string;
  autoFocus?: boolean;
  inputSize?: "s" | "xs";
  isDisabled?: boolean;
};

export const Input = (props: InputProps & WithMarginProp) => {
  const theme = useTheme();

  return (
    <Flex direction="column" margin={props.margin}>
      {props.label ? (
        <Text weight="semi" margin="0">
          {props.label}
        </Text>
      ) : null}
      {props.help && (
        <Text size="s" margin="0" colorPreset="secondary">
          {props.helpLink ? (
            <a href={props.helpLink} target="_blank" rel="noreferrer">
              <Text
                size="s"
                margin="0"
                colorPreset="secondary"
                isInline
                style={{ textDecoration: "underline" }}
              >
                Follow this guide{" "}
              </Text>
            </a>
          ) : null}
          {props.help}
        </Text>
      )}
      <StyledInput
        type={props.type ? props.type : "text"}
        isError={!!props.error}
        placeholder={props.placeholder}
        value={props.value}
        step={props.numberStep}
        name={props.name}
        autoFocus={props.autoFocus}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        hasLabel={props.label ? true : false}
        inputSize={props.inputSize}
        pattern={props.type === "phone" ? "[0-9]*" : undefined}
        disabled={props.isDisabled}
      />
      {props.error && (
        <Text size="s" margin="s 0 0 0" color={theme.color.warning}>
          {props.error}
        </Text>
      )}
    </Flex>
  );
};

interface StyledInputProps {
  inputSize?: "s" | "xs";
  isError?: boolean;
  hasLabel?: boolean;
}

const StyledInput = styled.input<StyledInputProps>`
  background-color: ${(p) => p.theme.color.card.background};
  padding: ${(p) =>
    p.inputSize === "s"
      ? p.theme.spacing.s
      : p.inputSize === "xs"
      ? p.theme.spacing.xs
      : p.theme.spacing.m};

  ${(p) =>
    p.hasLabel
      ? css`
          margin-top: ${(p) => p.theme.spacing.s};
        `
      : null}

  ${(p) =>
    p.type === "date" || p.type === "number"
      ? css`
          width: 175px;
          @media (max-width: 500px) {
            width: 125px;
          }
        `
      : null}

  ${(p) =>
    p.type === "date"
      ? css`
          padding: calc(${(p) => p.theme.spacing.m} - 1px);
          ::-webkit-calendar-picker-indicator {
            margin-left: -10px;
          }
        `
      : null}

  border: 1px solid ${(p) => p.theme.color.typography.secondary}50;
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  font-size: ${(p) => p.theme.typography.size.m};

  color: ${(p) => p.theme.color.typography.text};

  &::placeholder {
    color: ${(p) => p.theme.color.typography.secondary};
  }

  &:hover {
    border-color: ${(p) => p.theme.color.typography.text};
    outline: none;
  }

  &:focus,
  &:active {
    border-color: ${(p) => p.theme.color.primary};
    box-shadow: 0px 0px 0px 2px ${(p) => p.theme.color.input.hover};
    outline: none;
  }

  ${(p) =>
    p.isError
      ? css`
          border-color: ${(p) => p.theme.color.warning};
          box-shadow: 0px 0px 0px 2px ${(p) => p.theme.color.warning}20;
        `
      : null}

  ${(p) =>
    p.disabled
      ? css`
          background-color: ${(p) => p.theme.color.input.hover};
          border: 1px solid ${(p) => p.theme.color.typography.secondary}50;
          cursor: not-allowed;
          user-select: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;

          &::selection {
            background: transparent;
          }

          &::-moz-selection {
            background: transparent;
          }

          &:hover {
            border: 1px solid ${(p) => p.theme.color.typography.secondary}50;
            outline: none;
          }

          &:focus,
          &:active {
            box-shadow: none;
            outline: none;
          }
        `
      : null}
`;

interface FakeInputProps {
  isActive?: boolean;
}

export const FakeInput = styled.div<FakeInputProps>`
  background-color: ${(p) => p.theme.color.card.background};

  border: 1px solid ${(p) => p.theme.color.typography.secondary}50;
  border-radius: 5px;
  font-size: ${(p) => p.theme.typography.size.m};
  max-height: 36px;

  color: ${(p) => p.theme.color.typography.text};

  &:hover {
    border-color: ${(p) => p.theme.color.typography.text};
    outline: none;
  }

  ${(p) =>
    p.isActive
      ? css`
          border-color: ${(p) => p.theme.color.primary} !important;
          box-shadow: 0px 0px 0px 2px ${(p) => p.theme.color.input.hover};
          outline: none;
        `
      : null}

  &:focus,
  &:active {
    border-color: ${(p) => p.theme.color.primary};
    box-shadow: 0px 0px 0px 2px ${(p) => p.theme.color.input.hover};
    outline: none;
  }
`;
