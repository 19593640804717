import {
  addDays,
  format,
  fromUnixTime,
  getDay,
  getUnixTime,
  isBefore,
} from "date-fns";
import { useState } from "react";
import styled from "styled-components";
import {
  Content,
  Footer,
  Schedule as ScheduleType,
  SecondaryButton,
  StyledForm,
} from ".";
import { Card } from "../../../components/Card";
import { CloseIcon } from "../../../components/CloseIcon";
import { Button, ButtonWrap, Submit } from "../../../components/CTA";
import { DayPicker } from "../../../components/DayPicker";
import { CardDivider } from "../../../components/Divider";
import { Flex } from "../../../components/Flex";
import { H2 } from "../../../components/Heading";
import { Input } from "../../../components/Input";
import { InlineAddBtn } from "../../../components/NewButton";
import { Option, Select } from "../../../components/Select";
import { Text } from "../../../components/Text";
import {
  Days,
  Listing,
  ListingType,
  ScheduleInput,
} from "../../../graphql/generated";
import { DoubleInputGroup } from "./Availability";

interface RepeatEventProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setSchedules: React.Dispatch<React.SetStateAction<ScheduleInput[]>>;
  schedules: ScheduleInput[];
  setStartDate: React.Dispatch<React.SetStateAction<Listing["startDate"]>>;
  startDate: Listing["startDate"];
  setEndDate: React.Dispatch<React.SetStateAction<Listing["endDate"]>>;
  endDate: Listing["endDate"];
  setListingType: React.Dispatch<React.SetStateAction<ListingType | undefined>>;
}

function getHours(): Option[] {
  const hours: Option[] = [];
  for (let i = 0; i < 24; i++) {
    hours.push({
      value: {
        hour: i,
        minute: 0,
      },
      label: `${i < 10 ? "0" : ""}${i}`,
    });
  }
  return hours;
}

function generateScheduleId() {
  return Math.random().toString(36).substring(2, 9);
}

export const RepeatEvent = (props: RepeatEventProps) => {
  const [schedules, setSchedules] = useState<ScheduleType[]>(
    props.schedules.length > 0
      ? props.schedules.map((si) => {
          return {
            hour: si.startHour,
            minute: si.startMinute,
            days: si.days,
            id: generateScheduleId(),
          };
        })
      : [{ id: generateScheduleId(), days: [], hour: 0, minute: 0 }]
  );

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const fd = new FormData(e.currentTarget);
    const startDate = fd.get("startDate");
    const endDate = fd.get("endDate");

    if (schedules.length === 0 || schedules.every((s) => s.days.length === 0)) {
      alert("Please select at least one day");
      return;
    }

    const timeSlots: number[] = [];
    const sd = new Date(startDate as string);
    const ed = new Date(endDate as string);

    let currentDate = sd;
    while (isBefore(currentDate, ed)) {
      // eslint-disable-next-line no-loop-func
      schedules.forEach((schedule) => {
        if (dayEnumToDays(schedule.days).includes(getDay(currentDate))) {
          currentDate.setHours(schedule.hour);
          currentDate.setMinutes(schedule.minute);

          const timeSlot = getUnixTime(currentDate);
          timeSlots.push(timeSlot);
        }
      });
      currentDate = addDays(currentDate, 1);
    }

    props.setStartDate(
      fd.get("startDate")
        ? getUnixTime(new Date(fd.get("startDate") as string))
        : undefined
    );
    props.setEndDate(
      fd.get("endDate")
        ? getUnixTime(new Date(fd.get("endDate") as string))
        : undefined
    );

    props.setSchedules(
      schedules.map((s) => {
        return {
          days: s.days,
          startHour: s.hour,
          startMinute: s.minute,
        };
      })
    );
    props.setCurrentStep(4);
  };

  return (
    <StyledForm onSubmit={onSubmit}>
      <Card margin="l 0 xl">
        <Content>
          <Flex justify="space-between" align="center">
            <div>
              <H2 margin="0">Recurring event</H2>
              <Text margin="xs 0 0 0" colorPreset="secondary">
                eg. Brunch every Sunday at 1pm
              </Text>
            </div>
            <Button
              buttonType="quaternary"
              onClick={() => {
                props.setListingType(undefined);
              }}
            >
              Change
            </Button>
          </Flex>
        </Content>
        <CardDivider margin="s 0 0" />
        <Content>
          <DoubleInputGroup>
            <Input
              defaultValue={
                props.startDate
                  ? format(fromUnixTime(props.startDate), "yyyy-MM-dd")
                  : undefined
              }
              label="Start date"
              name="startDate"
              type="date"
              margin="0"
            />
            <Input
              defaultValue={
                props.endDate
                  ? format(fromUnixTime(props.endDate), "yyyy-MM-dd")
                  : undefined
              }
              label="End date"
              name="endDate"
              type="date"
              margin="0 0 0 0"
            />
          </DoubleInputGroup>

          {schedules.map((s, i) => {
            return (
              <Schedule
                id={s.id}
                key={s.id}
                index={i}
                initialValue={s}
                onChange={(schedule) => {
                  const newSchedules = schedules.map((s) => {
                    if (s.id === schedule.id) {
                      return schedule;
                    }
                    return s;
                  });
                  setSchedules(newSchedules);
                }}
                onRemove={(id) => {
                  setSchedules(schedules.filter((s) => s.id !== id));
                }}
              />
            );
          })}
          <InlineAddBtn
            margin="0 0 xl"
            label="Add another schedule"
            onClick={() => {
              const newSchedules = [...schedules];
              newSchedules.push({
                id: generateScheduleId(),
                days: [],
                hour: 0,
                minute: 0,
              });
              setSchedules(newSchedules);
            }}
          />
        </Content>
      </Card>
      <Footer margin="xl 0 0 0">
        <ButtonWrap>
          <SecondaryButton
            margin="0"
            type="submit"
            name="saveDraft"
            onClick={() => props.setCurrentStep(2)}
            value={!true ? "Saving..." : "Back"}
          />
          <Submit
            margin="0"
            type="submit"
            name="publish"
            value={!true ? "Saving..." : "Next"}
          />
        </ButtonWrap>
      </Footer>
    </StyledForm>
  );
};

interface ScheduleProps {
  id: string;
  onChange: (schedule: ScheduleType) => void;
  onRemove: (id: string) => void;
  initialValue?: ScheduleType;
  index: number;
}

const CloseIconWrapper = styled.div`
  padding: 8px;
  position: absolute;
  width: 25px;
  height: 25px;
  top: 10px;
  right: 0;
  cursor: pointer;
`;

const ScheduleWrap = styled.div`
  position: relative;
  margin-bottom: ${(p) => p.theme.spacing.xl};
`;

const Schedule = (props: ScheduleProps) => {
  const [days, setDays] = useState<Days[]>(
    props.initialValue ? props.initialValue.days : []
  );
  const [hour, setHour] = useState<number>(
    props.initialValue ? props.initialValue.hour : 0
  );
  const [minute, setMinute] = useState<number>(
    props.initialValue ? props.initialValue.minute : 0
  );

  return (
    <ScheduleWrap>
      {props.index === 0 ? null : (
        <>
          <CardDivider margin="l 0" />
          <CloseIconWrapper>
            <CloseIcon
              colorPreset="text"
              width={20}
              height={20}
              onClick={() => {
                props.onRemove(props.id);
              }}
            />
          </CloseIconWrapper>
        </>
      )}

      <Text weight="semi" margin="0 0 s 0">
        Days
      </Text>
      <DayPicker
        initialValue={dayEnumToDays(days)}
        onChange={(days) => {
          setDays(daysToDaysEnum(days));
          props.onChange({
            id: props.id,
            days: daysToDaysEnum(days),
            hour,
            minute,
          });
        }}
      />
      <Text weight="semi" margin="l 0 s 0">
        Time
      </Text>
      <Flex align="center">
        <Select
          name="hour"
          value={`${hour < 10 ? "0" : ""}${hour.toString()}`}
          onSelect={(option) => {
            setHour(option.value.hour);
            props.onChange({
              id: props.id,
              days,
              hour: option.value.hour,
              minute,
            });
          }}
          options={getHours()}
        ></Select>
        <Text weight="bold" margin="0 xs">
          :
        </Text>
        <Select
          name="minute"
          value={minute.toString()}
          onSelect={(option) => {
            setMinute(option.value);
            props.onChange({
              id: props.id,
              days,
              hour,
              minute: option.value,
            });
          }}
          options={[
            { label: "00", value: 0 },
            { label: "15", value: 15 },
            { label: "30", value: 30 },
            { label: "45", value: 45 },
          ]}
        ></Select>
      </Flex>
    </ScheduleWrap>
  );
};

export function daysToDaysEnum(days: number[]): Days[] {
  const daysEnum: Days[] = [];
  for (const d of days) {
    switch (d) {
      case 0:
        daysEnum.push(Days.Sunday);
        break;
      case 1:
        daysEnum.push(Days.Monday);
        break;
      case 2:
        daysEnum.push(Days.Tuesday);
        break;
      case 3:
        daysEnum.push(Days.Wednesday);
        break;
      case 4:
        daysEnum.push(Days.Thursday);
        break;
      case 5:
        daysEnum.push(Days.Friday);
        break;
      case 6:
        daysEnum.push(Days.Saturday);
        break;
    }
  }

  return daysEnum;
}

export function dayEnumToDays(days: Days[]): number[] {
  const daysEnum: number[] = [];
  for (const d of days) {
    switch (d) {
      case Days.Sunday:
        daysEnum.push(0);
        break;
      case Days.Monday:
        daysEnum.push(1);
        break;
      case Days.Tuesday:
        daysEnum.push(2);
        break;
      case Days.Wednesday:
        daysEnum.push(3);
        break;
      case Days.Thursday:
        daysEnum.push(4);
        break;
      case Days.Friday:
        daysEnum.push(5);
        break;
      case Days.Saturday:
        daysEnum.push(6);
        break;
    }
  }

  return daysEnum;
}
