import * as React from "react";
import styled from "../../styles";
import theme from "../../styles/theme";

type colors = typeof theme.color.typography;

const ShareIconSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    height="15"
    viewBox="0 0 12 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M2.48047 14.0156C1.83203 14.0156 1.33789 13.8477 0.998047 13.5117C0.662109 13.1797 0.494141 12.6914 0.494141 12.0469V6.33984C0.494141 5.69531 0.662109 5.20703 0.998047 4.875C1.33789 4.53906 1.83203 4.37109 2.48047 4.37109H4.05078V5.67773H2.58594C2.32812 5.67773 2.13086 5.74414 1.99414 5.87695C1.86133 6.00586 1.79492 6.20508 1.79492 6.47461V11.9121C1.79492 12.1816 1.86133 12.3828 1.99414 12.5156C2.13086 12.6484 2.32812 12.7148 2.58594 12.7148H9.13086C9.38477 12.7148 9.58008 12.6484 9.7168 12.5156C9.85352 12.3828 9.92188 12.1816 9.92188 11.9121V6.47461C9.92188 6.20508 9.85352 6.00586 9.7168 5.87695C9.58008 5.74414 9.38477 5.67773 9.13086 5.67773H7.68945V4.37109H9.24219C9.89062 4.37109 10.3828 4.53906 10.7188 4.875C11.0586 5.21094 11.2285 5.69922 11.2285 6.33984V12.0469C11.2285 12.6875 11.0586 13.1758 10.7188 13.5117C10.3828 13.8477 9.89062 14.0156 9.24219 14.0156H2.48047ZM5.85547 9.28711C5.69141 9.28711 5.55078 9.22852 5.43359 9.11133C5.31641 8.99414 5.25781 8.85547 5.25781 8.69531V2.87109L5.31055 2.00977L4.9707 2.44922L4.19727 3.27539C4.0918 3.38867 3.95898 3.44531 3.79883 3.44531C3.6543 3.44531 3.5293 3.39844 3.42383 3.30469C3.32227 3.20703 3.27148 3.08398 3.27148 2.93555C3.27148 2.79883 3.32617 2.67383 3.43555 2.56055L5.39258 0.679688C5.47461 0.601563 5.55273 0.548828 5.62695 0.521484C5.70117 0.490234 5.77734 0.474609 5.85547 0.474609C5.9375 0.474609 6.01562 0.490234 6.08984 0.521484C6.16797 0.548828 6.24609 0.601563 6.32422 0.679688L8.28125 2.56055C8.39062 2.67383 8.44531 2.79883 8.44531 2.93555C8.44531 3.08398 8.39258 3.20703 8.28711 3.30469C8.18164 3.39844 8.05859 3.44531 7.91797 3.44531C7.76172 3.44531 7.63086 3.38867 7.52539 3.27539L6.74609 2.44922L6.41211 2.00977L6.45898 2.87109V8.69531C6.45898 8.85547 6.40039 8.99414 6.2832 9.11133C6.16992 9.22852 6.02734 9.28711 5.85547 9.28711Z" />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const ShareIcon = styled(ShareIconSVG)<Props>`
  path {
    fill: ${(p) =>
      p.colorPreset ? p.theme.color.typography[p.colorPreset] : "#000"};
  }
`;
