import { useEffect, useState } from "react";
import { Content, Footer } from ".";
import { UpgradePrompt } from "../../../components/Brand/UpgradePrompt";
import { Button, ButtonWrap } from "../../../components/CTA";
import { Callout } from "../../../components/Callout";
import { Card } from "../../../components/Card";
import { SingleCheckBox } from "../../../components/Checkbox";
import { Dropdown } from "../../../components/Dropdown";
import { Flex } from "../../../components/Flex";
import { H2 } from "../../../components/Heading";
import { Radio } from "../../../components/RadioButton";
import { Text } from "../../../components/Text";
import { View } from "../../../components/View";
import {
  ApprovalSettingsInput,
  ApprovalType,
  CreatorTier,
} from "../../../graphql/generated";
import styled, { css } from "../../../styles";

interface ApprovalProps {
  showUpgradePrompt: boolean;
  minFollowers?: number | null;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setApprovalSettings: React.Dispatch<
    React.SetStateAction<ApprovalSettingsInput>
  >;
  approvalSettings: ApprovalSettingsInput;
}

const engagementOptions = [
  { label: "1%", value: 1 },
  { label: "3% (good)", value: 3 },
  { label: "5% (high)", value: 5 },
];

const qualityOptions = [
  { label: "any", value: CreatorTier.CreatorTierStandard },
  { label: "higher", value: CreatorTier.CreatorTierPremium },
];

const audienceOptions = [
  { label: "10%", value: 10 },
  { label: "20%", value: 20 },
  {
    label: "25%",
    value: 25,
  },
  {
    label: "30%",
    value: 30,
  },
];

const defaultFollowerOptions = [
  2000, 5000, 8000, 10000, 20000, 30000, 40000, 50000,
];

export const ApprovalSettings = (props: ApprovalProps) => {
  const [enabledFollowers, setEnabledFollowers] = useState(
    !!props.approvalSettings.minFollowers
  );
  const [enabledEngagement, setEnabledEngagement] = useState(
    !!props.approvalSettings.minEngagementRate
  );
  const [enabledAudience, setEnabledAudience] = useState(
    !!props.approvalSettings.minUkAudience
  );
  const [enabledQuality, setEnabledQuality] = useState(
    !!props.approvalSettings.tier
  );

  const [followersCriteria, setFollowersCriteria] = useState(
    props.minFollowers
      ? props.minFollowers.toString()
      : defaultFollowerOptions[3].toString()
  );
  const [engagementCriteria, setEngagementCriteria] = useState(
    props.approvalSettings.minEngagementRate
      ? props.approvalSettings.minEngagementRate
      : engagementOptions[1].value
  );
  const [audienceCriteria, setAudienceCriteria] = useState(
    props.approvalSettings.minUkAudience
      ? props.approvalSettings.minUkAudience
      : audienceOptions[1].value
  );

  const [qualityCriteria, setQualityCriteria] = useState(
    props.approvalSettings.tier &&
      props.approvalSettings.tier === CreatorTier.CreatorTierPremium
      ? CreatorTier.CreatorTierPremium
      : CreatorTier.CreatorTierStandard
  );

  const followerOptions: Array<{ label: string; value: number }> = [];
  const minFollowers: number = props.minFollowers ?? 2000;

  for (const df of [minFollowers, ...defaultFollowerOptions]) {
    if (
      df >= minFollowers &&
      !followerOptions.some((option) => option.value === df)
    ) {
      followerOptions.push({ label: df.toLocaleString(), value: df });
    }
  }

  function resetCustomApprovalSettings() {
    setEnabledFollowers(false);
    setEnabledEngagement(false);
    setEnabledAudience(false);
    setEnabledQuality(false);
  }

  const { setApprovalSettings, approvalSettings } = props;

  useEffect(() => {
    const newSettings = {
      ...props.approvalSettings,
      minFollowers: enabledFollowers ? Number(followersCriteria) : undefined,
      minEngagementRate: enabledEngagement ? engagementCriteria : undefined,
      minUkAudience: enabledAudience ? audienceCriteria : undefined,
      tier: enabledQuality ? qualityCriteria : undefined,
    };

    if (
      JSON.stringify(newSettings) !== JSON.stringify(props.approvalSettings)
    ) {
      setApprovalSettings(newSettings);
    }
  }, [
    enabledFollowers,
    enabledEngagement,
    enabledAudience,
    enabledQuality,
    followersCriteria,
    engagementCriteria,
    audienceCriteria,
    qualityCriteria,
    approvalSettings,
    setApprovalSettings,
  ]);

  return (
    <Wrap>
      <Card margin="l 0 xl" style={{ overflow: "visible" }}>
        <Content>
          <H2 margin="0 0 s 0">Approval settings</H2>
          <Text colorPreset="secondary" margin="xs 0 l">
            Allow influencers to "instantly book" offers or require manual
            approval
          </Text>
          <PointerFlex
            direction="row"
            align="flex-start"
            margin="0 xs"
            onClick={() => {
              props.setApprovalSettings({
                type: ApprovalType.ApprovalTypeAuto,
              });
              resetCustomApprovalSettings();
            }}
          >
            <Radio
              active={
                props.approvalSettings.type === ApprovalType.ApprovalTypeAuto
              }
            />
            <View margin="0 0 0 l" style={{ flex: 1 }}>
              <Text margin="0">Auto approve all applications</Text>
            </View>
          </PointerFlex>
          <Flex
            justify="space-between"
            direction="row"
            margin="xl xs s 0"
            align="flex-start"
          >
            <PointerFlex
              showUpgradePrompt={props.showUpgradePrompt}
              direction="row"
              align="flex-start"
              margin="0 xs 0"
              onClick={() =>
                props.setApprovalSettings({
                  ...props.approvalSettings,
                  type: ApprovalType.ApprovalTypeAutoWithCriteria,
                })
              }
            >
              <Radio
                active={
                  props.approvalSettings.type ===
                  ApprovalType.ApprovalTypeAutoWithCriteria
                }
              />
              <View margin="0 0 0 l" style={{ flex: 1 }}>
                <Text margin="0">
                  Auto approve applications that meet specific criteria
                </Text>
                <Text margin="0" size="s">
                  (and manually approve the rest)
                </Text>
              </View>
            </PointerFlex>
            {props.showUpgradePrompt ? (
              <div style={{ marginTop: 4 }}>
                <UpgradePrompt feature="control" />
              </div>
            ) : null}
          </Flex>
          <ApprovalSettingsWrap
            disableItems={
              props.approvalSettings.type !==
                ApprovalType.ApprovalTypeAutoWithCriteria ||
              props.showUpgradePrompt
            }
            direction="column"
          >
            <PointerFlex
              direction="row"
              align="center"
              margin="s 0"
              onClick={() => {
                setEnabledFollowers(!enabledFollowers);
              }}
            >
              <SingleCheckBox checked={!!enabledFollowers} />
              <Text margin="0 m">More than </Text>
              <Dropdown
                minWidth={100}
                height={38}
                selectionMode="single"
                renderLabel={() => {
                  const selectedOption = followerOptions.find(
                    (option) => option.value === Number(followersCriteria)
                  );
                  return selectedOption?.label || followersCriteria.toString();
                }}
                selectedOptions={[followersCriteria.toString()]}
                setSelectedOptions={(value) => {
                  if (value.length > 0) {
                    setFollowersCriteria(value[0]);
                  }
                }}
                options={followerOptions
                  .sort((a, b) => a.value - b.value)
                  .map((option) => ({
                    label: option.label,
                    value: option.value.toString(),
                  }))}
              />
              <Text margin="0 m">followers</Text>
            </PointerFlex>
            <PointerFlex
              direction="row"
              align="center"
              margin="s 0"
              onClick={() => setEnabledEngagement(!enabledEngagement)}
            >
              <SingleCheckBox checked={!!enabledEngagement} />
              <Text margin="0 m">Engagement rate over</Text>
              <Dropdown
                selectionMode="single"
                renderLabel={() => {
                  const selectedOption = engagementOptions.find(
                    (option) => option.value === engagementCriteria
                  );
                  return selectedOption?.label || `${engagementCriteria}%`;
                }}
                minWidth={100}
                height={38}
                selectedOptions={
                  props.approvalSettings.minEngagementRate &&
                  followerOptions.some(
                    (o) => o.value === props.approvalSettings.minEngagementRate
                  )
                    ? [props.approvalSettings.minEngagementRate.toString()]
                    : [engagementOptions[0].value.toString()]
                }
                setSelectedOptions={(selectedOptions) => {
                  const selectedValue = Number(selectedOptions[0]);
                  setEngagementCriteria(selectedValue);
                }}
                options={engagementOptions.map((option) => ({
                  label: option.label,
                  value: option.value.toString(),
                }))}
              />
            </PointerFlex>
            <PointerFlex
              direction="row"
              align="center"
              margin="s 0"
              onClick={() => setEnabledAudience(!enabledAudience)}
            >
              <SingleCheckBox checked={!!enabledAudience} />
              <Text margin="0 m">Over</Text>
              <Dropdown
                selectionMode="single"
                minWidth={100}
                height={38}
                renderLabel={() => {
                  const audienceLabel =
                    audienceOptions.find(
                      (option) => option.value === audienceCriteria
                    )?.label || `${audienceCriteria}%`;
                  return audienceLabel;
                }}
                selectedOptions={
                  props.approvalSettings.minUkAudience &&
                  audienceOptions.some(
                    (o) => o.value === props.approvalSettings.minUkAudience
                  )
                    ? [props.approvalSettings.minUkAudience.toString()]
                    : [audienceOptions[0].value.toString()]
                }
                setSelectedOptions={(selectedOptions) => {
                  const selectedValue = Number(selectedOptions[0]);
                  setAudienceCriteria(selectedValue);
                }}
                options={audienceOptions.map((option) => ({
                  label: option.label,
                  value: option.value.toString(),
                }))}
              />
              <Text margin="0 m">of their audience are UK based</Text>
            </PointerFlex>
            <PointerFlex
              direction="row"
              align="center"
              margin="s 0 0"
              onClick={() => setEnabledQuality(!enabledQuality)}
            >
              <SingleCheckBox checked={!!enabledQuality} />
              <Text margin="0 m">Content must be of</Text>
              <Dropdown
                minWidth={100}
                height={38}
                selectionMode="single"
                selectedOptions={
                  props.approvalSettings.tier &&
                  qualityOptions.some(
                    (o) => o.value === props.approvalSettings.tier
                  )
                    ? [props.approvalSettings.tier.toString()]
                    : [qualityOptions[0].value.toString()]
                }
                renderLabel={() => {
                  const selectedOption = qualityOptions.find(
                    (option) => option.value === qualityCriteria
                  );
                  return selectedOption ? selectedOption.label : "Any";
                }}
                setSelectedOptions={(selectedOptions: string[]) => {
                  const selectedValue = selectedOptions[0] as CreatorTier;
                  setQualityCriteria(selectedValue);
                }}
                options={qualityOptions}
              />
              <Text margin="0 0 0 m">quality</Text>
            </PointerFlex>
          </ApprovalSettingsWrap>

          <Flex justify="space-between" direction="row" margin="xl xs xl 0">
            <PointerFlex
              showUpgradePrompt={props.showUpgradePrompt}
              direction="row"
              align="flex-start"
              margin="0 xs"
              onClick={() => {
                props.setApprovalSettings({
                  type: ApprovalType.ApprovalTypeManual,
                });
                resetCustomApprovalSettings();
              }}
            >
              <Radio
                active={
                  props.approvalSettings.type ===
                  ApprovalType.ApprovalTypeManual
                }
              />
              <View margin="0 0 0 l" style={{ flex: 1 }}>
                <Text margin="0">Manually approve all applications</Text>
              </View>
            </PointerFlex>
            {props.showUpgradePrompt ? (
              <div style={{ marginTop: 4 }}>
                <UpgradePrompt feature="control" />
              </div>
            ) : null}
          </Flex>
          <View padding="0 0 l">
            <Callout
              type="info"
              header="Auto approve offers get 3.2x more applications"
              text="For businesses with table reservations, please get in touch with us first about our booking platform integrations"
              emoji="rocket"
            />
          </View>
        </Content>
      </Card>
      <Footer>
        <ButtonWrap>
          <Button
            margin="0"
            buttonType="secondary"
            onClick={() => props.setCurrentStep(4)}
          >
            Back
          </Button>
          <Button margin="0" onClick={() => props.setCurrentStep(6)}>
            Review
          </Button>
        </ButtonWrap>
      </Footer>
    </Wrap>
  );
};

const PointerFlex = styled(Flex)<{ showUpgradePrompt?: boolean }>`
  cursor: pointer;
  user-select: none;

  ${(p) =>
    p.showUpgradePrompt
      ? css`
          pointer-events: none;
          opacity: 0.44;
        `
      : css`
          opacity: 1;
        `}
`;

const ApprovalSettingsWrap = styled(Flex)<{ disableItems: boolean }>`
  margin-left: 44px;
  ${(p) =>
    p.disableItems
      ? css`
          pointer-events: none;
          opacity: 0.44;
        `
      : css`
          opacity: 1;
        `}
`;

const Wrap = styled.div`
  justify-content: flex-start;
  flex: 1;
`;
