import styled, { css } from "../styles";

export const Radio = styled.div<{ active: boolean; isDisabled?: boolean }>`
  width: 14px;
  height: 14px;
  border-radius: 20px;
  border: 1.5px solid ${(p) => p.theme.color.typography.secondary};

  padding: 3px;

  opacity: ${(p) => (p.isDisabled ? 0.2 : 1)};

  ${(p) =>
    p.active
      ? css`
          background: ${(p) => p.theme.color.primary} content-box;
          border: 1.5px solid ${(p) => p.theme.color.primary};
        `
      : ``};
`;
