import { addDays, format, fromUnixTime, isAfter, startOfDay } from "date-fns";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Callout } from "../../../components/Callout";
import { CloseIcon } from "../../../components/CloseIcon";
import { CardDivider } from "../../../components/Divider";
import { Flex } from "../../../components/Flex";
import { H1, H3 } from "../../../components/Heading";
import Loading from "../../../components/Loading";
import { LocationImage } from "../../../components/LocationImage";
import { StyledMarkdown } from "../../../components/StyledMarkdown";
import { Text } from "../../../components/Text";
import { ExternalTextLink } from "../../../components/TextLink";
import { ToggleButton } from "../../../components/ToggleButton";
import { View } from "../../../components/View";
import { BoltIcon } from "../../../components/icons/BoltIcon";
import {
  Days,
  ListingType,
  ScheduleInput,
  useGetLocationQuery,
  usePreviewQuery,
} from "../../../graphql/generated";
import { dayFromIndex } from "../../../helpers/dates";
import useGqlClient from "../../../hooks/useGqlClient";
import { useTheme } from "../../../hooks/useTheme";
import { authSelectors } from "../../../store/auth/selector";
import { styled } from "../../../styles";
import withMargin, { WithMarginProp } from "../../../styles/withMargin";
import withPadding, { WithPaddingProp } from "../../../styles/withPadding";
import { TimeSlot, getTimeSlots } from "../../../utils/timeSlots";
import { dayEnumToDays } from "./RepeatEvent";

interface PreviewProps {
  setShowPreview: React.Dispatch<React.SetStateAction<boolean>>;
  isActive: boolean;
  listingTitle: string;
  listingDetails: string;
  type: ListingType | undefined;
  schedules: ScheduleInput[];
  timeSlot?: number;
  startDate?: number | null;
  endDate?: number | null;
  days: Days[];
  locations: string[];
  images: string[];
}

const SlideOverDetailsWrap = styled.div`
  padding: ${(p) => p.theme.spacing.m};
`;

export const Preview = (props: PreviewProps) => {
  const theme = useTheme();
  const activeBrandId = useSelector(authSelectors.activeBrandId);
  const client = useGqlClient();
  const previewQuery = usePreviewQuery(
    client,
    {
      brandID: activeBrandId ? activeBrandId : "",
    },
    {
      enabled: activeBrandId !== null,
    }
  );

  if (
    previewQuery.isLoading ||
    !previewQuery.data ||
    !previewQuery.data.brand
  ) {
    return <Loading />;
  }

  if (previewQuery.error) {
    return (
      <>
        <Text>Something went wrong</Text>
      </>
    );
  }
  const locationInstagramHandle =
    previewQuery.data.brand.locations.locations.filter(
      (l) => l.id === props.locations[0]
    )[0]?.instagramHandle ?? null;

  const locationTtHandle =
    previewQuery.data.brand.locations.locations.filter(
      (l) => l.id === props.locations[0]
    )[0]?.tikTokHandle ?? null;

  return (
    <>
      {props.images.length > 0 ? (
        <LocationImage height={260} imageUrl={props.images[0]} />
      ) : null}
      <View padding="m m s">
        <Callout
          type="info"
          text="This is a simplified preview of what an influencer will see on their app"
        />
      </View>

      <SlideOverDetailsWrap>
        <H1 margin="0 0 0 0">{props.listingTitle}</H1>
        <Text margin="0" colorPreset="secondary">
          {props.locations.length === 1
            ? previewQuery.data.brand.locations.locations.filter(
                (l) => l.id === props.locations[0]
              )[0].name
            : "Multiple locations"}
        </Text>

        <H3 margin="xxl 0 xs 0">Details</H3>
        <StyledMarkdown markdown={props.listingDetails} />

        <CardDivider margin="xl 0 0 0" />
        {previewQuery.data.brand.instagramHandle ? (
          <Flex direction="row" align="center" margin="s 0">
            <Text size="s" weight="semi" margin="0">
              Instagram
            </Text>
            <ExternalTextLink
              size="s"
              margin="0 0 0 l"
              href={`https://instagram.com/${previewQuery.data.brand.instagramHandle}`}
              target="_blank"
            >
              @{previewQuery.data.brand.instagramHandle}
            </ExternalTextLink>
          </Flex>
        ) : null}

        {locationInstagramHandle ? (
          <Flex direction="row" align="center" margin="s 0">
            <Text size="s" weight="semi" margin="0">
              Instagram
            </Text>
            <Text size="s" weight="bold" colorPreset="link" margin="0 0 0 l">
              @{locationInstagramHandle}
            </Text>
            <ExternalTextLink
              size="s"
              margin="0 0 0 l"
              href={`https://instagram.com/${locationInstagramHandle}`}
              target="_blank"
            >
              @{locationInstagramHandle}
            </ExternalTextLink>
          </Flex>
        ) : null}

        {previewQuery.data.brand.tikTokHandle ? (
          <Flex direction="row" align="center" margin="s 0">
            <Text size="s" weight="semi" margin="0">
              TikTok
            </Text>
            <ExternalTextLink
              size="s"
              margin="0 0 0 l"
              href={`https://tiktok.com/@${previewQuery.data.brand.tikTokHandle}`}
              target="_blank"
            >
              @{previewQuery.data.brand.tikTokHandle}
            </ExternalTextLink>
          </Flex>
        ) : null}

        {locationTtHandle ? (
          <Flex direction="row" align="center" margin="s 0">
            <Text size="s" weight="semi" margin="0">
              TikTok
            </Text>
            <ExternalTextLink
              size="s"
              margin="0 0 0 l"
              href={`https://tiktok.com/@${locationTtHandle}`}
              target="_blank"
            >
              @{locationTtHandle}
            </ExternalTextLink>
          </Flex>
        ) : null}

        <CardDivider />

        <H3 margin="xl 0 0 0">Availability</H3>
        {props.type === ListingType.RedeemAnytime ? (
          <AnytimeRedeem>
            <Text
              size="s"
              margin="0"
              isCompact
              color={theme.color.stats.positiveValue}
            >
              <Text
                size="m"
                margin="0 0 0"
                weight="bold"
                color={theme.color.stats.positiveValue}
              >
                <BoltIcon color={theme.color.stats.positiveValue} />
                <span style={{ marginLeft: 6, marginTop: -2 }}>
                  Redeem anytime
                </span>
              </Text>
              Can be used at any time within 30 days of approval
            </Text>
            <Text
              margin="xs 0 0 0"
              size="xs"
              isCompact
              style={{ opacity: 0.8 }}
              color={theme.color.stats.positiveValue}
            >
              Valid at{" "}
              {props.locations.length === 0
                ? previewQuery.data.brand.locations.locations.filter((l) =>
                    props.locations.includes(l.id)
                  )[0].name
                : "multiple locations"}
            </Text>
          </AnytimeRedeem>
        ) : (
          <>
            <Text margin="xs 0 0 0" colorPreset="secondary" size="s">
              Choose up to 5 time slots when you can visit and the restaurant
              will confirm one.
            </Text>
            <TimeSlots
              m="l 0 0 0"
              startDate={props.startDate}
              endDate={props.endDate}
              type={props.type ? props.type : ListingType.Ongoing}
              timeSlot={props.timeSlot}
              schedules={props.schedules}
              days={props.days}
              locations={props.locations}
            />
          </>
        )}
      </SlideOverDetailsWrap>
      <BackIcon
        onClick={() => {
          props.setShowPreview(false);
        }}
        margin="m m 0"
        padding="s"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          cursor: "pointer",
        }}
      >
        <CloseIcon colorPreset="text" />
      </BackIcon>
    </>
  );
};

interface Props {
  m?: string;
  startDate?: number | null;
  endDate?: number | null;
  type: ListingType;
  timeSlot?: number;
  schedules: ScheduleInput[];
  days: Days[];
  locations: string[];
}

function TimeSlots(props: Props) {
  const theme = useTheme();
  const [dates, setDates] = useState<Date[]>([]);
  const [activeDate, setActiveDate] = useState<Date | null>(null);

  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);

  const client = useGqlClient();
  const brandLocationQuery = useGetLocationQuery(client, {
    id: props.locations[0],
  });

  useEffect(() => {
    if (brandLocationQuery.isLoading || !brandLocationQuery.data) {
      return;
    }

    let startDate = new Date();
    if (props.startDate && isAfter(fromUnixTime(props.startDate), startDate)) {
      startDate = fromUnixTime(props.startDate);
    }

    if (props.type === ListingType.Event) {
      if (!props.timeSlot) {
        return;
      }

      const d = fromUnixTime(props.timeSlot);
      setDates([d]);
      setActiveDate(d);
      setTimeSlots([
        {
          hour: d.getHours(),
          minute: d.getMinutes(),
        },
      ]);

      return;
    }

    if (props.type === ListingType.Scheduled) {
      if (props.schedules.length === 0 || !props.startDate || !props.endDate) {
        return;
      }

      const datesArr: Date[] = [];
      let i = 0;
      while (datesArr.length < 5) {
        const d = addDays(startDate, i);
        for (const sch of props.schedules) {
          if (dayEnumToDays(sch.days).includes(d.getDay())) {
            datesArr.push(d);
            i++;
            break;
          }
        }

        i++;
      }

      setDates(datesArr);
      setActiveDate(datesArr[0]);

      return;
    }

    const locationDays = brandLocationQuery.data.location.openingHours
      ? brandLocationQuery.data.location.openingHours.map((l) =>
          l.dayOfWeek.toUpperCase()
        )
      : [];

    const datesArr: Date[] = [];
    let i = 0;
    while (datesArr.length < 5) {
      const d = addDays(startDate, i);

      if (
        (d.getMonth() === 11 && d.getDate() > 18) ||
        (d.getMonth() === 0 && d.getDate() < 2)
      ) {
        i++;
        continue;
      }

      if (locationDays.includes(dayFromIndex(d.getDay()))) {
        datesArr.push(startOfDay(d));
      }
      i++;
    }

    setDates(datesArr);
    setActiveDate(datesArr[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type, brandLocationQuery.data, props.schedules, props.timeSlot]);

  useEffect(() => {
    if (
      !activeDate ||
      !brandLocationQuery.data ||
      props.type === ListingType.Event
    ) {
      return;
    }

    if (props.type === ListingType.Scheduled) {
      const timeSlots = props.schedules
        .filter((s) => dayEnumToDays(s.days).includes(activeDate.getDay()))
        .map((s) => {
          return {
            hour: s.startHour,
            minute: s.startMinute,
          };
        });

      setTimeSlots(timeSlots);
      return;
    }

    setTimeSlots(getTimeSlots(activeDate, brandLocationQuery.data.location));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDate, brandLocationQuery.data]);

  return (
    <View margin={props.m}>
      <>
        <DaysWrap>
          {dates.map((d) => {
            return (
              <DayWrap
                onClick={() => {
                  setActiveDate(d);
                }}
                key={d.getTime()}
              >
                <Dates
                  margin="0 0 0 0"
                  size="s"
                  weight="semi"
                  color={
                    activeDate === d
                      ? theme.color.primary
                      : theme.color.typography.secondary
                  }
                >
                  {format(d, "MMM")}
                </Dates>
                <DayNames
                  margin="0 0 0 0"
                  weight="semi"
                  color={
                    activeDate === d
                      ? theme.color.primary
                      : theme.color.typography.secondary
                  }
                >
                  {format(d, "do")}
                </DayNames>
                <Dates
                  margin="0 0 m 0"
                  size="s"
                  weight="semi"
                  color={
                    activeDate === d
                      ? theme.color.primary
                      : theme.color.typography.secondary
                  }
                >
                  {format(d, "eee")}
                </Dates>
              </DayWrap>
            );
          })}
        </DaysWrap>
      </>

      <TimeSlotWrapper>
        {timeSlots.map((t, i) => {
          if (!activeDate) {
            return null;
          }

          return (
            <TimeSlotButton key={i} activeDate={activeDate} timeSlot={t} />
          );
        })}
      </TimeSlotWrapper>
    </View>
  );
}

interface TimeSlotProps {
  timeSlot: TimeSlot;
  activeDate: Date;
}

function TimeSlotButton(props: TimeSlotProps) {
  const [time, setTime] = useState<Date>();

  useEffect(() => {
    const relativeTime = new Date(props.activeDate.getTime());
    relativeTime.setHours(props.timeSlot.hour, props.timeSlot.minute, 0);

    setTime(relativeTime);
  }, [props.activeDate, props.timeSlot]);

  if (!time) {
    return null;
  }

  return (
    <ToggleButton
      title={`${format(time, "HH:mm")}`}
      active={false}
      margin="0 0 s 0"
      onClick={() => {}}
    />
  );
}

const DaysWrap = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(5, 1fr);
`;

const DayWrap = styled.div`
  cursor: pointer;
`;

const DayNames = styled(Text)`
  text-transform: uppercase;
  text-align: center;
  line-height: 130%;
`;
const Dates = styled(Text)`
  /* text-transform: uppercase; */
  text-align: center;
  line-height: 130%;
  opacity: 0.75;
`;

const TimeSlotWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: ${(p) => p.theme.spacing.s};
`;

const BackIcon = styled.div<WithPaddingProp & WithMarginProp>`
  display: flex;
  border-radius: 999px;
  box-shadow: ${(p) => p.theme.shadow.imageFloatingIcon};
  justify-content: center;
  align-items: center;
  background: ${(p) => p.theme.color.card.background};
  border: 1px solid ${(p) => p.theme.color.card.divider};

  ${withMargin}
  ${withPadding}
`;

const AnytimeRedeem = styled.div`
  margin-top: ${(p) => p.theme.spacing.m};
  padding: ${(p) => p.theme.spacing.m};
  background-color: ${(p) => p.theme.color.stats.positiveBackground};
  border-radius: 6px;
  flex: 1;
`;
