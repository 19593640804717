import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Content,
  Footer,
  SecondaryButton,
  StyledForm,
  ToggleProductWrapper,
} from ".";
import { ButtonWrap, Submit } from "../../../components/CTA";
import { CancelIcon } from "../../../components/CancelIcon";
import { Card } from "../../../components/Card";
import { CardDivider } from "../../../components/Divider";
import { Flex } from "../../../components/Flex";
import { H2 } from "../../../components/Heading";
import { Input } from "../../../components/Input";
import Loader from "../../../components/Loader";
import { InlineAddBtn } from "../../../components/NewButton";
import { Text } from "../../../components/Text";
import { TextArea } from "../../../components/TextArea";
import { ToggleButton } from "../../../components/ToggleButton";
import {
  Listing,
  useBrandLocationsQuery,
  useDietaryPreferencesQuery,
  useToggleProductsQuery,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import { authSelectors } from "../../../store/auth/selector";
import { SelectToggleProduct, ToggleWrapper } from "../SelectToggleProduct";

interface OfferProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  listingName: Listing["name"];
  setListingName: React.Dispatch<React.SetStateAction<Listing["name"]>>;
  setListingDetails: React.Dispatch<React.SetStateAction<string>>;
  listingDetails: string;
  toggleProduct?: ToggleProductWrapper;
  setToggleProduct: React.Dispatch<
    React.SetStateAction<ToggleProductWrapper | undefined>
  >;
  dietaryPreferences: Listing["dietaryPreferences"];
  setDietaryPreferences: React.Dispatch<
    React.SetStateAction<Listing["dietaryPreferences"]>
  >;
}

export const Offer = (props: OfferProps) => {
  const exampleOffer =
    "We are offering 1 complimentary meal for 2 \n\nIn return, we’d like 1 x reel/tiktok and 3 stories all tagging @joli_app";
  const [offerLength, setOfferLength] = useState<number>(exampleOffer.length);
  const [showAddToggleVoucher, setShowAddToggleVoucher] = useState(false);
  const [nameError, setNameError] = useState("");
  const [offerError, setOfferError] = useState("");

  const brand = useSelector(authSelectors.activeBrand);
  const client = useGqlClient();

  const { data } = useBrandLocationsQuery(client, {
    brandId: brand ? brand.id : "",
  });

  const history = useHistory();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const fd = new FormData(e.currentTarget);

    const submitter =
      (e.nativeEvent as any).submitter ||
      e.currentTarget.querySelector('[type="submit"]:focus');
    const formEmitterName = submitter ? submitter.name : "";

    if (formEmitterName === "saveDraft") {
      history.goBack();
      return;
    }

    if (fd.get("name") === "" || fd.get("name") === null) {
      setNameError("A listing name is required");
      return;
    }

    if (fd.get("details") === "" || fd.get("details") === null) {
      setOfferError("An offer is required");
      return;
    }

    props.setListingName(fd.get("name") as string);
    props.setListingDetails(fd.get("details") as string);

    props.setCurrentStep(1);
  };

  return (
    <StyledForm onSubmit={onSubmit}>
      <Card margin="l 0 xl" padding="0 0 l">
        <Content>
          <H2 margin="0 0 l 0">Describe Offer</H2>
          <Input
            label="Listing name"
            name="name"
            onChange={(e) => {
              setNameError("");
              props.setListingName(e.currentTarget.value);
            }}
            defaultValue={props.listingName}
            margin="0 0 0 0"
            error={nameError}
            placeholder="eg. Valentine's Brunch"
          />
          <TextArea
            label="Describe your offer"
            margin="xl 0 l 0"
            name="details"
            rows={5}
            characterLimit={400}
            characterLength={offerLength}
            onChange={(e) => {
              setOfferError("");
              setOfferLength(e.currentTarget.value.length);
              props.setListingDetails(e.currentTarget.value);
            }}
            error={offerError}
            defaultValue={
              props.listingDetails ? props.listingDetails : exampleOffer
            }
          />
          <Text weight="semi" margin="0 0 0">
            Dietary options
          </Text>
          <Text size="s" colorPreset="secondary" margin="0 0 s">
            Select any dietary preferences you cater to
          </Text>
          <DietaryPreferences
            dietaryPreferences={props.dietaryPreferences}
            setDietaryPreferences={props.setDietaryPreferences}
          />
          {data && data.brand && data.brand.toggleIntegrationEnabled && (
            <>
              {showAddToggleVoucher || props.toggleProduct ? (
                <>
                  <CardDivider margin="xl 0 xl 0" />
                  <Flex margin="xl 0 0" justify="space-between">
                    <Text weight="semi" margin="0 0 0">
                      Toggle
                    </Text>
                    <div
                      onClick={() => {
                        setShowAddToggleVoucher(false);
                        props.setToggleProduct(undefined);
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <CancelIcon />
                    </div>
                  </Flex>

                  <Text
                    isCompact
                    size="s"
                    colorPreset="secondary"
                    margin="0 0 0 0"
                  >
                    Connect an existing voucher or create a new one via the{" "}
                    <a
                      style={{
                        textDecoration: "underline",
                        color: "inherit",
                      }}
                      href="https://dashboard.mytoggle.io/shop/product-list"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Toggle dashboard
                    </a>
                  </Text>
                  {props.toggleProduct ? (
                    <ExistingToggleProduct
                      productId={props.toggleProduct.id}
                      productValue={props.toggleProduct.value}
                      onClear={() => {
                        props.setToggleProduct(undefined);
                      }}
                    />
                  ) : (
                    <SelectToggleProduct
                      onProductSelected={(productId, value, pmId) => {
                        props.setToggleProduct({
                          id: productId,
                          value,
                        });
                      }}
                      onClear={() => {
                        setShowAddToggleVoucher(false);
                        props.setToggleProduct(undefined);
                      }}
                    />
                  )}
                </>
              ) : (
                <InlineAddBtn
                  label="Add Toggle voucher"
                  margin="l 0 0"
                  onClick={() => {
                    setShowAddToggleVoucher(true);
                  }}
                />
              )}
            </>
          )}
        </Content>
      </Card>
      <Footer>
        <ButtonWrap>
          <SecondaryButton
            margin="0"
            type="submit"
            name="saveDraft"
            value={!true ? "Saving..." : "Discard"}
          />
          <Submit
            margin="0"
            type="submit"
            name="publish"
            value={!true ? "Saving..." : "Next"}
          />
        </ButtonWrap>
      </Footer>
    </StyledForm>
  );
};

interface ExistingToggleProductProps {
  productId: number;
  productValue: number;
  onClear: () => void;
}

function ExistingToggleProduct(props: ExistingToggleProductProps) {
  const client = useGqlClient();
  const toggleQuery = useToggleProductsQuery(client);

  if (toggleQuery.isLoading) {
    return (
      <Flex margin="xl 0" align="center" justify="center">
        <Loader />
      </Flex>
    );
  }

  if (toggleQuery.error || !toggleQuery.data) {
    return <Text>Something went wrong</Text>;
  }

  const product = toggleQuery.data.toggleProducts.find(
    (tp) => tp.id === props.productId
  );
  if (!product) {
    return <Text>Something went wrong</Text>;
  }

  return (
    <ToggleWrapper padding="s m">
      <Flex align="center" justify="space-between">
        <Flex direction="column">
          <Text weight="bold" margin="0 0 s 0">
            {product.name}
          </Text>
          <Text margin="0">
            Voucher value:{" "}
            <span style={{ fontWeight: "bold" }}>
              £{props.productValue / 100}
            </span>
          </Text>
        </Flex>
        <CancelIcon
          onClick={() => {
            props.onClear();
          }}
        />
      </Flex>
    </ToggleWrapper>
  );
}

interface DietaryPreferencesProps {
  setDietaryPreferences: React.Dispatch<
    React.SetStateAction<Listing["dietaryPreferences"]>
  >;
  dietaryPreferences: Listing["dietaryPreferences"];
}

const DietaryPreferences = (props: DietaryPreferencesProps) => {
  const client = useGqlClient();

  const { data, isLoading, error } = useDietaryPreferencesQuery(client);

  if (isLoading || !data) {
    return (
      <Flex align="center" justify="center">
        <Loader />
      </Flex>
    );
  }

  if (error) {
    return <Text>Something went wrong</Text>;
  }

  const allPreferences = data.dietaryPreferences.sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  return (
    <Flex wrap="wrap" margin="0 0 0">
      {allPreferences.map((p) => {
        return (
          <ToggleButton
            key={p.id}
            title={p.label}
            active={
              props.dietaryPreferences &&
              props.dietaryPreferences.length > 0 &&
              props.dietaryPreferences.includes(p.id)
                ? true
                : false
            }
            margin="0 m s 0"
            onClick={() => {
              const preferences = props.dietaryPreferences
                ? props.dietaryPreferences
                : [];

              const updatedPreferences = preferences.includes(p.id)
                ? preferences.filter((e) => e !== p.id)
                : [...preferences, p.id];

              props.setDietaryPreferences(updatedPreferences);
            }}
          />
        );
      })}
    </Flex>
  );
};
