import {
  formatDistanceToNowStrict,
  fromUnixTime,
  getUnixTime,
  isBefore,
  startOfMonth,
  subDays,
} from "date-fns";
import { GraphQLClient } from "graphql-request";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Avatar } from "../../../components/Avatar";
import { BookingDetails } from "../../../components/Brand/BookingDetails";
import { CTA } from "../../../components/CTA";
import { CardLink } from "../../../components/CardLink";
import { Flex } from "../../../components/Flex";
import { H1, H2, H3 } from "../../../components/Heading";
import Loading from "../../../components/Loading";
import { Subtitle } from "../../../components/Subtitle";
import { Tab } from "../../../components/Tab";
import { Text } from "../../../components/Text";
import { ListingEmptyStateIcon } from "../../../components/icons/ListingEmptyStateIcon";
import {
  LARGE_DESKTOP_BREAKPOINT,
  MEDIUM_DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  XL_DESKTOP_BREAKPOINT,
} from "../../../config";
import {
  BookingStatus,
  BookingsSortBy,
  BrandBookingsDocument,
  BrandBookingsQuery,
  ListingStatus,
  useBrandListingsQuery,
  useBrandNotificationCountQuery,
  useGetBookingQuery,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import { authSelectors } from "../../../store/auth/selector";
import { actions } from "../../../store/auth/slice";
import { css, styled } from "../../../styles";
import { ArchivedBookings } from "./Archived";
import { CompletedBookings } from "./Completed";
import { UpcomingBookings } from "./Upcoming";

export enum BookingTabs {
  New = "new",
  Upcoming = "upcoming",
  InProgress = "in_progress",
  Archived = "archived",
  Completed = "completed",
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1600px;
  max-height: 92vh;
  height: 100%;
  padding: 0 ${(p) => p.theme.spacing.l};
  box-sizing: border-box;

  @media (min-width: ${LARGE_DESKTOP_BREAKPOINT}px) {
    padding: 0 ${(p) => p.theme.spacing.xl};
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0 ${(p) => p.theme.spacing.l} ${(p) => p.theme.spacing.xl};
    max-height: unset;
  }
`;

const Grid = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  min-height: 100%;
`;

const BookingsWrap = styled.div`
  background: ${(p) => p.theme.color.card.background};
  box-shadow: ${(p) => p.theme.shadow.card};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  box-sizing: border-box;
  overflow: hidden;
  height: 100%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    background: transparent;
    box-shadow: none;
    overflow: unset;
  }
`;

const FirstColumn = styled.div`
  overflow-y: scroll;
  width: 100%;
  overflow-x: hidden;
  max-width: 320px;
  border-right: 1px solid ${(p) => p.theme.color.card.divider};
  background-color: ${(p) => p.theme.color.card.callout};

  @media (min-width: ${MEDIUM_DESKTOP_BREAKPOINT}px) {
    max-width: 360px;
  }

  @media (min-width: ${XL_DESKTOP_BREAKPOINT}px) {
    max-width: 400px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    min-width: 0px;
    max-width: 100%;
    background: transparent;
    overflow: visible;
    border-width: 0px;
  }

  ${(p) => p.theme.color.primary}
`;

const SecondColumn = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const StyledCardLink = styled(CardLink)<{ isActive: boolean }>`
  border-left: 3px solid
    ${(p) => (p.isActive ? p.theme.color.primary : "transparent")};
  padding: ${(p) => p.theme.spacing.xs} 0;
  background-color: ${(p) => p.theme.color.card.background};
  transition: border-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;

  ${(p) =>
    p.isActive
      ? css`
          background-color: ${(p) => p.theme.color.primary}09;
        `
      : css``}

  &:hover {
    background-color: ${(p) => p.theme.color.primary}09;
  }

  border-bottom: 1px solid ${(p) => p.theme.color.card.divider};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    border: none;
    background: ${(p) => p.theme.color.card.background};
    box-shadow: ${(p) => p.theme.shadow.card};
    border-radius: ${(p) => p.theme.misc.borderRadius};
    margin-bottom: ${(p) => p.theme.spacing.m};
  }
`;

const HorizontalScroll = styled.div`
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
  }
`;

const TabWrap = styled(Flex)`
  & > *:not(:first-child) {
    margin-left: ${(p) => p.theme.spacing.l};
  }

  & > *:last-child {
    margin-left: auto;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
`;

export interface MatchParams {
  page: BookingTabs;
  id: string | undefined;
}

function getTabFromBooking(status: BookingStatus, inProgress: boolean) {
  switch (status) {
    case BookingStatus.BookingStatusPending:
      return BookingTabs.New;
    case BookingStatus.BookingStatusCreatorRescheduled:
      return BookingTabs.New;
    case BookingStatus.BookingStatusApproved:
      if (inProgress) {
        return BookingTabs.InProgress;
      }
      return BookingTabs.Upcoming;
    case BookingStatus.BookingStatusCompleted:
      return BookingTabs.Completed;
    // archived
    default:
      return BookingTabs.Archived;
  }
}

function useBookingStatusToDeterminePage(
  client: GraphQLClient,
  bookId?: string
) {
  const { data, isLoading, isError } = useGetBookingQuery(
    client,
    { id: bookId ? bookId : "" },
    {
      enabled: !!bookId,
      retry: false,
    }
  );

  if (!data || isLoading || isError) {
    return { tab: null, isLoading, isError };
  }

  const tab = getTabFromBooking(data.booking.status, data.booking.inProgress);

  return { tab, isLoading, isError };
}

const getStartDate = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 1);
  return date;
};

const INITIAL_START_DATE = getStartDate();

const getStartOfMonth = () => {
  const date = new Date();
  return startOfMonth(date);
};

const START_OF_MONTH = getStartOfMonth();

export const BrandBookingsView = () => {
  const [page, setPage] = useState<BookingTabs>(BookingTabs.New);
  const activeBrandId = useSelector(authSelectors.activeBrandId);
  let idMatch = useRouteMatch<MatchParams>("/b/bookings/:page/:id");
  let pageMatch = useRouteMatch<MatchParams>("/b/bookings/:page");
  const [activeBookingId, setActiveBookingId] = useState<string | null>(null);
  const history = useHistory();
  const client = useGqlClient();
  const dispatch = useDispatch();
  const profile = useSelector(authSelectors.activeBrand);
  const { tab } = useBookingStatusToDeterminePage(client, idMatch?.params.id);

  const { data: notifcaitionCountData, refetch } =
    useBrandNotificationCountQuery(client, {
      filters: {
        startDate: getUnixTime(INITIAL_START_DATE),
      },
    });

  useEffect(() => {
    const q = new URLSearchParams(history.location.search);
    const newActiveBrandId = q.get("activeBrandId");

    if (newActiveBrandId && newActiveBrandId !== activeBrandId) {
      dispatch(actions.setActiveBrand({ brandId: newActiveBrandId }));
    }
  }, [history.location.search, dispatch, activeBrandId]);

  useEffect(() => {
    refetch();
  }, [activeBrandId, refetch]);

  useEffect(() => {
    if (pageMatch) {
      const newPage = pageMatch.params.page;
      if (page !== newPage) {
        setPage(newPage);
        setActiveBookingId(null);
      }
    }
  }, [pageMatch, page]);

  useEffect(() => {
    if (idMatch) {
      const newActiveBookingId = idMatch.params.id || null;
      // don't use the status to determine the tab for upcoming
      if (page === BookingTabs.Upcoming) {
        setActiveBookingId(newActiveBookingId);
        return;
      }
      const newTab = tab || page; // Fallback to current page if tab is not determined

      // Only update if there's a change in booking ID or tab
      if (activeBookingId !== newActiveBookingId || page !== newTab) {
        setActiveBookingId(newActiveBookingId);
        // Check if the URL actually needs updating
        const newPath = `/b/bookings/${newTab}/${newActiveBookingId}`;
        if (window.location.pathname !== newPath) {
          history.replace(newPath);
        }
      }
    }
  }, [idMatch, tab, activeBookingId, page, history]);

  useEffect(() => {
    const q = new URLSearchParams(history.location.search);
    const activeBrandId = q.get("activeBrandId");

    if (activeBrandId) {
      dispatch(actions.setActiveBrand({ brandId: activeBrandId }));
    }
  }, [history.location.search, dispatch]);

  if (!profile) {
    return <Loading defer />;
  }

  const pendingCount =
    notifcaitionCountData &&
    notifcaitionCountData.bookingsMetrics.totalPendingBookings
      ? notifcaitionCountData.bookingsMetrics.totalPendingBookings
      : 0;
  const rescheduleCount =
    notifcaitionCountData &&
    notifcaitionCountData.bookingsMetrics.totalCreatorRescheduledBookings
      ? notifcaitionCountData.bookingsMetrics.totalCreatorRescheduledBookings
      : 0;

  const newBadgeCount = pendingCount + rescheduleCount;

  const tabOnClick = (page: BookingTabs) => {
    history.push(`/b/bookings/${page}`);
  };

  return (
    <Wrap>
      <H1 margin="0 0 s">Bookings</H1>
      <HorizontalScroll>
        <TabWrap margin="0 0 s" justify="flex-start">
          <Tab
            textAlign="left"
            active={page === BookingTabs.New}
            label="New Applications"
            onClick={() => tabOnClick(BookingTabs.New)}
            badgeCount={
              newBadgeCount && newBadgeCount > 0 ? newBadgeCount : undefined
            }
            hideInactiveBorder
            textSize="m"
          />
          <Tab
            textAlign="left"
            active={page === BookingTabs.Upcoming}
            label="Upcoming"
            onClick={() => tabOnClick(BookingTabs.Upcoming)}
            hideInactiveBorder
            textSize="m"
          />
          <Tab
            textAlign="left"
            active={page === BookingTabs.InProgress}
            label="In Progress"
            onClick={() => tabOnClick(BookingTabs.InProgress)}
            hideInactiveBorder
            textSize="m"
          />
          <Tab
            textAlign="left"
            active={page === BookingTabs.Completed}
            label="Completed"
            onClick={() => tabOnClick(BookingTabs.Completed)}
            hideInactiveBorder
            textSize="m"
          />
          <Tab
            textAlign="left"
            active={page === BookingTabs.Archived}
            label="Archived"
            onClick={() => tabOnClick(BookingTabs.Archived)}
            hideInactiveBorder
            textSize="m"
          />
        </TabWrap>
      </HorizontalScroll>
      <RenderBookings
        page={page}
        client={client}
        activeBookingId={activeBookingId}
        setActiveBookingId={setActiveBookingId}
      />
    </Wrap>
  );
};

const RenderBookings = ({
  page,
  client,
  activeBookingId,
  setActiveBookingId,
}: {
  client: GraphQLClient;
  page: BookingTabs;
  activeBookingId: string | null;
  setActiveBookingId: (id: string | null) => void;
}) => {
  const observer = useRef<IntersectionObserver | null>(null);
  const brandId = useSelector(authSelectors.activeBrandId);
  const [statuses, setStatuses] = useState<BookingStatus[]>([
    BookingStatus.BookingStatusPending,
  ]);
  const [sortBy, setSortBy] = useState<BookingsSortBy>(
    BookingsSortBy.BookingsSortByCreatedAt
  );
  const [isInProgress, setIsInProgress] = useState<boolean | null>(null);
  const [count, setCount] = useState(10);
  const [startDate, setStartDate] = useState<number | null>(null);

  const serializedFilters = useMemo(
    () =>
      JSON.stringify({
        statuses,
        isAwaitingContent: isInProgress,
        count,
        sortBy,
        bookingStartDate: startDate,
      }),
    [statuses, isInProgress, count, sortBy, startDate]
  );

  const fetchBookings = useCallback(
    async ({ pageParam = "" }) => {
      return client.request<BrandBookingsQuery>(BrandBookingsDocument, {
        cursor: pageParam,
        statuses,
        isAwaitingContent: isInProgress,
        count,
        sortBy,
        bookingStartDate: startDate,
      });
    },
    [client, statuses, isInProgress, count, sortBy, startDate]
  );

  const { refetch, data, isLoading, isError, hasNextPage, fetchNextPage } =
    useInfiniteQuery(["BrandBookings", serializedFilters], fetchBookings, {
      getNextPageParam: (lastPage) => lastPage.bookings.cursor || undefined,
    });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandId, statuses, isInProgress, sortBy]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data.pages[0].bookings.bookings.length > 0) {
      setActiveBookingId(data.pages[0].bookings.bookings[0].id);
    }
  }, [page, data, setActiveBookingId]);

  useEffect(() => {
    switch (page) {
      case BookingTabs.New:
        setSortBy(BookingsSortBy.BookingsSortByCreatedAt);
        setIsInProgress(null);
        setStatuses([
          BookingStatus.BookingStatusPending,
          BookingStatus.BookingStatusCreatorRescheduled,
        ]);
        setCount(10);
        setStartDate(null);
        break;
      case BookingTabs.Upcoming:
        setSortBy(BookingsSortBy.BookingsSortByConfirmedTimeslot);
        setIsInProgress(null);
        setStatuses([
          BookingStatus.BookingStatusApproved,
          BookingStatus.BookingStatusCompleted,
        ]);
        // new date start of this month
        setStartDate(getUnixTime(START_OF_MONTH));
        setCount(100);
        break;
      case BookingTabs.InProgress:
        setSortBy(BookingsSortBy.BookingsSortByUpdatedAt);
        setIsInProgress(true);
        setStatuses([BookingStatus.BookingStatusApproved]);
        setCount(10);
        setStartDate(null);
        break;
      case BookingTabs.Completed:
        setSortBy(BookingsSortBy.BookingsSortByUpdatedAt);
        setIsInProgress(null);
        setStatuses([BookingStatus.BookingStatusCompleted]);
        setCount(10);
        setStartDate(null);
        break;
      // archived
      default:
        setSortBy(BookingsSortBy.BookingsSortByUpdatedAt);
        setIsInProgress(null);
        setStatuses([
          BookingStatus.BookingStatusCancelled,
          BookingStatus.BookingStatusExpired,
          BookingStatus.BookingStatusRejected,
        ]);
        setStartDate(null);
        setCount(10);
    }
    refetch();
  }, [page, refetch]);

  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasNextPage, fetchNextPage]
  );

  const bookings = data?.pages.flatMap((page) => page.bookings.bookings) || [];

  if (isLoading) {
    return <Loading defer />;
  }

  if (isError) {
    return (
      <div>
        <H3>Oops</H3>
        <Text margin={"0 0 xl 0"}>
          Couldn't load your bookings, please try again later.
        </Text>
      </div>
    );
  }

  if (bookings.length === 0 && page !== BookingTabs.Upcoming) {
    return (
      <Flex margin="xl 0 0" justify="center" align="center" direction="column">
        <EmptyState page={page} />
      </Flex>
    );
  }

  if (page === BookingTabs.Completed) {
    return (
      <CompletedBookings bookings={bookings} lastElementRef={lastElementRef} />
    );
  }

  if (page === BookingTabs.Upcoming) {
    return <UpcomingBookings bookings={bookings} />;
  }

  if (page === BookingTabs.Archived) {
    return (
      <ArchivedBookings bookings={bookings} lastElementRef={lastElementRef} />
    );
  }

  return (
    <BookingsWrap>
      <Grid>
        <FirstColumn>
          <RenderBookingList
            bookings={bookings}
            bookingType={page}
            lastElementRef={lastElementRef}
            activeBookingId={activeBookingId}
          />
        </FirstColumn>
        <SecondColumn>
          <BookingDetails bookingId={activeBookingId} />
        </SecondColumn>
      </Grid>
    </BookingsWrap>
  );
};

interface EmptyStateProps {
  page: BookingTabs;
}

const EmptyStateWrap = styled.div`
  margin-top: 80px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 32px;
  }
`;

const EmptyState = ({ page }: EmptyStateProps) => {
  const activeBrand = useSelector(authSelectors.activeBrand);
  const client = useGqlClient();
  const { data, isLoading } = useBrandListingsQuery(
    client,
    { brandId: activeBrand?.id },
    {
      retry: false,
    }
  );

  const noListings =
    data &&
    data.listings.listings.filter(
      (l) => l.status === ListingStatus.StatusActive
    ).length === 0;

  if (isLoading) {
    return <Loading defer />;
  }

  if (noListings) {
    return (
      <EmptyStateWrap>
        <Flex
          margin="xxxl 0 0"
          justify="center"
          direction="column"
          align="center"
          style={{ textAlign: "center" }}
        >
          <ListingEmptyStateIcon />
          <H2 margin="xxl 0 m">No active listings</H2>
          <Subtitle margin="0">
            Create a live listing to start receiving applications
          </Subtitle>
          <div>
            <CTA margin="l 0 0" to={"/b/listings"}>
              Create a listing
            </CTA>
          </div>
        </Flex>
      </EmptyStateWrap>
    );
  }

  let header = "You don't currently  have any new applications";
  let subtitle =
    "We'll email you as soon as there's a new application to review";
  let buttonText = "Create listing";
  let buttonUrl = "/b/listings";
  switch (page) {
    case BookingTabs.New:
      header = "No new applications";
      subtitle =
        "We'll email you as soon as there's a new application to review";
      buttonText = "";
      buttonUrl = "";
      break;
    // case BookingTabs.Upcoming:
    //   header = "You don't currently have any new applications";
    //   subtitle =
    //     "We'll notify you as soon an influencer has applied for your listing.";
    //   buttonText = "Create listing";
    //   buttonUrl = "/b/listings";
    //   break;
    case BookingTabs.InProgress:
      header = "No bookings currently in progress";
      subtitle = "Check to see if there are any applications to review";
      buttonText = "Review new applications";
      buttonUrl = "/b/bookings/new";
      break;
    case BookingTabs.Completed:
      header = "No completed bookings";
      subtitle =
        "You can review and complete any in progress via the 'In Progress' tab";
      buttonText = "";
      buttonUrl = "/b/bookings/in_progress";
      break;
    case BookingTabs.Archived:
      header = "There are currently no archived bookings";
      subtitle =
        "You'll find all your archived bookings here as soon as that changes";
      buttonText = "";
      buttonUrl = "";
      break;
  }

  return (
    <EmptyStateWrap>
      <Flex
        margin="xxxl 0 0"
        justify="center"
        direction="column"
        align="center"
        style={{ textAlign: "center" }}
      >
        <ListingEmptyStateIcon />
        <H2 margin="xxl 0 m">{header}</H2>
        <Subtitle margin="0">{subtitle}</Subtitle>
        {buttonUrl && buttonText ? (
          <div>
            <CTA margin="l 0 0" to={buttonUrl}>
              {buttonText}
            </CTA>
          </div>
        ) : null}
      </Flex>
    </EmptyStateWrap>
  );
};

interface RenderBookingProps {
  bookings: BrandBookingsQuery["bookings"]["bookings"];
  showDate?: boolean;
  bookingType: BookingTabs;
  lastElementRef: (node: any) => void;
  activeBookingId?: string | null;
}

export const RenderBookingList = ({
  bookings,
  bookingType,
  lastElementRef,
  activeBookingId,
}: RenderBookingProps) => {
  if (bookings.length === 0) {
    return <Text align="center">No {bookingType.toLowerCase()} bookings</Text>;
  }

  if (bookingType === BookingTabs.New) {
    bookings.sort((a, b) => {
      // Case 1: a is BookingStatusCreatorRescheduled and has approvedAt
      if (
        a.status === BookingStatus.BookingStatusCreatorRescheduled &&
        a.approvedAt
      ) {
        if (
          b.status !== BookingStatus.BookingStatusCreatorRescheduled ||
          !b.approvedAt
        ) {
          return -1;
        }
      }

      // Case 2: b is BookingStatusCreatorRescheduled and has approvedAt
      if (
        b.status === BookingStatus.BookingStatusCreatorRescheduled &&
        b.approvedAt
      ) {
        if (
          a.status !== BookingStatus.BookingStatusCreatorRescheduled ||
          !a.approvedAt
        ) {
          return 1;
        }
      }

      // Case 3: Both a and b are either BookingStatusCreatorRescheduled with approvedAt or not
      if (
        a.status === BookingStatus.BookingStatusCreatorRescheduled &&
        a.approvedAt &&
        b.status === BookingStatus.BookingStatusCreatorRescheduled &&
        b.approvedAt
      ) {
        return 0;
      }

      // Case 4: Sort by expiresAt for all other cases
      if (a.expiresAt && b.expiresAt) {
        return a.expiresAt - b.expiresAt;
      }

      return 0;
    });
  }

  return (
    <div>
      {bookings.map((b, index) => (
        <div
          ref={index === bookings.length - 3 ? lastElementRef : null}
          key={b.id}
        >
          <BookingCardLink
            isActive={
              activeBookingId && activeBookingId === b.id ? true : false
            }
            bookingType={bookingType}
            booking={b}
          />
        </div>
      ))}
    </div>
  );
};

const BookingCardLink = ({
  booking,
  bookingType,
  isActive,
}: {
  booking: BrandBookingsQuery["bookings"]["bookings"][0];
  bookingType: BookingTabs;
  isActive: boolean;
}) => {
  if (!booking.creator.preferredProfile) {
    return null;
  }

  const tab = getTabFromBooking(booking.status, booking.inProgress);

  return (
    <StyledCardLink
      isActive={isActive}
      to={`/b/bookings/${tab}/${booking.id}`}
      key={booking.id}
    >
      <Flex direction="row" justify="space-between" align="center" margin="m">
        <Flex>
          <Avatar
            withShadow={false}
            borderSize={0}
            size={46}
            url={booking.creator.preferredProfile.avatar}
          />
          <Flex direction="column" margin="0 0 0 s">
            <CardName
              colorPreset="heading"
              weight="bold"
              isCompact
              truncate
              margin="0"
            >
              {booking.creator.preferredProfile.name.length > 18
                ? booking.creator.preferredProfile.name.slice(0, 18) + "..."
                : booking.creator.preferredProfile.name}
            </CardName>
            <CardUsername colorPreset="secondary" margin="0" truncate>
              @
              {booking.creator.preferredProfile.username.length > 18
                ? booking.creator.preferredProfile.username.slice(0, 18) + "..."
                : booking.creator.preferredProfile.username}
            </CardUsername>
          </Flex>
        </Flex>
        <Flex
          align="center"
          justify="center"
          margin="0 0 0 0"
          style={{ flexShrink: 0 }}
        >
          {generateStatusText(booking, bookingType)}
        </Flex>
      </Flex>
    </StyledCardLink>
  );
};

const CardName = styled(Text)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: ${(p) => p.theme.typography.size.s};
  @media (min-width: ${XL_DESKTOP_BREAKPOINT}px) {
    font-size: ${(p) => p.theme.typography.size.m};
  }
`;

const CardUsername = styled(Text)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: ${(p) => p.theme.typography.size.s};
  @media (min-width: ${XL_DESKTOP_BREAKPOINT}px) {
    font-size: ${(p) => p.theme.typography.size.m};
  }
`;

function generateStatusText(
  booking: BrandBookingsQuery["bookings"]["bookings"][0],
  bookingType: BookingTabs
) {
  if (bookingType === BookingTabs.Archived) {
    return (
      <Text margin="xxs 0 0" size="xxxs" colorPreset="secondary">
        {booking.status === BookingStatus.BookingStatusCancelled
          ? "Cancelled "
          : booking.status === BookingStatus.BookingStatusCompleted
          ? "Completed "
          : booking.status === BookingStatus.BookingStatusExpired
          ? "Expired "
          : "Declined "}
        {formatDistanceToNowStrict(fromUnixTime(booking.updatedAt), {
          addSuffix: true,
        })}
      </Text>
    );
  }

  if (bookingType === BookingTabs.New) {
    const expiresAt = fromUnixTime(booking.expiresAt);
    const isExpiringInThreeDays = isBefore(subDays(expiresAt, 3), new Date());

    if (
      booking.status === BookingStatus.BookingStatusCreatorRescheduled &&
      booking.approvedAt
    ) {
      return (
        <Flex direction="column" align="flex-end">
          <Text margin="xxs 0 0" size="xxxs" colorPreset="link">
            Reschedule request
          </Text>
          <Text
            margin="xxs 0 0"
            size="xxxs"
            colorPreset={isExpiringInThreeDays ? "warning" : "secondary"}
          >
            Expires{" "}
            {formatDistanceToNowStrict(expiresAt, {
              addSuffix: true,
            })}
          </Text>
        </Flex>
      );
    }

    return (
      <Text
        margin="xxs 0 0"
        size="xxxs"
        colorPreset={isExpiringInThreeDays ? "warning" : "secondary"}
      >
        Expires{" "}
        {formatDistanceToNowStrict(expiresAt, {
          addSuffix: true,
        })}
      </Text>
    );
  }

  return (
    <Text margin="xxs 0 0" size="xxxs" colorPreset="secondary">
      Updated{" "}
      {formatDistanceToNowStrict(fromUnixTime(booking.updatedAt), {
        addSuffix: true,
      })}
    </Text>
  );
}
