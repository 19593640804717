import { Content, Footer, SecondaryButton, StyledForm } from ".";
import { Callout } from "../../../components/Callout";
import { Card } from "../../../components/Card";
import { Button, ButtonWrap, Submit } from "../../../components/CTA";
import { CardDivider } from "../../../components/Divider";
import { Flex } from "../../../components/Flex";
import { H2, H3 } from "../../../components/Heading";
import { Text } from "../../../components/Text";
import { View } from "../../../components/View";
import { ListingType } from "../../../graphql/generated";
import styled from "../../../styles";

interface RedeemAnyTimeProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setListingType: React.Dispatch<React.SetStateAction<ListingType | undefined>>;
}

export const RedeemAnyTime = (props: RedeemAnyTimeProps) => {
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    props.setCurrentStep(4);
  };

  return (
    <StyledForm onSubmit={onSubmit}>
      <Card margin="l 0 xl">
        <Content>
          <Flex justify="space-between" align="center">
            <div>
              <H2 margin="0">Redeem anytime</H2>
              <Text margin="xs 0 0 0" colorPreset="secondary">
                Valid at any time without prior scheduling
              </Text>
            </div>
            <Button
              buttonType="quaternary"
              onClick={() => {
                props.setListingType(undefined);
              }}
            >
              Change
            </Button>
          </Flex>
        </Content>
        <CardDivider margin="s 0 0" />
        <Content>
          <H3 margin="xs 0 s">How it works</H3>
          <Ol>
            <li>
              Approved influencers have 30 days to activate the offer via their
              app
            </li>
            <li>Once activated, the offer is available for a 24-hour period</li>
            <li>
              The offer is automatically removed from their app once used or the
              24-hours have passed
            </li>
          </Ol>

          <View margin="0 0 l">
            <Callout
              type="info"
              text="This is best suited for businesses without table reservations"
            />
          </View>
        </Content>
      </Card>
      <Footer margin="xl 0 0 0">
        <ButtonWrap>
          <SecondaryButton
            margin="0"
            type="submit"
            name="saveDraft"
            onClick={() => props.setCurrentStep(2)}
            value={!true ? "Saving..." : "Back"}
          />
          <Submit
            margin="0"
            type="submit"
            name="publish"
            value={!true ? "Saving..." : "Next"}
          />
        </ButtonWrap>
      </Footer>
    </StyledForm>
  );
};

const Ol = styled.ol`
  margin-block-start: 0;
  padding-inline-start: ${(p) => p.theme.spacing.l};
  color: ${(p) => p.theme.color.typography.text};

  li::marker {
    font-weight: ${(p) => p.theme.typography.weight.bold};
  }

  li {
    margin-bottom: ${(p) => p.theme.spacing.s};
    color: ${(p) => p.theme.color.typography.text};
  }
`;
