import { Days } from "../graphql/generated";

export const dayShortName = (d: Days) => {
  switch (d) {
    case Days.Monday:
      return "Mon";
    case Days.Tuesday:
      return "Tues";
    case Days.Wednesday:
      return "Wed";
    case Days.Thursday:
      return "Thurs";
    case Days.Friday:
      return "Fri";
    case Days.Saturday:
      return "Sat";
    case Days.Sunday:
      return "Sun";
  }
};

export const dayIndex = (d: Days) => {
  switch (d) {
    case Days.Sunday:
      return 0;
    case Days.Monday:
      return 1;
    case Days.Tuesday:
      return 2;
    case Days.Wednesday:
      return 3;
    case Days.Thursday:
      return 4;
    case Days.Friday:
      return 5;
    case Days.Saturday:
      return 6;
  }
};

export const dayFromIndex = (i: number) => {
  switch (i) {
    case 0:
      return Days.Sunday;
    case 1:
      return Days.Monday;
    case 2:
      return Days.Tuesday;
    case 3:
      return Days.Wednesday;
    case 4:
      return Days.Thursday;
    case 5:
      return Days.Friday;
    case 6:
      return Days.Saturday;
    default:
      return Days.Sunday;
  }
};
