const core = {
  spacing: {
    xxs: "2px",
    xs: "4px",
    s: "8px",
    m: "12px",
    l: "16px",
    xl: "24px",
    xxl: "32px",
    xxxl: "40px",
  },
  typography: {
    bodyFont: "Hind",
    bodyFamily: "'Hind', sans-serif",
    headingFont: "Hind",
    headingFamily: "'Hind', sans-serif",
    weight: {
      bold: "600",
      semi: "500",
      normal: "400",
    },
    size: {
      xxxs: "11px",
      xxs: "12px",
      xs: "13px",
      s: "15px",
      m: "16px",
      l: "18px",
      xl: "21px",
      xxl: "24px",
      xxxl: "32px",
    },
  },
  misc: {
    borderRadiusSmall: "8px",
    borderRadius: "10px",
    borderRadiusLarge: "12px",
    focusRingShadow: "0 0 0 3px",
    blur: "4px",
  },
};

const sharedColours = {
  primary: "#E5437D",
  highlight: "#E5F5FD",
  constructive: "#059669",
  warning: "#F0722B",
  destructive: "#E11748",
  inactive: "#9F9F9F",
  additional: {
    lightBlue: "#6AB8FF",
    darkBlue: "#3C5998",
    darkPurple: "#533C98",
  },
  brandGradient:
    "linear-gradient(90deg, #FA3CAB 13%, #F94C94 24%, #F87C54 57%, #F79A2C 81%, #F7A61D 94%)",
  tags: {
    lightOrange: "#FCF3CC",
    darkOrange: "#E9A23B",
    lightGreen: "#D7FCE3",
    darkGreen: "#15532B",
    lightRed: "#FEDEDE",
    darkRed: "#701010",
    lightPurple: "#F1E5FF",
    darkPurple: "#4E1A7E",
    lightBlue: "#D6E7FE",
    darkBlue: "#132A84",
  },
};

const lightColors = {
  name: "light",
  color: {
    background: {
      start: "#FDF3F0",
      end: "#FDF3F7",
      behind: "#211930",
    },
    secondary: "#3D3C5A",
    secondaryLight: "#595880",
    tertiary: "#7485C7",
    typography: {
      heading: "#2B2839",
      secondaryHeading: "#312F44",
      text: "#3D3C5A",
      secondary: "#7D7976",
      success: "#4D9568",
      reward: "#693F00",
      warning: "#895C38",
      error: "#B43E24",
      link: "#CB3F6C", // TODO
    },
    stats: {
      positiveBackground: "#D1FAE5",
      positiveValue: "#10B981",
      negativeBackground: "#FEE2E2",
      negativeValue: "#EF4444",
      neutralValue: "#5F7AE9",
      neutralBackground: "#E9EDFF",
    },
    card: {
      background: "#FDFDFF",
      divider: "#E9E8EA",
      callout: "#F7F5F5",
    },
    input: {
      background: "#ffffff",
      hover: "#e5437d12",
    },
    button: {
      secondaryBorder: "#D0D5DD",
      secondaryText: "#233249",
    },
    nav: {
      border: "#BFCADC",
    },
    divider: "#ebebeb",
    ...sharedColours,
  },
  shadow: {
    // box-shadow: [horizontal offset] [vertical offset] [blur radius] [optional spread radius] [color];
    card: "rgba(61, 60, 90, 0.6) 0px 0px 1px 0px, rgba(255, 241, 248, 0.12) 0px 1px 2px 0px, rgba(255, 241, 248, 0.25) 0px 0px 12px 0px inset",
    cardHeavy:
      "0px 0px 12px rgba(255, 241, 248, 0.12),0px 4px 6px rgba(61, 60, 90, 0.10),0px 0px 1px rgba(61, 60, 90, 0.60), rgba(255, 241, 248, 0.25) 0px 0px 12px 0px inset",
    popover: "0px 4px 20px #DAD3D1",
    nav: "0px 8px 20px 0px rgba(0, 0, 0, 0.1)",
    filters: "0px 3px 10px rgba(0, 0, 0, 0.1)",
    imageFloatingIcon:
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);",
  },
};

const darkColors = {
  name: "dark",
  color: {
    background: {
      start: "#26161D",
      end: "#1A0F09",
      behind: "#0f080b",
    },
    secondary: "#595880",
    secondaryLight: "#8F9EE4",
    tertiary: "#7485C7",
    typography: {
      heading: "#FFFFFF",
      secondaryHeading: "#FBFDFF",
      text: "#dfe9f1",
      secondary: "#AEAEAE",
      success: "#6FD392",
      reward: "#ED8F03",
      warning: "#F0722B",
      error: "#E11748",
      link: "#CB3F6C",
    },
    stats: {
      positiveBackground: "#1DB54933",
      positiveValue: "#1DB549",
      negativeBackground: "#F43F5E26",
      negativeValue: "#E11D48",
      neutralValue: "#5F7AE9",
      neutralBackground: "#2C3558",
    },
    card: {
      background: "#3D2E35",
      divider: "#463A3F",
      callout: "#35262d",
    },
    input: {
      background: "#33415d",
      hover: "#e5437d2b",
    },
    button: {
      secondaryBorder: "#788AA5",
      secondaryText: "#91a4bf",
    },
    nav: {
      border: "#384A70",
    },
    divider: "#463A3F",
    ...sharedColours,
  },
  shadow: {
    // box-shadow: [horizontal offset] [vertical offset] [blur radius] [optional spread radius] [color];
    card: "0px 0px 1px rgba(255, 255, 255, 0.8),inset 0px 0px 12px rgba(112, 104, 108, 0.1)",
    cardHeavy:
      "2px 2px 10px rgba(0, 0, 0, 0.5),0px 4px 6px rgba(0, 0, 0, 0.20), 0px 0px 1px rgba(255, 255, 255, 1.0), rgba(112, 104, 108, 0.1) 0px 0px 12px inset",
    popover: "0px 4px 20px #261F22",
    nav: "0px 8px 20px 0px rgba(0, 0, 0, 0.1)",
    filters: "0px 3px 10px rgba(84, 114, 136, 0.15)",
    imageFloatingIcon:
      "0px 1px 3px rgba(255, 255, 255, 0.1), 0px 1px 2px rgba(255, 255, 255, 0.06);",
  },
};

const defaultTheme = {
  ...core,
  ...lightColors,
};

export const lightTheme = {
  ...core,
  ...lightColors,
};

export const darkTheme = {
  ...core,
  ...darkColors,
};

export default defaultTheme;
