import { useSelector } from "react-redux";
import { Content, Footer, SecondaryButton, StyledForm } from ".";
import { ButtonWrap, Submit } from "../../../components/CTA";
import { Card } from "../../../components/Card";
import { Checkbox } from "../../../components/Checkbox";
import { Flex } from "../../../components/Flex";
import { H2 } from "../../../components/Heading";
import Loading from "../../../components/Loading";
import { Text } from "../../../components/Text";
import { TextLink } from "../../../components/TextLink";
import { useBrandLocationsQuery } from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import { authSelectors } from "../../../store/auth/selector";

interface LocationsProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setLocations: React.Dispatch<React.SetStateAction<string[]>>;
  locations: string[];
  newListing: boolean;
}

export const Locations = (props: LocationsProps) => {
  const brand = useSelector(authSelectors.activeBrand);
  const client = useGqlClient();

  const { data, isLoading, error } = useBrandLocationsQuery(client, {
    brandId: brand ? brand.id : "",
  });

  if (isLoading || !data || !data.brand) {
    return <Loading />;
  }

  if (error) {
    return <Text>Something went wrong</Text>;
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const fd = new FormData(e.currentTarget);

    const selectedLocs: string[] = [];
    data &&
      data.brand &&
      data.brand.locations.locations.forEach((loc) => {
        if (fd.get(`locations[${loc.id}]`)) {
          selectedLocs.push(loc.id);
        }
      });

    if (selectedLocs.length === 0) {
      alert("Please select at least one location");
      return;
    }

    props.setLocations(selectedLocs);

    props.setCurrentStep(3);
  };

  return (
    <StyledForm onSubmit={onSubmit}>
      <Card margin="l 0 xl">
        <Content>
          <H2 margin="0 0 m 0">Locations</H2>
          {data.brand.locations.locations.length > 0 ? (
            <Checkbox
              label=""
              name="locations"
              type="number"
              margin="0 0 xl 0"
              options={data.brand.locations.locations.map((loc) => {
                return {
                  label: loc.name,
                  value: loc.id,
                  isChecked: props.newListing
                    ? true
                    : props.locations.includes(loc.id),
                };
              })}
            />
          ) : (
            <Flex direction="column" margin="0 0 xl 0">
              <Text weight="semi" margin="0 0 xs">
                Locations
              </Text>
              <Text margin="0" colorPreset="secondary">
                No locations - please{" "}
                <TextLink margin="0 0 0" to="/b/locations">
                  add a location
                </TextLink>{" "}
                to continue
              </Text>
            </Flex>
          )}
        </Content>
      </Card>
      <Footer>
        <ButtonWrap>
          <SecondaryButton
            margin="0"
            type="submit"
            name="saveDraft"
            onClick={() => props.setCurrentStep(1)}
            value={!true ? "Saving..." : "Back"}
          />
          <Submit
            margin="0"
            type="submit"
            name="publish"
            value={!true ? "Saving..." : "Next"}
          />
        </ButtonWrap>
      </Footer>
    </StyledForm>
  );
};
