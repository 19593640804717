import { useRef, useState } from "react";
import styled, { ThemeProp, css } from "../styles";
import { Card } from "./Card";
import { Flex } from "./Flex";
import QuestionMark from "./QuestionMark";
import { View } from "./View";

export const Tooltip = ({ children }: { children: React.ReactNode }) => {
  const [position, setPosition] = useState<"above" | "below">("below");
  const ref = useRef<HTMLDivElement>(null);

  const handleMouseEnter = () => {
    if (!ref.current) return;

    const rect = ref.current.getBoundingClientRect();
    const popoverHeight = 160;

    if (rect.top < popoverHeight) {
      setPosition("below");
    } else {
      setPosition("above");
    }
  };

  return (
    <>
      <Wrap
        ref={ref}
        onMouseEnter={handleMouseEnter}
        align="center"
        justify="center"
      >
        {<QuestionMark />}
        <PopoverContent
          className="popover"
          position={position}
          onClick={(e: any) => e.stopPropagation()}
        >
          <StyledCard position={position}>
            <View padding="m">{children}</View>
          </StyledCard>
        </PopoverContent>
      </Wrap>
    </>
  );
};

const Wrap = styled(Flex)`
  position: relative;
  cursor: pointer;
  top: -2px;

  &:hover {
    .popover {
      display: block;
    }
  }
`;

const PopoverContent = styled.div<{
  position: "above" | "below";
}>`
  display: none;
  position: absolute;
  width: 280px;
  z-index: 999;
  padding: 10px 0;

  cursor: auto;

  left: -132px;

  ${(p: any) =>
    p.position === "above"
      ? css`
          bottom: calc(100% - 4px);
        `
      : css`
          top: calc(100% - 10px);
        `}
`;

const StyledCard = styled(Card)<{ position: "above" | "below" }>`
  box-shadow: ${(p: ThemeProp) => p.theme.shadow.popover};
  user-select: none;

  &:after {
    ${(p: any) =>
      p.position === "below"
        ? css`
            content: "";
            width: 12px;
            height: 12px;
            background: inherit;
            z-index: 1;
            top: 6px; // half height minus card margin top
            left: calc(50% - 6px);
            display: block;
            position: absolute;
            transform: rotateZ(45deg);
          `
        : css`
            content: "";
            width: 12px;
            height: 12px;
            background: inherit;
            z-index: 1;
            bottom: 6px;
            left: calc(50% - 6px);
            display: block;
            position: absolute;
            transform: rotateZ(45deg);
          `}
  }
`;
