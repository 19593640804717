import { ListingStatus } from "../graphql/generated";
import { useTheme } from "../hooks/useTheme";
import { renderListingStatus } from "../utils/enums";
import { Text } from "./Text";

export const ListingStatusText = (props: {
  status: ListingStatus;
  margin?: string;
}) => {
  const theme = useTheme();
  switch (props.status) {
    case ListingStatus.StatusActive:
      return (
        <Text
          size="s"
          margin="0"
          weight="semi"
          color={theme.color.constructive}
          {...props}
        >
          {renderListingStatus(props.status)}
        </Text>
      );
    case ListingStatus.StatusExpired:
      return (
        <Text
          margin="0"
          size="s"
          weight="semi"
          color={theme.color.warning}
          {...props}
        >
          {renderListingStatus(props.status)}
        </Text>
      );
    case ListingStatus.StatusScheduled:
      return (
        <Text
          margin="0"
          size="s"
          weight="semi"
          color={theme.color.primary}
          {...props}
        >
          {renderListingStatus(props.status)}
        </Text>
      );
    case ListingStatus.StatusDraft:
      return (
        <Text
          margin="0"
          size="s"
          weight="semi"
          color={theme.color.secondary}
          {...props}
        >
          {renderListingStatus(props.status)}
        </Text>
      );
    default:
      return (
        <Text
          size="s"
          margin="0"
          weight="semi"
          color={theme.color.typography.secondary}
          {...props}
        >
          {renderListingStatus(props.status)}
        </Text>
      );
  }
};
