import { Disclosure } from "@headlessui/react";
import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../config";
import RocketEmoji from "../images/rocket-emoji.png";
import { css, keyframes } from "../styles";
import { Chevron } from "./Chevron";
import { Flex } from "./Flex";
import { H3 } from "./Heading";
import { View } from "./View";
import { CheckCircle } from "./icons/CheckCircle";

type CollapsableSectionProps = {
  title: string;
  children: React.ReactNode;
  tooltip?: React.ReactNode;
  defaultOpen: boolean;
  isCheckboxComplete?: boolean; // used for set up guide
  showCheckbox?: boolean; // used for set up guide
  emoji?: "rocket"; // used for set up guide
};

const CollapsableSectionWrap = styled(Disclosure)`
  background-color: red;
  overflow: visible;
`;

const scaleIn = keyframes`
    from {
      transform: scale(0.98);
    }
    to {
      transform: scale(1);
    }
  `;

const scaleOut = keyframes`
    from {
      transform: scale(1);
    }
    to {
      transform: scale(0.98);
    }
  `;

const fadeIn = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `;

const fadeOut = keyframes`
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  `;

const CollapsableSectionButton = styled(Disclosure.Button)<{ open: boolean }>`
  background-color: ${(p) => p.theme.color.card.callout};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  width: 100%;
  border: 0px solid ${(p) => p.theme.color.card.divider};
  cursor: pointer;

  ${(p) =>
    p.open
      ? css`
          border-radius: ${(p) => p.theme.misc.borderRadius}
            ${(p) => p.theme.misc.borderRadius} 0 0;
        `
      : css`
          border-radius: ${(p) => p.theme.misc.borderRadius};
          :hover {
            filter: brightness(0.99);
          }
        `}
`;

const CollapsableSectionPanel = styled(Disclosure.Panel)`
  background-color: ${(p) => p.theme.color.card.callout};
  border-radius: 0 0 ${(p) => p.theme.misc.borderRadius}
    ${(p) => p.theme.misc.borderRadius};
  width: 100%;
`;

const CollapsableContentWrap = styled.div<{ open: boolean }>`
  ${(p) =>
    p.open
      ? css`
          animation: ${scaleIn} 0.3s ease-out, ${fadeIn} 0.4s ease-out;
        `
      : css`
          animation: ${scaleOut} 0.3s ease-out, ${fadeOut} 0.4s ease-out;
        `}

  padding: 0 ${(p) => p.theme.spacing.xl} ${(p) => p.theme.spacing.l};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0 ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.s};
  }
`;

const CollapsableHeaderWrap = styled(Flex)<{ open: boolean }>`
  ${(p) =>
    p.open
      ? css`
          margin: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.l}
            ${(p) => p.theme.spacing.xs};

          @media (max-width: ${MOBILE_BREAKPOINT}px) {
            margin: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m}
              ${(p) => p.theme.spacing.xs};
          }
        `
      : css`
          margin: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.l};

          @media (max-width: ${MOBILE_BREAKPOINT}px) {
            margin: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
          }
        `}
`;

export function CollapsableSection(props: CollapsableSectionProps) {
  return (
    <CollapsableSectionWrap defaultOpen={props.defaultOpen}>
      {({ open }) => (
        <>
          <CollapsableSectionButton open={open}>
            <CollapsableHeaderWrap
              open={open}
              align="center"
              direction="row"
              justify="space-between"
            >
              <Flex align="center">
                {props.showCheckbox ? (
                  <View margin="0 s 0 0">
                    <CheckCircle
                      size="l"
                      checked={
                        props.isCheckboxComplete
                          ? props.isCheckboxComplete
                          : false
                      }
                    />
                  </View>
                ) : null}
                <H3
                  margin="s xs s 0"
                  style={{
                    textDecoration: props.isCheckboxComplete
                      ? "line-through"
                      : "none",
                  }}
                >
                  {props.title}
                </H3>
                {props.emoji === "rocket" ? (
                  <div style={{ marginBottom: -1, marginLeft: 3 }}>
                    <img alt="rocket icon" width={20} src={RocketEmoji} />
                  </div>
                ) : null}
                {props.tooltip}
              </Flex>

              <Chevron direction={open ? "up" : "down"} animateTransition />
            </CollapsableHeaderWrap>
          </CollapsableSectionButton>
          <CollapsableSectionPanel>
            <View>
              <CollapsableContentWrap open={open}>
                {props.children}
              </CollapsableContentWrap>
            </View>
          </CollapsableSectionPanel>
        </>
      )}
    </CollapsableSectionWrap>
  );
}
