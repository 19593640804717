import { Listbox } from "@headlessui/react";
import { useEffect, useState } from "react";
import { Content, Footer, SecondaryButton, StyledForm } from ".";
import { Button, ButtonWrap, Submit } from "../../../components/CTA";
import { Card } from "../../../components/Card";
import { Chevron } from "../../../components/Chevron";
import { Flex } from "../../../components/Flex";
import { H2 } from "../../../components/Heading";
import { Input } from "../../../components/Input";
import Loader from "../../../components/Loader";
import { Modal } from "../../../components/Modal";
import { Switch } from "../../../components/Switch";
import { Text } from "../../../components/Text";
import { FakeTextLink } from "../../../components/TextLink";
import { View } from "../../../components/View";
import { TickIcon } from "../../../components/icons/TickIcon";
import { Listing, useCategoriesQuery } from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import { useTheme } from "../../../hooks/useTheme";
import styled from "../../../styles";
import { DoubleInputGroup } from "./Availability";

interface RequirementsProps {
  newListing: boolean;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setMinFollowers: React.Dispatch<
    React.SetStateAction<Listing["minFollowers"]>
  >;
  setMinEngagementRate: React.Dispatch<
    React.SetStateAction<Listing["minEngagementRate"]>
  >;
  setMaxFollowers: React.Dispatch<
    React.SetStateAction<Listing["minFollowers"]>
  >;
  setPhoneRequired: React.Dispatch<
    React.SetStateAction<Listing["requiresPhone"]>
  >;
  minFollowers?: number | null;
  maxFollowers?: number | null;
  minEngagementRate?: number | null;
  phoneRequired: boolean;
  emailRequired: boolean;
  setEmailRequired: React.Dispatch<React.SetStateAction<boolean>>;
  categories: Listing["categories"];
  setCategories: React.Dispatch<React.SetStateAction<string[]>>;
}

export const Requirements = (props: RequirementsProps) => {
  const [showModal, setShowModal] = useState(false);
  const [dietaryError, setDietaryError] = useState<string | null>(null);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const fd = new FormData(e.currentTarget);

    if (props.categories.length === 0) {
      alert("Please select at least one category of influencer");
      return;
    }

    const fdMinFollowers = fd.get("minFollowers");

    props.setMinFollowers(
      fdMinFollowers ? parseInt(fdMinFollowers as string) : undefined
    );

    props.setMaxFollowers(
      fd.get("maxFollowers")
        ? parseInt(fd.get("maxFollowers") as string)
        : undefined
    );

    props.setMinEngagementRate(
      fd.get("minEngagement")
        ? parseFloat(fd.get("minEngagement") as string)
        : undefined
    );

    if (!fdMinFollowers) {
      setShowModal(true);
      return;
    }

    props.setCurrentStep(5);
  };

  return (
    <StyledForm onSubmit={onSubmit}>
      <Modal isOpen={showModal} maxWidth={500}>
        <Flex justify="center" align="center" direction="column">
          <Text size="m" margin={"0 0 l"}>
            Hey — just a heads up you haven't set a minimum number of followers.
            <br />
            <br />
            That's totally fine as we have our own vetting process but just
            checking 😊
          </Text>
          <FakeTextLink
            margin="0 0 l"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Set minimum followers
          </FakeTextLink>
          <Button
            onClick={() => {
              setShowModal(false);
              props.setCurrentStep(5);
            }}
          >
            Continue
          </Button>
        </Flex>
      </Modal>
      <Card margin="l 0 xl">
        <Content>
          <H2 margin="0 0 m 0">Requirements</H2>
          <Text weight="semi" margin="l 0 0">
            Followers
          </Text>
          <Text size="s" colorPreset="secondary" margin="0 0 0 0">
            Visible only to influencers who match this range of followers
          </Text>
          <DoubleInputGroup>
            <Input
              name="minFollowers"
              type="number"
              defaultValue={props.minFollowers ? props.minFollowers : undefined}
              placeholder="No min"
              margin="0 0 0 0"
            />
            <Input
              name="maxFollowers"
              type="number"
              defaultValue={props.maxFollowers ? props.maxFollowers : undefined}
              placeholder="No max"
              margin="0 0 0 0"
            />
          </DoubleInputGroup>
          {/* <Input
            label="Engagement rate (%)"
            help="Visible only to creators with this engagement rate or higher"
            name="minEngagement"
            type="number"
            numberStep="0.1"
            defaultValue={
              props.minEngagementRate ? props.minEngagementRate : undefined
            }
            placeholder="No min"
            margin="0 0 xl 0"
          /> */}
          <Text weight="semi" margin="0 0 0">
            Category
          </Text>
          <Text size="s" margin="0 0 m" colorPreset="secondary">
            Visible only to influencers within these selected categories
          </Text>
          <CreatorCategories
            newListing={props.newListing}
            setError={setDietaryError}
            error={dietaryError}
            categories={props.categories}
            setCategories={props.setCategories}
          />
          <Flex direction="row" align="center" margin="s 0 l 0">
            <View style={{ flex: 1 }}>
              <Text weight="semi" margin="0">
                Require email address
              </Text>
            </View>
            <Switch
              value={props.emailRequired}
              onChange={() => {
                const newStatus = !props.emailRequired;
                props.setEmailRequired(newStatus);
              }}
            />
          </Flex>
          <Flex direction="row" align="center" margin="0 0 l 0">
            <View style={{ flex: 1 }}>
              <Text weight="semi" margin="0">
                Require phone number
              </Text>
            </View>
            <Switch
              value={props.phoneRequired}
              onChange={() => {
                props.setPhoneRequired(!props.phoneRequired);
              }}
            />
          </Flex>
        </Content>
      </Card>
      <Footer>
        <ButtonWrap>
          <SecondaryButton
            margin="0"
            type="submit"
            name="saveDraft"
            onClick={() => props.setCurrentStep(3)}
            value={!true ? "Saving..." : "Back"}
          />
          <Submit
            margin="0"
            type="submit"
            name="publish"
            value={!true ? "Saving..." : "Next"}
          />
        </ButtonWrap>
      </Footer>
    </StyledForm>
  );
};

export const StyledListboxButton = styled(Listbox.Button)`
  position: relative;
  /* width: 100%; */
  min-width: 220px;
  background-color: ${(p) => p.theme.color.card.background};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  border: 1px solid ${(p) => p.theme.color.typography.secondary}50;
  border-radius: 5px;
  color: ${(p) => p.theme.color.typography.text};
  padding: ${(p) => p.theme.spacing.m};
  text-align: left;
  cursor: pointer;

  font-family: ${(p) => p.theme.typography.bodyFamily};
  color: ${(p) => p.theme.color.typography.text};

  font-style: normal;
  font-weight: ${(p) => p.theme.typography.weight.normal};
  font-size: ${(p) => p.theme.typography.size.m};

  &:hover {
    border-color: ${(p) => p.theme.color.typography.text};
    outline: none;
  }

  &:focus,
  &:active,
  &:focus-visible {
    border-color: ${(p) => p.theme.color.primary};
    box-shadow: 0px 0px 0px 2px ${(p) => p.theme.color.input.hover};
    outline: none;
  }
`;

export const StyledListboxOptions = styled(Listbox.Options)`
  background-color: ${(p) => p.theme.color.card.background};
  box-shadow: ${(p) => p.theme.shadow.cardHeavy};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  list-style: none;
  margin: ${(p) => p.theme.spacing.s} 0;
  padding: ${(p) => p.theme.spacing.xs} 0;
  position: absolute;
  overflow-y: scroll;
  z-index: 10;
  min-width: 220px;

  &:focus-visible {
    outline: ${(p) => p.theme.color.primary} auto 1px;
  }
`;

export const StyledListboxOption = styled(Listbox.Option)`
  margin: 0;
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.s};
  cursor: pointer;
  user-select: none;

  &:focus,
  &:active,
  &:hover {
    background-color: ${(p) => p.theme.color.input.hover};
  }

  &:focus-visible {
    outline: ${(p) => p.theme.color.primary} auto 1px;
  }
`;

interface CreatorCategoryProps {
  newListing: boolean;
  setCategories: React.Dispatch<React.SetStateAction<Listing["categories"]>>;
  categories: Listing["categories"];
  error: string | null;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
}

const CreatorCategories = (props: CreatorCategoryProps) => {
  const client = useGqlClient();
  const theme = useTheme();

  const { data, isLoading, error } = useCategoriesQuery(client);

  useEffect(() => {
    if (!data) {
      return;
    }
    if (props.newListing && props.categories.length === 0) {
      props.setCategories(data.categories.map((c) => c.id));
    }
  }, [data, props]);

  if (isLoading || !data) {
    return (
      <Flex align="center" justify="center">
        <Loader />
      </Flex>
    );
  }

  const handleSelectionChange = (selectedItems: string[]) => {
    props.setCategories(selectedItems);
  };

  if (error) {
    return <Text>Something went wrong</Text>;
  }

  return (
    <View margin="0 0 l">
      <Listbox
        value={props.categories}
        onChange={handleSelectionChange}
        multiple
      >
        {({ open }) => (
          <>
            <StyledListboxButton>
              <span>
                {props.categories.length === data.categories.length
                  ? "All categories selected"
                  : `${props.categories.length} categories selected`}
              </span>
              <span style={{ position: "absolute", right: 0 }}>
                <View margin="0 m 0 0" style={{ marginTop: 2 }}>
                  <Chevron
                    size="s"
                    style={{ marginLeft: "auto" }}
                    direction={open ? "up" : "down"}
                    animateTransition
                  />
                </View>
              </span>
            </StyledListboxButton>
            {open && (
              <StyledListboxOptions>
                {data.categories
                  .sort((a, b) => a.label.localeCompare(b.label))
                  .map((item) => (
                    <StyledListboxOption key={item.id} value={item.id}>
                      {({ selected }) => (
                        <Flex direction="row" margin="0 s 0 0">
                          <Flex
                            style={{ width: 20 }}
                            margin="0 s 0 0"
                            align="center"
                            justify="center"
                          >
                            {selected ? (
                              <TickIcon color={theme.color.primary} />
                            ) : null}
                          </Flex>
                          <Text margin="0">{item.label}</Text>
                        </Flex>
                      )}
                    </StyledListboxOption>
                  ))}
              </StyledListboxOptions>
            )}
          </>
        )}
      </Listbox>
    </View>
  );
};
