import * as React from "react";
import styled from "../../styles";
import theme from "../../styles/theme";

type colors = typeof theme.color.typography;

const InvitesIconSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.25 1.75V2.5M15.25 5.5V6.25M15.25 9.25V10M15.25 13V13.75M6.25 8.5H11.5M6.25 10.75H9.25M2.125 1C1.504 1 1 1.504 1 2.125V5.151C1.45675 5.41398 1.83612 5.79267 2.09992 6.24895C2.36371 6.70522 2.5026 7.22296 2.5026 7.75C2.5026 8.27704 2.36371 8.79478 2.09992 9.25105C1.83612 9.70733 1.45675 10.086 1 10.349V13.375C1 13.996 1.504 14.5 2.125 14.5H19.375C19.996 14.5 20.5 13.996 20.5 13.375V10.349C20.0433 10.086 19.6639 9.70733 19.4001 9.25105C19.1363 8.79478 18.9974 8.27704 18.9974 7.75C18.9974 7.22296 19.1363 6.70522 19.4001 6.24895C19.6639 5.79267 20.0433 5.41398 20.5 5.151V2.125C20.5 1.504 19.996 1 19.375 1H2.125Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const InvitesIcon = styled(InvitesIconSVG)<Props>`
  path {
    stroke: ${(p) =>
      p.colorPreset ? p.theme.color.typography[p.colorPreset] : "#fff"};
  }
`;
