import { format, fromUnixTime } from "date-fns";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useIntercom } from "react-use-intercom";
import { Content, Footer, SecondaryButton, StyledForm } from ".";
import { Button, ButtonWrap, Submit } from "../../../components/CTA";
import { Card } from "../../../components/Card";
import { CardDivider } from "../../../components/Divider";
import { Flex } from "../../../components/Flex";
import { H2, H3 } from "../../../components/Heading";
import Loading from "../../../components/Loading";
import { Modal } from "../../../components/Modal";
import { Text } from "../../../components/Text";
import { View } from "../../../components/View";
import { MOBILE_BREAKPOINT } from "../../../config";
import {
  ApprovalSettingsInput,
  ApprovalType,
  BillingStatus,
  CreatorTier,
  Listing,
  ListingStatus,
  ListingType,
  ScheduleInput,
  useBrandBillingQuery,
  useBrandLocationsQuery,
  useCategoriesQuery,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { authSelectors } from "../../../store/auth/selector";
import styled from "../../../styles";
import { Checklist, ChecklistWrap } from "../Billing";

interface ReviewProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  name: string;
  id?: string;
  details: string;
  minFollowers?: number | null;
  maxFollowers?: number | null;
  type: ListingType | undefined;
  emailRequired: boolean;
  phoneRequired: boolean;
  locations: string[];
  timeSlot: number | undefined;
  schedules: ScheduleInput[];
  days: Listing["days"];
  startDate?: number | null;
  endDate?: number | null;
  onSave: (status: ListingStatus, redirectURL?: string) => void;
  isSaving: boolean;
  images: string[];
  listingStatus: ListingStatus;
  setShowPreview: React.Dispatch<React.SetStateAction<boolean>>;
  showPreview: boolean;
  approvalSettings: ApprovalSettingsInput;
  dmnBookingType: string;
  categories: Listing["categories"];
  minEngagementRate?: number | null;
}

const ReviewHeader = styled(Flex)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: ${(p) => p.theme.spacing.m};

    & div:nth-child(2) {
      display: none;
    }
  }
`;

const InfoText = styled.div`
  padding: ${(p) => p.theme.spacing.s};
  border-radius: 2.5px;
  background-color: ${(p) => p.theme.color.secondary}15;
  border-left: 3px solid ${(p) => p.theme.color.secondary};
`;

export const StyledButtonWrap = styled(ButtonWrap)`
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
    grid-row-gap: ${(p) => p.theme.spacing.m};
  }
`;

const ImagesWrap = styled.div`
  margin-top: ${(p) => p.theme.spacing.s};
  overflow-x: scroll;
  // hide scrollbar
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

const Image = styled.img`
  border-radius: 4px;
  height: 50px;
  width: 66px;
  margin: 0 ${(p) => p.theme.spacing.s} 0 0;
`;

const TruncatedH2 = styled(H2)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Review = (props: ReviewProps) => {
  const brand = useSelector(authSelectors.activeBrand);

  const client = useGqlClient();
  const queryClient = useQueryClient();
  const windowSize = useWindowSize();
  const brandLocationsQuery = useBrandLocationsQuery(client, {
    brandId: brand ? brand.id : "",
  });
  const { showNewMessages } = useIntercom();

  const { data: brandBilling } = useBrandBillingQuery(client, {
    brandID: brand ? brand.id : "",
  });

  const [showUpgrade, setShowUpgrade] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const allCategoriesQuery = useCategoriesQuery(client);

  if (brandLocationsQuery.isLoading || allCategoriesQuery.isLoading) {
    return <Loading />;
  }

  if (
    brandLocationsQuery.error ||
    !brandLocationsQuery.data ||
    !brandLocationsQuery.data.brand ||
    allCategoriesQuery.error ||
    !allCategoriesQuery.data
  ) {
    return <Text>Something went wrong</Text>;
  }

  const selectedLocations = brandLocationsQuery.data.brand.locations.locations
    .filter((l) => {
      return props.locations.includes(l.id);
    })
    .map((l) => l.name);

  const selectedCategories = allCategoriesQuery.data.categories
    .filter((c) => {
      return props.categories.includes(c.id);
    })
    .map((c) => c.label);

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    queryClient.resetQueries(["BrandListings"], {
      exact: false,
    });
    const formSubmitName = (e.nativeEvent as any).submitter.name;

    if (
      brandBilling &&
      brandBilling.brand?.billingStatus === BillingStatus.BillingStatusCancelled
    ) {
      setShowCancelModal(true);
      return;
    }

    if (
      brandBilling &&
      brandBilling.brand &&
      !brandBilling.brand.stripeCustomerId &&
      formSubmitName !== "saveDraft"
    ) {
      setShowUpgrade(true);
      return;
    }

    props.onSave(
      formSubmitName === "saveDraft"
        ? ListingStatus.StatusDraft
        : ListingStatus.StatusActive
    );
  }

  if (props.isSaving) {
    return <Loading />;
  }

  return (
    <>
      <Modal isOpen={showCancelModal} maxWidth={620}>
        <H2 margin="0">Reactivate your subscription?</H2>
        <Text size="l" margin="s 0 xl">
          You'll need an active subscription to publish and go live. If you'd
          like to reactivate your subscription, please get in touch with the
          Joli team.
        </Text>
        <Text margin="0 0 s" weight="bold">
          With your subscription, you'll get:
        </Text>
        <ChecklistWrap margin="0 0 m">
          <Checklist header="Access vetted creators" />
          <Checklist header="Content gallery" />
          <Checklist header="Unlimited campaigns & events" />
          <Checklist header="Team management" />
          <Checklist header="Advanced reporting" />
          <Checklist header="Live chat & email support" />
        </ChecklistWrap>
        <StyledButtonWrap margin="xl 0 0">
          <Button
            buttonType="secondary"
            onClick={() => {
              setShowCancelModal(false);
            }}
          >
            Not now
          </Button>
          <Button
            onClick={() => {
              showNewMessages(
                "Hey Joli Team, can you help me reactivate my subscription?"
              );
            }}
          >
            Chat to us
          </Button>
        </StyledButtonWrap>
      </Modal>
      <Modal
        isOpen={showUpgrade}
        onClose={() => setShowUpgrade(!showUpgrade)}
        maxWidth={620}
        // setIsOpen={setShowUpgrade}
      >
        <Flex direction="row" justify="space-between" margin="0 0 0">
          <View>
            <H2 margin="0">Ready to go live?</H2>
            <Text size="l" margin="s 0 l">
              Joli's influencers are waiting to promote your brand to their
              followers. You'll need an active subscription to publish and go
              live.
            </Text>
          </View>
        </Flex>
        <Text margin="0 0 s" weight="bold">
          With your subscription, you'll get:
        </Text>
        <ChecklistWrap margin="0 0 m">
          <Checklist header="Access vetted creators" />
          <Checklist header="Content gallery" />
          <Checklist header="Unlimited campaigns & events" />
          <Checklist header="Team management" />
          <Checklist header="Advanced reporting" />
          <Checklist header="Live chat & email support" />
        </ChecklistWrap>
        <StyledButtonWrap margin="xl 0 0">
          <Button
            buttonType="secondary"
            onClick={() => {
              props.onSave(ListingStatus.StatusDraft);
              setShowUpgrade(false);
            }}
          >
            Not now
          </Button>
          <Button
            onClick={() => {
              props.onSave(ListingStatus.StatusDraft, "/b/billing");
              setShowUpgrade(false);
            }}
          >
            Manage subscription
          </Button>
        </StyledButtonWrap>
      </Modal>
      <StyledForm onSubmit={onSubmit}>
        <Card margin="l 0 xl">
          <Content>
            <ReviewHeader>
              <TruncatedH2 margin="0">{props.name}</TruncatedH2>
              <div style={{ whiteSpace: "nowrap" }}>
                <Button
                  buttonType="tertiary"
                  onClick={(e) => {
                    e.preventDefault();
                    props.setShowPreview(!props.showPreview);
                  }}
                >
                  {props.showPreview ? "Hide preview" : "Show preview"}
                </Button>
              </div>
            </ReviewHeader>

            <Flex margin="xl 0 0 0" align="center" justify="space-between">
              <H3 margin="0">Details</H3>
              <Button
                buttonType="quaternary"
                onClick={() => {
                  props.setCurrentStep(0);
                }}
              >
                Edit
              </Button>
            </Flex>
            <Text size="s" margin="xs xxl 0 0">
              {props.details}
            </Text>

            <CardDivider margin="m 0" />

            <Flex margin="l 0 0 0" align="center" justify="space-between">
              <H3 margin="0">Images</H3>
              <Button
                buttonType="quaternary"
                onClick={() => {
                  props.setCurrentStep(1);
                }}
              >
                Edit
              </Button>
            </Flex>

            <ImagesWrap>
              {props.images.map((i) => {
                return <Image src={i} />;
              })}
            </ImagesWrap>

            <CardDivider margin="m 0 m" />

            <Flex margin="l 0 0 0" align="center" justify="space-between">
              <H3 margin="0 0 xs">Locations</H3>
              <Button
                buttonType="quaternary"
                onClick={() => {
                  props.setCurrentStep(2);
                }}
              >
                Edit
              </Button>
            </Flex>
            <Text size="s" margin="0 xxl 0 0">
              {selectedLocations.length <= 3
                ? selectedLocations.join(", ")
                : `${selectedLocations.slice(0, 3).join(", ")} and ${
                    selectedLocations.length - 3
                  } more`}
            </Text>

            <CardDivider margin="m 0" />

            <Flex margin="l 0 0 0" align="center" justify="space-between">
              <H3 margin="0 0 xs">Availability</H3>
              <Button
                buttonType="quaternary"
                onClick={() => {
                  props.setCurrentStep(3);
                }}
              >
                Edit
              </Button>
            </Flex>
            {props.type === ListingType.Event && props.timeSlot ? (
              <>
                <Text size="s" margin="0">{`${format(
                  fromUnixTime(props.timeSlot!),
                  "EEEE do MMMM y"
                )} at ${format(
                  fromUnixTime(props.timeSlot!),
                  "hh:mmaaa"
                )}`}</Text>
              </>
            ) : null}
            {props.type === ListingType.Scheduled ? (
              <>
                {props.schedules.map((s) => {
                  return (
                    <View>
                      <Text size="s" margin="0 0 0 0" isInline>
                        {s.days
                          .map((d) => {
                            return d[0] + d.slice(1).toLowerCase() + "s";
                          })
                          .join(", ")}
                      </Text>
                      <Text size="s" margin="0 0 0 xs" isInline>
                        at {s.startHour}:{s.startMinute}
                        {s.startMinute < 10 ? "0" : ""}
                      </Text>
                    </View>
                  );
                })}

                {props.startDate ? (
                  <Text size="s" margin="0 0 0 0">
                    Starting{" "}
                    {format(fromUnixTime(props.startDate), "EEEE do MMMM y")}
                  </Text>
                ) : null}

                {props.endDate ? (
                  <Text size="s" margin="0">
                    {"Until "}
                    {format(fromUnixTime(props.endDate), "EEEE do MMMM y")}
                  </Text>
                ) : null}
              </>
            ) : null}
            {props.type === ListingType.Ongoing && (
              <>
                <Text margin="0 xxl 0 0" size="s">
                  {props.days
                    .map((d) => {
                      return d[0] + d.slice(1).toLowerCase() + "s";
                    })
                    .join(", ")}
                </Text>
                {props.startDate ? (
                  <Text size="s" margin="0 0 0 0">
                    Starting{" "}
                    {format(fromUnixTime(props.startDate), "EEEE do MMMM y")}
                  </Text>
                ) : null}
                {props.endDate ? (
                  <Text size="s" margin="0">
                    {"Until "}
                    {format(fromUnixTime(props.endDate), "EEEE do MMMM y")}
                  </Text>
                ) : null}

                <View margin="l 0 0">
                  <InfoText>
                    <Text margin="0" size="xs">
                      {props.dmnBookingType !== ""
                        ? `Live availability powered by Access Collins + any hours you've excluded`
                        : `Availability will be based on location opening hours and any hours you've excluded`}
                    </Text>
                  </InfoText>
                </View>
              </>
            )}
            {props.type === ListingType.RedeemAnytime ? (
              <>
                <Text size="s" margin="0">
                  Valid at anytime without prior scheduling
                </Text>
              </>
            ) : null}

            <CardDivider margin="l 0 m" />

            <Flex margin="l 0 0 0" align="center" justify="space-between">
              <H3 margin="0 0 xs">Requirements</H3>
              <Button
                buttonType="quaternary"
                onClick={() => {
                  props.setCurrentStep(4);
                }}
              >
                Edit
              </Button>
            </Flex>
            <Flex
              margin="0 0 0 0"
              direction="row"
              align="center"
              justify="space-between"
            >
              <Text size="s" margin="0">
                Minimum followers
              </Text>
              <Text size="s" margin="0 0 0 s">
                {props.minFollowers ? props.minFollowers.toLocaleString() : "-"}
              </Text>
            </Flex>
            <Flex
              margin="xxs 0 0 0"
              direction="row"
              align="center"
              justify="space-between"
            >
              <Text size="s" margin="0">
                Maximum followers
              </Text>
              <Text size="s" margin="0 0 0 s">
                {props.maxFollowers ? props.maxFollowers.toLocaleString() : "-"}
              </Text>
            </Flex>
            {/* <Flex
            margin="xxs 0 0 0"
            direction="row"
            align="center"
            justify="space-between"
          >
            <Text size="s" margin="0">
              Minimum engagement rate
            </Text>
            <Text size="s" margin="0 0 0 s">
              {props.minEngagementRate ? `${props.minEngagementRate}%` : "-"}
            </Text>
          </Flex> */}
            <Flex margin="xxs 0 0 0" direction="row" justify="space-between">
              <Text size="s" margin="0">
                Categories
              </Text>
              <Text size="s" margin="0 0 0 s" align="right">
                {selectedCategories.length <= 3
                  ? selectedCategories.join(", ")
                  : `${selectedCategories.slice(0, 3).join(", ")} and ${
                      selectedCategories.length - 3
                    } more`}
              </Text>
            </Flex>
            <Flex direction="row" align="center" justify="space-between">
              <Text size="s" margin="0">
                Phone required
              </Text>
              <Text size="s" margin="0">
                {props.phoneRequired ? `Yes` : `No`}
              </Text>
            </Flex>
            <Flex
              direction="row"
              align="center"
              justify="space-between"
              margin="0 0 l"
            >
              <Text size="s" margin="0">
                Email required
              </Text>
              <Text size="s" margin="0">
                {props.emailRequired ? `Yes` : `No`}
              </Text>
            </Flex>
            <CardDivider margin="l 0 m" />
            <Flex margin="l 0 0 0" align="center" justify="space-between">
              <H3 margin="0 0 xs">Approval settings</H3>
              <Button
                buttonType="quaternary"
                onClick={() => {
                  props.setCurrentStep(5);
                }}
              >
                Edit
              </Button>
            </Flex>
            <AutoApprovalSettings settings={props.approvalSettings} />
            {windowSize.width && windowSize.width < MOBILE_BREAKPOINT ? (
              <View style={{ whiteSpace: "nowrap" }} margin="0 0 l">
                <Button
                  buttonType="tertiary"
                  onClick={(e) => {
                    e.preventDefault();
                    props.setShowPreview(!props.showPreview);
                  }}
                >
                  {props.showPreview ? "Hide preview" : "Show preview"}
                </Button>
              </View>
            ) : null}
          </Content>
        </Card>
        <Footer margin="xl 0 0 0">
          <ButtonWrap>
            <SecondaryButton
              margin="0"
              type="submit"
              name="saveDraft"
              value={
                props.listingStatus === ListingStatus.StatusActive
                  ? "Unpublish"
                  : "Save Draft"
              }
            />
            <Submit
              margin="0"
              type="submit"
              name="publish"
              value={
                props.listingStatus === ListingStatus.StatusActive
                  ? "Save"
                  : "Publish"
              }
            />
          </ButtonWrap>
        </Footer>
      </StyledForm>
    </>
  );
};

interface AutoApprovalSettingsProps {
  settings: ApprovalSettingsInput;
}

function AutoApprovalSettings(props: AutoApprovalSettingsProps) {
  if (props.settings.type === ApprovalType.ApprovalTypeAuto) {
    return (
      <Text size="s" margin="0 0 l 0">
        Automatically approve all applications
      </Text>
    );
  }

  if (props.settings.type === ApprovalType.ApprovalTypeManual) {
    return (
      <Text size="s" margin="0 0 l 0">
        Manually approve all applications
      </Text>
    );
  }

  return (
    <>
      <Text size="s" margin="0 0 0">
        Auto approve applications that meet specific criteria:
      </Text>
      <Flex
        margin="0 0 0 0"
        direction="row"
        align="center"
        justify="space-between"
      >
        <Text size="s" margin="0">
          Min. followers
        </Text>
        <Text size="s" margin="0 0 0 s">
          {props.settings.minFollowers
            ? props.settings.minFollowers.toLocaleString()
            : "-"}
        </Text>
      </Flex>
      <Flex
        margin="0 0 0 0"
        direction="row"
        align="center"
        justify="space-between"
      >
        <Text size="s" margin="0">
          Min. engagement rate (%)
        </Text>
        <Text size="s" margin="0 0 0 s">
          {props.settings.minEngagementRate
            ? `${props.settings.minEngagementRate}%`
            : "-"}
        </Text>
      </Flex>
      <Flex
        margin="0 0 0 0"
        direction="row"
        align="center"
        justify="space-between"
      >
        <Text size="s" margin="0">
          Min. % of audience in the UK
        </Text>
        <Text size="s" margin="0 0 0 s">
          {props.settings.minUkAudience
            ? `${props.settings.minUkAudience}%`
            : "-"}
        </Text>
      </Flex>
      <Flex
        margin="0 0 l 0"
        direction="row"
        align="center"
        justify="space-between"
      >
        <Text size="s" margin="0">
          Content quality
        </Text>
        <Text size="s" margin="0 0 0 s">
          {props.settings.tier === CreatorTier.CreatorTierPremium
            ? "Higher"
            : "Any"}
        </Text>
      </Flex>
    </>
  );
}
