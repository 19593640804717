import { isToday } from "date-fns";
import { GetBookingQuery, GetLocationQuery } from "../graphql/generated";

export interface TimeSlot {
  hour: number;
  minute: number;
}

const weekdays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export function getTimeSlots(
  date: Date | null,
  location:
    | GetBookingQuery["booking"]["location"]
    | GetLocationQuery["location"]
): TimeSlot[] {
  if (!date || !location || location.openingHours.length === 0) {
    return [];
  }

  const today = new Date();
  const dayOfWeek = weekdays[date.getDay()].toLowerCase();

  const openingHours = location.openingHours.find(
    (ohs) => ohs.dayOfWeek.toLowerCase() === dayOfWeek
  );

  const excludedHours = location.excludedHours.filter(
    (ehs) =>
      ehs.dayOfWeek.toLowerCase() === dayOfWeek || ehs.dayOfWeek === "Everyday"
  );

  if (!openingHours) {
    return [];
  }

  const closeHour =
    openingHours.closeHour > openingHours.openHour
      ? openingHours.closeHour
      : 24;

  const timeSlots = [];
  for (let hour = openingHours.openHour; hour < closeHour; hour++) {
    if (isToday(date) && hour < today.getHours()) {
      continue;
    }

    if (hour > closeHour - 2) {
      continue;
    }

    let isExcludedHour = false;

    excludedHours.forEach((ehs) => {
      if (hour >= ehs.startHour && hour <= ehs.endHour) {
        isExcludedHour = true;
      }
    });

    if (isExcludedHour) {
      continue;
    }

    if (hour === openingHours.openHour) {
      timeSlots.push({
        hour,
        minute: openingHours.openMinute,
      });
      continue;
    }

    timeSlots.push({
      hour,
      minute: 0,
    });
  }

  return timeSlots;
}
