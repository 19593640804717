import { Card } from "../Card";
import { CardLink } from "../CardLink";
import { Text } from "../Text";
import { Flex } from "../Flex";
import styled from "../../styles";
import { useTheme } from "../../hooks/useTheme";
import { AddIcon } from "../AddIcon";

export interface ListingTemplateProps {
  name: string;
  description: string;
  offer: string;
  minFollowers?: number;
  maxFollowers?: number;
  signup?: boolean;
}

const StyledCard = styled(Card)`
  text-align: left;
`;

const CardContent = styled.div`
  flex: 1;
`;

const CardRight = styled.div`
  display: flex;
  align-items: center;
`;

export function ListingTemplate(props: ListingTemplateProps) {
  const theme = useTheme();
  return (
    <CardLink
      to={{
        pathname: props.signup
          ? "/signup/b/create-listing"
          : "/b/create-listing",
        state: { ...props },
      }}
    >
      <StyledCard hover padding="s l">
        <Flex>
          <CardContent>
            <Text
              weight="semi"
              margin="0"
              color={theme.color.typography.heading}
            >
              {props.name}
            </Text>
            <Text size="s" margin="0">
              {props.description}
            </Text>
          </CardContent>
          <CardRight>
            <AddIcon width={20} height={20} colorPreset="secondary" />
          </CardRight>
        </Flex>
      </StyledCard>
    </CardLink>
  );
}
