import styled, { css } from "../styles";

interface Props {
  color: "green" | "blue" | "purple" | "red" | "orange";
  text: string;
  size?: "s" | "l";
  weight?: "bold";
  shadow?: boolean;
  padding?: "s" | "l";
}

const NewWrap = styled.span`
  border-radius: 22px;
  background-color: ${(p) => p.theme.color.tags.lightGreen};
  padding: 0 ${(p) => p.theme.spacing.s};
  font-size: ${(p) => p.theme.typography.size.xs};
`;

const ValueText = styled.span<Props>`
  font-weight: ${(p) => p.theme.typography.weight.semi};
  font-size: ${(p) => p.theme.typography.size.xs};
  border-radius: 999px;
  line-height: 1.1em;
  background-color: ${(p) => p.theme.color.tags.lightGreen};

  ${(p) => {
    if (p.padding === "l") {
      return css`
        padding: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.s};
      `;
    } else {
      return css`
        padding: 0 ${(p) => p.theme.spacing.s};
      `;
    }
  }};

  ${(p) => {
    if (p.color === "green") {
      return css`
        color: ${p.theme.color.tags.darkGreen};
      `;
    } else if (p.color === "red") {
      return css`
        color: ${p.theme.color.tags.darkRed};
      `;
    } else if (p.color === "blue") {
      return css`
        color: ${p.theme.color.tags.darkBlue};
      `;
    } else if (p.color === "orange") {
      return css`
        color: ${p.theme.color.tags.darkOrange};
      `;
    } else {
      return css`
        color: ${p.theme.color.tags.darkPurple};
      `;
    }
  }}

  ${(p) => {
    if (p.size === "s") {
      return css`
        font-size: ${(p) => p.theme.typography.size.xs};
      `;
    }

    if (p.weight === "bold") {
      return css`
        /* font-size: ${(p) => p.theme.typography.size.s}; */
        font-weight: ${(p) => p.theme.typography.weight.bold};
      `;
    }
  }};

  ${(p) => {
    if (p.color === "green") {
      return css`
        background-color: ${p.theme.color.tags.lightGreen};
      `;
    } else if (p.color === "red") {
      return css`
        background-color: ${p.theme.color.tags.lightRed};
      `;
    } else if (p.color === "blue") {
      return css`
        background-color: ${p.theme.color.tags.lightBlue};
      `;
    } else if (p.color === "orange") {
      return css`
        background-color: ${p.theme.color.tags.lightOrange};
      `;
    } else {
      return css`
        background-color: ${p.theme.color.tags.lightPurple};
      `;
    }
  }}
`;

const NewText = styled.span`
  font-weight: ${(p) => p.theme.typography.weight.semi};
  font-size: ${(p) => p.theme.typography.size.xs};
  line-height: 0.8;
  color: ${(p) => p.theme.color.tags.darkGreen};
`;

export function NewTag() {
  return (
    <div>
      <NewWrap>
        <NewText>New</NewText>
      </NewWrap>
    </div>
  );
}

export function MostPopularTag() {
  return (
    <div>
      <NewWrap>
        <NewText>Most Popular</NewText>
      </NewWrap>
    </div>
  );
}

export function Tag(props: Props) {
  return <ValueText {...props}>{props.text}</ValueText>;
}
