import { formatDistanceToNowStrict, fromUnixTime } from "date-fns";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { ListingTemplate } from "../../components/Brand/ListingTemplate";
import { CTA } from "../../components/CTA";
import { Card } from "../../components/Card";
import { Chevron } from "../../components/Chevron";
import { Divider } from "../../components/Divider";
import { Flex } from "../../components/Flex";
import { H1, H2, H3, H4 } from "../../components/Heading";
import { ListingStatusText } from "../../components/ListingStatusText";
import Loading from "../../components/Loading";
import { Modal } from "../../components/Modal";
import { NewButton } from "../../components/NewButton";
import { Text } from "../../components/Text";
import { View } from "../../components/View";
import { MOBILE_BREAKPOINT } from "../../config";
import {
  BrandListingsQuery,
  ListingStatus,
  useBrandListingsQuery,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { authSelectors } from "../../store/auth/selector";
import { styled } from "../../styles";

const StyledCard = styled(Card)`
  position: relative;
  text-align: left;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 600px;
  text-align: left;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0 ${(p) => p.theme.spacing.l};
  }
`;

const TemplateWrap = styled(Wrap)`
  max-width: 320px;
  @media (max-width: 500px) {
    align-items: stretch;
  }
`;

const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
`;

export interface MatchParams {
  page: string;
}

export const BrandListings = () => {
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const [showListingModal, setShowListingModal] = useState(false);
  const brand = useSelector(authSelectors.activeBrand);

  const client = useGqlClient();
  const { refetch } = useBrandListingsQuery(
    client,
    { brandId: brand?.id },
    {
      retry: false,
    }
  );

  const history = useHistory();
  const q = new URLSearchParams(history.location.search);
  const r = q.get("r");

  useEffect(() => {
    if (r === "onboarding") {
      setShowOnboardingModal(true);
    } else if (r === "activeListing") {
      refetch().then((newData) => {
        if (
          newData &&
          newData.data &&
          newData.data.listings.listings.length === 1
        ) {
          setShowListingModal(true);
        }
      });
    }
  }, [r, refetch]);

  return (
    <Wrap>
      <Modal
        isOpen={showOnboardingModal}
        setIsOpen={setShowOnboardingModal}
        maxWidth={480}
      >
        <H2 margin={"s 0 0"}>Almost there 🎉</H2>
        <Text size="m" margin={"xl 0"}>
          The last step is to set up a listing — these are offers you run for
          influencers.
          <br />
          <br />
          Describe what you're offering, and what you'd like in return. Target
          influencers by category, dietary preferences, minimum followers, and
          more.
        </Text>
        <CTA to="#" onClick={() => setShowOnboardingModal(false)}>
          Got it
        </CTA>
      </Modal>
      <Modal
        isOpen={showListingModal}
        setIsOpen={setShowListingModal}
        maxWidth={480}
      >
        <H2 margin="xs 0 0">Nice work 💪</H2>
        <Text margin={"xl 0"}>
          You've created your first live listing. Matching influencers will now
          be able to apply.
          <br />
          <br />
          We'll let you know via email, so sit back, relax and watch the
          applications roll in.
        </Text>
        <CTA to="#" onClick={() => setShowListingModal(false)}>
          Got it
        </CTA>
      </Modal>
      <HeaderWrap>
        <div style={{ flex: 1 }}>
          <H1 margin="0 0">Listings</H1>
        </div>
        <div>
          <NewButton label="New listing" to="/b/create-listing" />
        </div>
      </HeaderWrap>
      <Divider margin="xl 0 xxl 0" />
      {brand && <Listings brandID={brand.id} />}
    </Wrap>
  );
};

const Listings = (props: { brandID: string }) => {
  const client = useGqlClient();
  const [showArchived, setShowArchived] = useState(false);
  const { isLoading, data, isError } = useBrandListingsQuery(
    client,
    { brandId: props.brandID },
    {
      retry: false,
    }
  );

  const brand = useSelector(authSelectors.activeBrand);
  const listingTemplates = [
    {
      name: "General",
      description: "A simple ongoing campaign",
      offer: `We are offering complimentary food and drink up to the value of £50. In return we are looking for 3 tagged instagram stories & a static feed post all tagging @${
        brand && brand.instagram ? brand.instagram.username : "joli_app"
      }`,
      minFollowers: 2000,
    },

    // TODO ask michael about other tempaltes
  ];

  if (isLoading) {
    return <Loading />;
  }

  if (isError || !data) {
    return (
      <Wrap>
        <H3>Oops</H3>

        <Text margin={"0 0 xl 0"}>
          Couldn't load your listings, please try again later.
        </Text>
      </Wrap>
    );
  }

  if (data.listings.listings.length === 0) {
    return (
      <Flex align="center" direction="column" justify="center">
        <Text align="center">
          <b>No listings</b> <br />
          Get started by creating a new listing
        </Text>
        <Text align="center" size="s" margin="xxxl 0 s 0">
          Or start from a template
        </Text>
        <TemplateWrap>
          {listingTemplates.map((l) => (
            <ListingTemplate
              name={l.name}
              description={l.description}
              offer={l.offer}
              minFollowers={l.minFollowers}
              key={l.name}
            />
          ))}
        </TemplateWrap>
      </Flex>
    );
  }

  const active = data.listings.listings
    .filter(
      (l) =>
        l.status === ListingStatus.StatusActive ||
        l.status === ListingStatus.StatusScheduled ||
        l.status === ListingStatus.StatusDraft
    )
    .sort((a, b) => {
      return a.status === b.status
        ? 0
        : a.status === ListingStatus.StatusActive
        ? -1
        : b.status === ListingStatus.StatusActive
        ? 1
        : a.status === ListingStatus.StatusScheduled
        ? -1
        : 1;
    });

  const archived = data.listings.listings
    .filter(
      (l) =>
        l.status === ListingStatus.StatusExpired ||
        l.status === ListingStatus.StatusUnknown
    )
    .sort((a, b) => {
      return b.endDate! - a.endDate!;
    });

  return (
    <View margin="0 0 xl">
      {active.map((l) => (
        <ListingItem listing={l} key={l.id} />
      ))}
      {archived.length > 0 ? (
        <div>
          <Flex justify="center" margin="xl 0 xl" align="center">
            {showArchived ? <Divider /> : null}
            <Text
              style={{ cursor: "pointer", width: "100%", whiteSpace: "nowrap" }}
              size="s"
              align="center"
              colorPreset="secondary"
              margin="0 l"
              onClick={() => setShowArchived(!showArchived)}
            >
              {showArchived ? "Hide expired listings" : "Show expired listings"}
            </Text>
            {showArchived ? <Divider /> : null}
          </Flex>

          {showArchived &&
            archived.map((l) => <ListingItem listing={l} key={l.id} />)}
        </div>
      ) : null}
    </View>
  );
};

const timingText = (l: BrandListingsQuery["listings"]["listings"][0]) => {
  switch (l.status) {
    case ListingStatus.StatusExpired:
      return l.endDate
        ? `Expired ${formatDistanceToNowStrict(
            new Date(fromUnixTime(l.endDate)),
            {
              addSuffix: true,
            }
          )}`
        : "Expired";
    case ListingStatus.StatusActive:
      if (!l.endDate) {
        return null;
      }
      return `Expires in ${formatDistanceToNowStrict(
        new Date(fromUnixTime(l.endDate))
      )}`;
    case ListingStatus.StatusScheduled:
      return `Starts in ${formatDistanceToNowStrict(
        new Date(fromUnixTime(l.startDate!))
      )}`;
    default:
      return null;
  }
};

const TruncatedH4 = styled(H4)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ListingItem = (props: {
  listing: BrandListingsQuery["listings"]["listings"][0];
}) => {
  const l = props.listing;

  return (
    <CardLink to={`/b/listings/${l.id}`} key={l.id}>
      <StyledCard hover padding="s l">
        <Flex>
          <CardContent>
            <TruncatedH4 margin="s 0 xs 0">{l.name}</TruncatedH4>
            <Text size="s" margin="0 0 m 0" colorPreset="secondary">
              {timingText(l)}
            </Text>
            <Flex margin="m 0 0">
              <View margin="0 l 0 0">
                <Text size="l" margin="0" weight="bold" isCompact>
                  {l.locations.length}
                </Text>
                <Text margin="0" isCompact size="s">
                  {l.locations.length === 1 ? "Location" : "Locations"}
                </Text>
              </View>
              <View margin="0 l 0 0">
                <Text size="l" margin="0" weight="bold" isCompact>
                  {l.applicationsCount}
                </Text>
                <Text margin="0" isCompact size="s">
                  Applications
                </Text>
              </View>
              <View>
                <Text size="l" margin="0" weight="bold" isCompact>
                  {l.bookingsCount}
                </Text>
                <Text margin="0" isCompact size="s">
                  Bookings
                </Text>
              </View>
            </Flex>
          </CardContent>
          <CardRight>
            <ListingStatusText status={l.status} />
            <Chevron width={12} height={12} />
            <View className="hideOnMobile" margin="s" />
          </CardRight>
        </Flex>
      </StyledCard>
    </CardLink>
  );
};

const CardLink = styled(Link)`
  text-decoration: none;
  display: block;
  margin-bottom: ${(p) => p.theme.spacing.l};
`;

const CardContent = styled.div`
  flex: 1;
`;

const CardRight = styled.div`
  display: flex;
  align-items: flex-end;

  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    justify-content: center;
    & .hideOnMobile {
      display: none;
    }
  }
`;
