import {
  endOfQuarter,
  endOfYear,
  format,
  getYear,
  startOfQuarter,
  startOfYear,
  subDays,
  subYears,
} from "date-fns";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MOBILE_BREAKPOINT } from "../config";
import { useTheme } from "../hooks/useTheme";
import styled from "../styles";
import withMargin from "../styles/withMargin";
import { Flex } from "./Flex";
import { BookingsIcon } from "./icons/BookingsIcon";

const DateInput = styled.input.attrs({ readOnly: true })<{ noDates: boolean }>`
  background-color: ${(p) => p.theme.color.card.background} !important;
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
  padding-left: ${(p) => p.theme.spacing.xxxl} !important;
  width: ${(p) => (p.noDates ? 60 : 170)}px;
  border: 1px solid ${(p) => p.theme.color.typography.secondary}50;
  border-radius: 5px;
  color: ${(p) => p.theme.color.typography.secondary};
  font-size: ${(p) => p.theme.typography.size.m};
  cursor: pointer;
  &::placeholder {
    color: ${(p) => p.theme.color.typography.secondary};
  }
  &:hover {
    border-color: ${(p) => p.theme.color.typography.text};
    outline: none;
  }
  &:focus,
  &:active {
    border-color: ${(p) => p.theme.color.primary};
    box-shadow: 0px 0px 0px 2px ${(p) => p.theme.color.input.hover};
    outline: none;
  }
`;

const DatePickerWrap = styled.div`
  background-color: ${(p) => p.theme.color.card.background};
  position: relative;
  border-radius: 5px;
  color: ${(p) => p.theme.color.typography.secondary};
  cursor: pointer;

  .react-datepicker {
    background-color: ${(p) => p.theme.color.card.background};
    color: ${(p) => p.theme.color.typography.secondary};
    border: 0px solid transparent;
    box-shadow: ${(p) => p.theme.shadow.cardHeavy};
    padding: ${(p) => p.theme.spacing.s};
  }

  .react-datepicker__header,
  .react-datepicker__current-month {
    background-color: ${(p) => p.theme.color.card.background};
    color: ${(p) => p.theme.color.typography.text};
  }

  .react-datepicker__current-month {
    padding-bottom: 6px;
  }

  .react-datepicker__header {
    border-bottom: 1px solid ${(p) => p.theme.color.card.divider};
  }

  .react-datepicker__day-name {
    color: ${(p) => p.theme.color.typography.text};
  }

  .react-datepicker__day,
  .react-datepicker__time-name {
    background-color: ${(p) => p.theme.color.card.background} !important;
    color: ${(p) => p.theme.color.typography.text};

    &:hover {
      background-color: ${(p) => p.theme.color.primary} !important;
      color: #fff;
    }
  }

  .react-datepicker__month {
    margin: 0 ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.s};
  }

  .react-datepicker__day--selecting-range-start,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__day--selecting-range-end {
    background-color: ${(p) => p.theme.color.primary} !important;
    color: #fff;
  }

  .react-datepicker__day--disabled {
    color: #ffffff60 !important;

    &:hover {
      opacity: 0;
    }
  }
`;

const Row = styled.div`
  padding: calc(${(p) => p.theme.spacing.s} + 2px) ${(p) => p.theme.spacing.m};
  color: ${(p) => p.theme.color.typography.secondary};
  margin: 0 0 ${(p) => p.theme.spacing.xs};
  border-radius: ${(p) => p.theme.misc.borderRadius};

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover,
  &.isActive {
    background-color: ${(p) => p.theme.color.input.hover};

    p {
      color: ${(p) => p.theme.color.primary};
    }

    svg {
      path {
        fill: ${(p) => p.theme.color.primary};
      }
    }
  }

  cursor: pointer;
  min-width: 180px;
`;

const CalWrap = styled.div`
  border-left: 1px solid ${(p) => p.theme.color.card.divider};
  padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.m};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

interface DateRangePickerProps {
  initialStartDate: Date | null;
  initialEndDate: Date | null;
  onChange?: (dates: [Date | null, Date | null]) => void;
  maxDate?: Date;
  dateFormat?: string;
  placeholderText?: string;
  className?: string;
  allTimeStartDate?: Date;
}

const formatDateRange = (start: Date, end: Date) => {
  const sameYear = getYear(start) === getYear(end);
  const yearFormat = `'${format(end, "yy")}`;
  const startFormat = format(
    start,
    `MMM d${!sameYear ? `, '${format(start, "yy")}` : ""}`
  );
  const endFormat = format(end, `MMM d${!sameYear ? `, ${yearFormat}` : ""}`);

  return `${startFormat} – ${endFormat}`;
};

const today = new Date();
const startOfCurrentQuarter = startOfQuarter(today);
const endOfLastQuarter = endOfQuarter(subDays(startOfCurrentQuarter, 1));
const startOfLastQuarter = startOfQuarter(subDays(startOfCurrentQuarter, 1));
const startOfCurrentYear = startOfYear(today);
const endOfLastYear = endOfYear(subYears(today, 1));
const startOfLastYear = startOfYear(subYears(today, 1));

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  initialStartDate,
  initialEndDate,
  onChange,
  maxDate = new Date(),
  dateFormat = "dd/MM/yyyy",
  placeholderText = "All time",
  className,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(
    initialStartDate || null
  );
  const [endDate, setEndDate] = useState<Date | null>(initialEndDate || null);
  const theme = useTheme();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [key, setKey] = useState(0); // Key for forcing re-render

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [newStartDate, newEndDate] = dates;
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    onChange?.(dates);
  };

  const MyContainer = ({ className, children }: any) => {
    const handlePreset = (start: Date, end: Date, clear: boolean) => {
      setStartDate(clear ? null : start);
      setEndDate(clear ? null : end);
      onChange?.([start, end]);

      setIsCalendarOpen(false);
      // force the re-render of the date picker to let us open it again
      setTimeout(() => {
        setKey((prev) => prev + 1);
      }, 10);
    };

    const presets = [
      {
        id: "last_30_days",
        text: "Last 30 Days",
        start: subDays(today, 30),
        end: today,
      },
      {
        id: "this_quarter",
        text: "This Quarter",
        start: startOfCurrentQuarter,
        end: today,
      },
      {
        id: "last_quarter",
        text: "Last Quarter",
        start: startOfLastQuarter,
        end: endOfLastQuarter,
      },
      {
        id: "this_year",
        text: "This Year",
        start: startOfCurrentYear,
        end: today,
      },
      {
        id: "last_year",
        text: "Last Year",
        start: startOfLastYear,
        end: endOfLastYear,
      },
      {
        id: "all_time",
        text: "All Time",
        start: new Date(0), // Unix epoch start date
        end: today,
      },
    ];

    return (
      <div style={{ userSelect: "none", width: "100%" }} className={className}>
        <Flex>
          <Flex margin="0" direction="column">
            {presets.map(({ text, start, end, id }) => {
              const isAllTime = id === "all_time";
              return (
                <Row
                  key={text}
                  style={{ marginRight: theme.spacing.s }}
                  onClick={() => handlePreset(start, end, isAllTime)}
                >
                  <Flex direction="row" align="center">
                    <Title className="cta">{text}</Title>
                    {!isAllTime ? (
                      <Subtitle margin="0 0 0 s">
                        {formatDateRange(start, end)}
                      </Subtitle>
                    ) : null}
                  </Flex>
                </Row>
              );
            })}
          </Flex>
          <CalWrap style={{ position: "relative" }}>{children}</CalWrap>
        </Flex>
      </div>
    );
  };

  return (
    <DatePickerWrap className={className}>
      <Flex>
        <label style={{ cursor: "pointer" }}>
          <DatePicker
            key={key}
            dateFormat={dateFormat}
            selectsRange
            selected={endDate}
            startDate={startDate}
            popperPlacement={"top-end"}
            endDate={endDate}
            onChange={handleDateChange}
            showIcon
            showPopperArrow={false}
            calendarContainer={MyContainer}
            open={isCalendarOpen}
            onCalendarOpen={() => setIsCalendarOpen(true)}
            onCalendarClose={() => setIsCalendarOpen(false)}
            onFocus={(e) => (e.target.readOnly = true)}
            icon={
              <div style={{ margin: "auto 0" }}>
                <div
                  style={{
                    marginTop: 2,
                    marginRight: theme.spacing.xl,
                    marginLeft: theme.spacing.s,
                  }}
                >
                  <BookingsIcon colorPreset="secondary" width={17} />
                </div>
              </div>
            }
            customInput={<DateInput noDates={!startDate ? true : false} />}
            maxDate={maxDate}
            placeholderText={placeholderText}
            locale="en-GB"
          />
        </label>
      </Flex>
    </DatePickerWrap>
  );
};

const Title = styled.p`
  font-family: ${(p) => p.theme.typography.bodyFamily};
  color: ${(p) => p.theme.color.typography.heading};
  font-size: ${(p) => p.theme.typography.size.m};
  line-height: 1.3em;
  font-weight: ${(p) => p.theme.typography.weight.semi};
  margin: 0;
`;

const Subtitle = styled.p<{ margin?: string }>`
  font-family: ${(p) => p.theme.typography.bodyFamily};
  color: ${(p) => p.theme.color.typography.secondary};
  font-size: ${(p) => p.theme.typography.size.xs};
  line-height: 1.3em;
  margin: 0;
  ${withMargin};
`;
