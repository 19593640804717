import { Listbox } from "@headlessui/react";
import { MouseEvent } from "react";
import { useTheme } from "../hooks/useTheme";
import styled, { css, ThemeProp } from "../styles";
import { Chevron } from "./Chevron";
import { Flex } from "./Flex";
import { Text } from "./Text";
import { TickIcon } from "./icons/TickIcon";

export interface DropdownOption {
  label: string;
  value: any;
  disabled?: boolean;
  comingSoon?: boolean;
}

interface Props {
  margin?: string;
  options: DropdownOption[];
  selectedOptions: string[]; // Always an array of strings
  setSelectedOptions: (selected: string[]) => void;
  renderLabel: () => string;
  placeholder?: string;
  disableSelection?: boolean;
  selectionMode: "single" | "multiple";
  dropdownType?: "text" | "actions"; // actions is the 3 dots menu
  height?: number;
  disableOptionSort?: boolean;
  minWidth?: number;
}

const StyledListboxButton = styled(Listbox.Button)<{
  dropdownType?: "text" | "actions";
  minWidth?: number;
  height?: number;
}>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
  cursor: pointer;
  font-family: ${(p) => p.theme.typography.bodyFamily};
  font-size: ${(p) => p.theme.typography.size.m};

  ${(p) =>
    p.dropdownType === "text"
      ? css`
          background: transparent;
          border: none;
          color: ${(p) => p.theme.color.primary};
          font-size: ${(p) => p.theme.typography.size.s};
          font-weight: ${(p) => p.theme.typography.weight.semi};
        `
      : p.dropdownType === "actions"
      ? css`
          background: transparent;
          border: none;
          color: ${(p) => p.theme.color.typography.secondary};
        `
      : css`
          color: ${(p) => p.theme.color.typography.text};
          border-radius: ${(p) => p.theme.misc.borderRadius};
          min-width: ${(p: ThemeProp & { minWidth?: number }) =>
            p.minWidth ? p.minWidth : 200}px !important;
          height: ${(p: ThemeProp & { height?: number }) =>
            p.height ? p.height : 52}px;
          background-color: ${(p) => p.theme.color.card.background};
          border: 1px solid ${(p) => p.theme.color.typography.secondary}50;
          &:focus-visible {
            outline: none !important;
          }

          &:focus-within {
            outline: none !important;
          }

          &:hover {
            border-color: ${(p) => p.theme.color.typography.text};
            outline: none;
          }

          &[aria-expanded="true"] {
            border-color: ${(p) => p.theme.color.primary};
            outline: none;
          }

          &:focus,
          &:active {
            border-color: ${(p) => p.theme.color.primary};
            box-shadow: 0px 0px 0px 2px ${(p) => p.theme.color.input.hover};
            outline: none;
          }
        `}
`;

const StyledListboxOptions = styled(Listbox.Options)<{
  dropdownType?: "text" | "actions";
}>`
  background-color: ${(p) => p.theme.color.card.background};
  box-shadow: ${(p) => p.theme.shadow.cardHeavy};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  margin-top: ${(p) => p.theme.spacing.xs};
  max-height: 260px;
  min-width: 200px;
  overflow-y: auto;
  position: absolute;
  padding: 0px;
  z-index: 10;

  ${(p) =>
    p.dropdownType === "text"
      ? css`
          right: 0px !important;
        `
      : css`
          left: 0px;
        `}
`;

const StyledListboxOption = styled(Listbox.Option)<{ comingSoon?: boolean }>`
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
  cursor: ${(p) => (p.comingSoon ? "not-allowed" : "pointer")};
  opacity: ${(p) => (p.comingSoon ? 0.7 : 1)};
  display: flex;
  align-items: center;
  user-select: none;

  &:hover {
    background-color: ${(p) => p.theme.color.input.hover};
  }

  &:focus-visible {
    outline: none !important;
  }
`;

export function Dropdown({
  options,
  selectedOptions,
  setSelectedOptions,
  renderLabel,
  disableSelection,
  selectionMode,
  dropdownType,
  disableOptionSort,
  minWidth,
  height,
}: Props) {
  const theme = useTheme();

  const handleSelect = (newValue: string | string[]) => {
    if (selectionMode === "multiple" && Array.isArray(newValue)) {
      setSelectedOptions(newValue);
    } else if (selectionMode === "single" && typeof newValue === "string") {
      setSelectedOptions([newValue]); // Wrap single selection in an array
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <Listbox
        as="div"
        value={selectedOptions.length > 0 ? selectedOptions : undefined}
        onChange={handleSelect}
        multiple={selectionMode === "multiple"}
      >
        {({ open }) => (
          <>
            <StyledListboxButton
              minWidth={minWidth}
              height={height}
              dropdownType={dropdownType}
              onClick={(e: MouseEvent) => {
                if (!disableSelection) {
                  e.stopPropagation();
                }
              }}
            >
              {dropdownType === "actions" ? (
                <Dots />
              ) : (
                <>
                  <span style={{ marginRight: 20 }}>{renderLabel()}</span>
                  <span style={{ position: "absolute", right: 0 }}>
                    <Flex margin="0 m 0 0" style={{ marginTop: 2 }}>
                      <Chevron
                        size="s"
                        style={{ marginLeft: "auto" }}
                        direction={open ? "up" : "down"}
                        animateTransition
                        color={
                          dropdownType === "text"
                            ? theme.color.primary
                            : theme.color.typography.secondary
                        }
                      />
                    </Flex>
                  </span>
                </>
              )}
            </StyledListboxButton>
            {open && (
              <StyledListboxOptions
                onClick={(e: MouseEvent) => e.stopPropagation()}
                dropdownType={dropdownType}
              >
                {options
                  .sort((a, b) => {
                    if (disableOptionSort) return 0;
                    if (a.comingSoon && !b.comingSoon) return 1;
                    if (!a.comingSoon && b.comingSoon) return -1;
                    return a.label > b.label ? 1 : -1;
                  })
                  .map((option) => (
                    <StyledListboxOption
                      key={option.value}
                      value={option.value}
                      comingSoon={option.comingSoon}
                      onClick={(e: MouseEvent) => {
                        if (option.comingSoon) {
                          e.preventDefault();
                          e.stopPropagation();
                        }
                      }}
                    >
                      {({ selected }) => (
                        <Flex direction="row" margin="0 s 0 0">
                          {selectionMode === "multiple" ? (
                            <Flex
                              style={{ width: 20 }}
                              margin="0 s 0 0"
                              align="center"
                              justify="center"
                            >
                              {selected ? (
                                <TickIcon color={theme.color.primary} />
                              ) : null}
                            </Flex>
                          ) : null}
                          <Text margin="0">
                            {option.label}
                            {option.comingSoon && (
                              <Text
                                size="xs"
                                margin="0 0 0 m"
                                isInline
                                colorPreset="reward"
                              >
                                Coming Soon
                              </Text>
                            )}
                          </Text>
                        </Flex>
                      )}
                    </StyledListboxOption>
                  ))}
              </StyledListboxOptions>
            )}
          </>
        )}
      </Listbox>
    </div>
  );
}

const Dot = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.color.typography.secondary};
  margin: 1px 0px;
`;

function Dots() {
  return (
    <Flex direction="column" align="center" justify="center">
      <Dot />
      <Dot />
      <Dot />
    </Flex>
  );
}
