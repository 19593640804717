import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../config";
import { useTheme } from "../hooks/useTheme";
import { Flex } from "./Flex";
import { Text } from "./Text";

interface Props {
  active: boolean;
  label: string;
  onClick?: any;
  hideInactiveBorder?: true;
  badgeCount?: number;
  textAlign?: "left" | "center";
  textSize?: "s" | "m";
}

const Underline = styled.div<{ isActive?: boolean }>`
  width: ${(p) => p.theme.spacing.xxl};
  height: 2px;
  background-color: ${(p) =>
    p.isActive ? p.theme.color.primary : "transparent"};
  transition: all 0.2s;
  margin-top: ${(p) => p.theme.spacing.xs};
`;

const StyledTab = styled.div<Props>`
  padding: ${(p) => p.theme.spacing.s} 2px;
  padding-left: ${(p) => (p.textAlign === "left" ? "2px" : p.theme.spacing.m)};
  padding-right: ${(p) => p.theme.spacing.m};

  justify-content: ${(p) => (p.textAlign === "left" ? "flex-start" : "center")};
  align-items: center;
  font-weight: ${(p) => p.theme.typography.weight.semi};

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
    padding-left: ${(p) =>
      p.textAlign === "left" ? "2px" : p.theme.spacing.s};
    padding-right: ${(p) =>
      p.textAlign === "left" ? "2px" : p.theme.spacing.s};
  }

  :focus,
  focus-visible {
    outline: none;
  }

  cursor: pointer;

  user-select: none;
  transition: all 0.2s;
`;

export const Badge = styled.span`
  height: ${(p) => p.theme.spacing.m};
  width: ${(p) => p.theme.spacing.m};
  padding: 3px;
  margin-bottom: 3px;
  border-radius: 100%;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(p) => p.theme.typography.size.xxs};
  border-radius: 100%;
  margin-left: ${(p) => p.theme.spacing.s};
  color: #fff;
  font-weight: ${(p) => p.theme.typography.weight.bold};
  transition: opacity 200ms ease-out;
  background-color: ${(p) => p.theme.color.primary};
  color: #fff;
`;

export const Tab = (props: Props) => {
  const theme = useTheme();
  return (
    <StyledTab {...props}>
      <Flex align="center" direction="column">
        <Flex align="center" justify="center" direction="row">
          <Text
            weight={props.active ? "bold" : "semi"}
            color={
              props.active
                ? theme.color.typography.secondaryHeading
                : theme.color.typography.text
            }
            margin="0"
          >
            {props.label}
          </Text>
          {props.badgeCount && props.badgeCount > 0 ? (
            <Badge>{props.badgeCount && props.badgeCount}</Badge>
          ) : null}
        </Flex>
        <Underline isActive={props.active} />
      </Flex>
    </StyledTab>
  );
};
