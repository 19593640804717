import { MOBILE_BREAKPOINT } from "../config";
import styled, { css } from "../styles";
import withMargin, { WithMarginProp } from "../styles/withMargin";
import { Text } from "./Text";
import { CheckCircle } from "./icons/CheckCircle";

const ToggleWrap = styled.div<
  {
    active: boolean;
    placement?: string;
    size?: "s";
    borderRadius?: string;
  } & WithMarginProp
>`
padding: calc(${(p) => p.theme.spacing.s} + 4px)
calc(${(p) => p.theme.spacing.m} + 1px);
);

  border-radius: ${(p) =>
    p.borderRadius ? p.borderRadius : p.theme.misc.borderRadius};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  border: 1px solid ${(p) => p.theme.color.typography.secondary};

  & p {
    line-height: 1em;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    p {
      font-size: ${(p) => p.theme.typography.size.xs} !important;
    }
  }

  ${(p) =>
    p.active
      ? css`
          background-color: ${(p) => p.theme.color.input.hover};
          border: 1px solid ${(p) => p.theme.color.primary};
        `
      : p.placeholder === "card"
      ? css`
          border: 1px solid ${(p) => p.theme.color.card.divider};
          transition: border-color 0.1s;

          &:hover {
            border: 1px solid ${(p) => p.theme.color.typography.secondary};
          }
        `
      : css`
          border: 1px solid ${(p) => p.theme.color.typography.secondary}33;
          transition: border-color 0.1s;

          &:hover {
            border: 1px solid ${(p) => p.theme.color.typography.secondary};
          }
        `}

  ${withMargin}
`;

export const ToggleButton = (
  props: {
    title: string;
    active: boolean;
    placement?: string;
    borderRadius?: string;
    size?: "s";
    onClick: () => void;
  } & WithMarginProp
) => {
  return (
    <ToggleWrap
      onClick={props.onClick}
      placement={props.placement}
      margin={props.margin}
      active={props.active}
      borderRadius={props.borderRadius}
    >
      <Text
        margin="0 s 0 0"
        size={props.size ? props.size : "m"}
        colorPreset={props.active ? "text" : "secondary"}
        weight="semi"
        style={{ marginBottom: -2 }}
      >
        {props.title}
      </Text>
      <CheckCircle checked={props.active} />
    </ToggleWrap>
  );
};
